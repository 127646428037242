import React, { useCallback, useEffect, useState } from "react";
import "./style/load-manager.css";
import { Button, Form, Input, message, Select } from "antd";
import saveimg from "../../../assets/icons/common/save.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import LoadMangerService from "../../../services/loadManager.service";
import CommonService from "../../../services/common.service";
import NumberFormat from "react-number-format";

export default function AutoFreightOnPO(props) {
  const [autoFreightOnLoadForm] = Form.useForm();
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState({});
  const [loadDetails, setLoadDetails] = useState({});
  const [allowPOCal, setAllowPOCal] = useState(false);
  const [saveAutoFreightLoader, setSaveAutoFreightLoader] = useState(false);
  useEffect(() => {
    setSelectedPurchaseOrders({ ...props?.autoFreightPO });
    setLoadDetails(props?.autoFreightLoad?.loadDetailsDto);
  }, []);
  useEffect(() => validatePOCal(), [props?.autoFreightPO]);
  let toFixedVal;
  useEffect(() => {
    toFixedVal = (n, fixed) =>
      ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
    autoFreightOnLoadForm.setFieldsValue({
      carrier: loadDetails?.carrierId,
      segment:
        !loadDetails?.loadPricingDto || !loadDetails?.loadPricingDto?.segmentId
          ? "0"
          : loadDetails?.loadPricingDto?.segmentId === 0
          ? String(loadDetails?.loadPricingDto?.segmentId)
          : loadDetails?.loadPricingDto?.segmentId,
      loadType: loadDetails?.loadType,
      lineHaulRate: selectedPurchaseOrders?.loadPricingDto?.lineHaulRate,
      totalLineHaulRate: selectedPurchaseOrders?.loadPricingDto
        ?.totalLineHaulRate
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
          ).toFixed(2)
        : 0,
      freightType: selectedPurchaseOrders?.loadPricingDto?.freightType,
      miles: loadDetails?.loadPricingDto?.miles,
      pickupCharge: selectedPurchaseOrders?.loadPricingDto?.pickupCharge
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.pickupCharge
          ).toFixed(2)
        : 0,
      deliverCharge: selectedPurchaseOrders?.loadPricingDto?.deliveryCharge
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.deliveryCharge
          ).toFixed(2)
        : 0,
      shipZone: selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge
          ).toFixed(2)
        : 0,
      feulCharge: selectedPurchaseOrders?.loadPricingDto?.fuelCharge
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.fuelCharge
          ).toFixed(2)
        : 0,
      minorFreighr: selectedPurchaseOrders?.loadPricingDto?.minorFreight
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.minorFreight
          ).toFixed(2)
        : 0,
      unloadingCharge: selectedPurchaseOrders?.loadPricingDto?.unloadingCharge
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.unloadingCharge
          ).toFixed(2)
        : 0,
      rebate: selectedPurchaseOrders?.loadPricingDto?.rebate
        ? parseFloat(selectedPurchaseOrders?.loadPricingDto?.rebate).toFixed(2)
        : 0,
      miscellaneous: selectedPurchaseOrders?.loadPricingDto?.miscellaneous
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.miscellaneous
          ).toFixed(2)
        : 0,
      allocationBasis: loadDetails?.loadPricingDto?.allocationBasis
        ? loadDetails?.loadPricingDto?.allocationBasis
        : (!loadDetails?.loadPricingDto ||
            !loadDetails?.loadPricingDto?.segmentId ||
            loadDetails?.loadPricingDto?.segmentId === 0) &&
          "Case",
      totalCost: selectedPurchaseOrders?.loadPricingDto?.totalCost
        ? parseFloat(selectedPurchaseOrders?.loadPricingDto?.totalCost).toFixed(
            2
          )
        : 0,
      finishedCost: selectedPurchaseOrders?.loadPricingDto?.finishedCost
        ? parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.finishedCost
          ).toFixed(2)
        : 0,
      container: loadDetails?.container,
    });
  }, [selectedPurchaseOrders]);

  const validatePOCal = useCallback(async () => {
    const response = await LoadMangerService.validateRecalPO(
      props?.autoFreightPO?.loadPricingDto?.loadId,
      props.autoFreightPO?.id
    );
    if (response.status === 200) {
      setAllowPOCal(response.data);
    } else {
    }
  }, [props.autoFreightPO]);

  const calculateCount = (type) => {
    let count = 0;
    switch (type) {
      case "cases":
        count = selectedPurchaseOrders.cases;
        break;
      case "pallets":
        count = selectedPurchaseOrders.pallets;
        break;
      case "cubes":
        count = selectedPurchaseOrders.cubes;
        break;
      case "weight":
        count = selectedPurchaseOrders.weight;
        break;
    }

    return count;
  };

  const saveAutoFreightOnPO = useCallback(
    async (values) => {
      try {
        setSaveAutoFreightLoader(true);
        const autoFreightOnPO = {
          loadId: loadDetails?.loadId,
          purchaseOrderId: selectedPurchaseOrders.id,
          loadPricingDto: {
            id: selectedPurchaseOrders?.loadPricingDto?.id,
            segmentId: values.segment,
            lineHaulRate: values.lineHaulRate,
            totalLineHaulRate: values.totalLineHaulRate
              ? parseFloat(values.totalLineHaulRate)
              : values.totalLineHaulRate,
            freightType: values.freightType,
            status: loadDetails?.loadPricingDto?.status,
            pickupCharge: values.pickupCharge
              ? parseFloat(values.pickupCharge)
              : values.pickupCharge,
            deliveryCharge: values.deliverCharge
              ? parseFloat(values.deliverCharge)
              : values.deliverCharge,
            shipZoneCharge: values.shipZone
              ? parseFloat(values.shipZone)
              : values.shipZone,
            fuelCharge: values.feulCharge
              ? parseFloat(values.feulCharge)
              : values.feulCharge,
            minorFreight: values.minorFreighr
              ? parseFloat(values.minorFreighr)
              : values.minorFreighr,
            unloadingCharge: values.unloadingCharge
              ? parseFloat(values.unloadingCharge)
              : values.unloadingCharge,
            rebate: values.rebate ? parseFloat(values.rebate) : values.rebate,
            miscellaneous: values.miscellaneous
              ? parseFloat(values.miscellaneous)
              : values.miscellaneous,
            allocationBasis: values.allocationBasis,
            totalCost: values.totalCost,
            finishedCost: values.finishedCost,
            systemAllocFreight: 0,
            freightCost: 0,
            loadId: loadDetails?.loadId,
            purchaseOrderId: selectedPurchaseOrders.id,
            loadType: loadDetails?.loadType,
            miles: loadDetails?.miles,
          },
        };
        const response = await LoadMangerService.autoFreightOnPO(
          autoFreightOnPO
        );
        if (response.status === 200) {
          message.success("Auto Freight Applied on Purchase Order!");
          props.closeAutoFreightOnPOModal();
          props?.setAutoFreightPOSuccessAccess(true);
        } else {
          message.error("Record save failed!");
          props.closeAutoFreightOnPOModal();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSaveAutoFreightLoader(false);
      }
    },
    [loadDetails, selectedPurchaseOrders]
  );

  const handleChangeTotalLineHaulRate = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calTLR = posFlag.map((i) => {
      return i.loadPricingDto?.totalLineHaulRate;
    });
    var sumPosTLR = calTLR.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(
              loadDetails?.loadPricingDto?.totalLineHaulRate - sumPosTLR
            )
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            totalLineHaulRate: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          totalLineHaulRate: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        totalLineHaulRate: selectedPurchaseOrders?.loadPricingDto
          ?.totalLineHaulRate
          ? parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
            )
          : 0,
      });
    }
  };

  const handleChangePickupCharge = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.pickupCharge;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.pickupCharge) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.pickupCharge - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            pickupCharge: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.pickupCharge
            ),
          });

          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.pickupCharge) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.pickupCharge) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          pickupCharge: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        pickupCharge: selectedPurchaseOrders?.loadPricingDto?.pickupCharge,
      });
    }
  };
  const handleChangeDeliveryCharge = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.deliveryCharge;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.deliveryCharge) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.deliveryCharge - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            deliverCharge: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.deliveryCharge
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.deliveryCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.deliveryCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          deliverCharge: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        deliverCharge: selectedPurchaseOrders?.loadPricingDto?.deliverCharge,
      });
    }
  };
  const handleChangeShipZoneCharge = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.shipZoneCharge;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.shipZoneCharge - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            shipZone: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          shipZone: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        shipZone: selectedPurchaseOrders?.loadPricingDto?.shipZoneCharge,
      });
    }
  };
  const handleChangeFuelCharge = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.fuelCharge;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.fuelCharge) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.fuelCharge - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            feulCharge: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.fuelCharge
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.fuelCharge) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.fuelCharge) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          feulCharge: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        feulCharge: selectedPurchaseOrders?.loadPricingDto?.fuelCharge,
      });
    }
  };
  const handleChangeMinorFreight = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.minorFreight;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.minorFreight) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.minorFreight - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            minorFreighr: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.minorFreight
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.minorFreight) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ),
            finishedCost:
              parseFloat(selectedPurchaseOrders?.loadPricingDto?.minorFreight) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              );
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          minorFreight: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        minorFreight: selectedPurchaseOrders?.loadPricingDto?.minorFreight,
      });
    }
  };
  const handleChangeUnloadingCharge = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.unloadingCharge;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (selectedPurchaseOrders?.loadPricingDto?.unloadingCharge) {
        if (
          e.target.value &&
          parseFloat(e.target.value) >
            parseFloat(loadDetails?.loadPricingDto?.unloadingCharge - sumPO)
        ) {
          message.error("Must be less than actual price");
          autoFreightOnLoadForm.setFieldsValue({
            unloadingCharge: parseFloat(
              selectedPurchaseOrders?.loadPricingDto?.unloadingCharge
            ),
          });
          autoFreightOnLoadForm.setFieldsValue({
            totalCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.unloadingCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")),
            finishedCost:
              parseFloat(
                selectedPurchaseOrders?.loadPricingDto?.unloadingCharge
              ) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("miscellaneous")),
          });
        } else {
          const totalCost =
            e.target.value &&
            parseFloat(e.target.value) +
              parseFloat(
                autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  : 0
              ) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("pickupCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("deliverCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("shipZone")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("feulCharge")) +
              parseFloat(autoFreightOnLoadForm.getFieldValue("minorFreighr"));
          autoFreightOnLoadForm.setFieldsValue({
            totalCost: Math.floor(totalCost * 100).toFixed(2) / 100,
            finishedCost:
              Math.floor(totalCost * 100).toFixed(2) / 100 +
              Math.floor(
                autoFreightOnLoadForm.getFieldValue("miscellaneous") * 100
              ) /
                100,
          });
        }
      } else {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          unloadingCharge: 0,
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        unloadingCharge:
          selectedPurchaseOrders?.loadPricingDto?.unloadingCharge,
      });
    }
  };
  const handleChangeMiscellaneous = (e) => {
    const remainingPOs = props?.autoFreightLoad?.purchaseOrders.filter(
      (ele) => ele.id !== selectedPurchaseOrders.id
    );
    const posFlag = remainingPOs.filter((ele) => {
      return ele.manualOverrideAutoFreightFg === true;
    });
    const calPO = posFlag.map((i) => {
      return i.loadPricingDto?.miscellaneous;
    });
    var sumPO = calPO.reduce(function (x, y) {
      return x + y;
    }, 0);
    if (allowPOCal) {
      if (
        e.target.value &&
        parseFloat(e.target.value) >
          parseFloat(loadDetails?.loadPricingDto?.miscellaneous - sumPO)
      ) {
        message.error("Must be less than actual price");
        autoFreightOnLoadForm.setFieldsValue({
          miscellaneous: parseFloat(
            selectedPurchaseOrders?.loadPricingDto?.miscellaneous
          ),
        });
      } else {
        autoFreightOnLoadForm.setFieldsValue({
          finishedCost:
            Math.floor(
              autoFreightOnLoadForm.getFieldValue("totalCost") * 100
            ).toFixed(2) /
              100 +
            parseFloat(e.target.value),
        });
      }
    } else {
      message.error("Not possible to edit");
      autoFreightOnLoadForm.setFieldsValue({
        miscellaneous: selectedPurchaseOrders?.loadPricingDto?.miscellaneous,
      });
    }
  };
  const pickUpClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ pickupCharge: "" });
    }
  };
  const deliveryClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ deliverCharge: "" });
    }
  };
  const shipzneClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ shipZone: "" });
    }
  };
  const fualChargeClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ feulCharge: "" });
    }
  };
  const minorFreightClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ minorFreighr: "" });
    }
  };
  const unLoadClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ unloadingCharge: "" });
    }
  };
  const rebateClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ rebate: "" });
    }
  };
  const miscClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ miscellaneous: "" });
    }
  };
  return (
    <>
      <Form
        name="addSystemOption"
        className="autoFreightForm"
        layout="vertical"
        requiredMark={true}
        onFinish={saveAutoFreightOnPO}
        form={autoFreightOnLoadForm}
      >
        <div className="row">
          <div className="col-12">
            <h3
              className="primary-title"
              style={{ fontSize: "17px", width: "97%" }}
            >
              {loadDetails && selectedPurchaseOrders && (
                <label style={{ width: "40%" }}>
                  {loadDetails?.loadNumber}-{selectedPurchaseOrders.poNumber}
                </label>
              )}
              <label className="newLaodHeaderLabelWidth">
                <label>Case:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("cases")}
                >
                  <NumberFormat
                    value={calculateCount("cases")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Pallets:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("pallets")}
                >
                  {" "}
                  <NumberFormat
                    value={calculateCount("pallets")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Cubes:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("cubes")}
                >
                  {" "}
                  <NumberFormat
                    value={calculateCount("cubes")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Weight(lb):</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("weight")}
                >
                  {" "}
                  <NumberFormat
                    value={
                      calculateCount("weight") ? calculateCount("weight") : 0
                    }
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
            </h3>
          </div>
          <div style={{ width: "22%", marginTop: "15px" }}>
            <h3
              className="primary-title"
              style={{ fontSize: "17px", width: "97%" }}
            >
              Line Haul
            </h3>
            <div
              layout="vertical"
              style={{ marginTop: "15px" }}
              requiredMark={true}
            >
              <div className="logisticLMHeaderfields">
                <Form.Item
                  className="lblTotalLine"
                  label="Total Line Haul Amount($)"
                  name="totalLineHaulRate"
                  colon={false}
                  rules={[
                    {
                      message: "Must be less than actual price",
                      validator: (rules, value) => {
                        if (
                          value &&
                          parseFloat(value) >
                            parseFloat(
                              loadDetails?.loadPricingDto?.totalLineHaulRate
                            ).toFixed(2)
                        ) {
                          return Promise.reject();
                        } else return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder=" "
                    title={
                      selectedPurchaseOrders?.loadPricingDto?.totalLineHaulRate
                    }
                    onChange={(e) => handleChangeTotalLineHaulRate(e)}
                    disabled={
                      props?.autoFreightLoad?.loadDetailsDto?.loadStatus ===
                      "Cancelled"
                    }
                  ></Input>
                </Form.Item>
              </div>
            </div>
          </div>
          <div style={{ width: "71%", marginTop: "15px" }}>
            <div style={{ width: "100%" }}>
              <h3
                className="primary-title"
                style={{ fontSize: "17px", width: "97%" }}
              >
                Accessorials
              </h3>
              <div className="row">
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div layout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Pickup Charge($)"
                        name="pickupCharge"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.pickupCharge
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto?.pickupCharge
                          }
                          max={
                            selectedPurchaseOrders?.loadPricingDto?.pickupCharge
                          }
                          onChange={(e) => handleChangePickupCharge(e)}
                          onClick={pickUpClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Delivery Charge($)"
                        name="deliverCharge"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.deliveryCharge
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto
                              ?.deliveryCharge
                          }
                          onChange={(e) => handleChangeDeliveryCharge(e)}
                          onClick={deliveryClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Ship Zone Charge($)"
                        name="shipZone"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.shipZoneCharge
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto
                              ?.shipZoneCharge
                          }
                          onChange={(e) => handleChangeShipZoneCharge(e)}
                          onClick={shipzneClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div
                      className="logisticLMHeaderfields"
                      style={{ marginTop: "5rem" }}
                    >
                      <Form.Item
                        label="Total Cost($)"
                        name="totalCost"
                        colon={false}
                      >
                        <Input placeholder=" " disabled></Input>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div layout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Fuel Charge($)"
                        name="feulCharge"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.fuelCharge
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto?.fuelCharge
                          }
                          onChange={(e) => handleChangeFuelCharge(e)}
                          onClick={fualChargeClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Minor Freight($)"
                        name="minorFreighr"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.minorFreight
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto?.minorFreight
                          }
                          onChange={(e) => handleChangeMinorFreight(e)}
                          onClick={minorFreightClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Unloading Charge($)"
                        name="unloadingCharge"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.unloadingCharge
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto
                              ?.unloadingCharge
                          }
                          onChange={(e) => handleChangeUnloadingCharge(e)}
                          onClick={unLoadClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div
                      className="logisticLMHeaderfields"
                      style={{ marginTop: "5rem" }}
                    >
                      <Form.Item
                        label="Finished Cost($)"
                        name="finishedCost"
                        colon={false}
                      >
                        <Input placeholder=" " disabled></Input>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div layout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Rebate($)"
                        name="rebate"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.rebate
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={selectedPurchaseOrders?.loadPricingDto?.rebate}
                          onClick={rebateClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Miscellaneous($)"
                        name="miscellaneous"
                        colon={false}
                        rules={[
                          {
                            message: "Must be less than actual price",
                            validator: (rules, value) => {
                              if (
                                value &&
                                parseFloat(value) >
                                  parseFloat(
                                    loadDetails?.loadPricingDto?.miscellaneous
                                  )
                              )
                                return Promise.reject();
                              else return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder=" "
                          title={
                            selectedPurchaseOrders?.loadPricingDto
                              ?.miscellaneous
                          }
                          onChange={(e) => handleChangeMiscellaneous(e)}
                          onClick={miscClick}
                          disabled={
                            props?.autoFreightLoad?.loadDetailsDto
                              ?.loadStatus === "Cancelled"
                          }
                        ></Input>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7"></div>
          <div className="col-5">
            {props?.autoFreightLoad?.purchaseOrders?.length === 1 ||
            props?.autoFreightLoad?.loadDetailsDto?.loadStatus ===
              "Cancelled" ? (
              ""
            ) : (
              <Button
                className="logisticLMRemoveYesButton saveBtnAddLoad"
                style={{ width: "110px" }}
                htmlType="submit"
                disabled={
                  [
                    "Tender",
                    "Draft",
                    "Submitted",
                    "Confirmed",
                    "In Transit",
                  ].indexOf(
                    props?.autoFreightLoad?.loadDetailsDto?.loadStatus
                  ) < 0
                }
                loading={saveAutoFreightLoader}
              >
                <img src={saveimg} style={{ fontSize: 25, marginRight: 10 }} />
                Save
              </Button>
            )}
          </div>
        </div>
      </Form>
      <div className="cancelAndAutoFButtons">
        <ButtonComponent
          className="addLoadCancelButton"
          onClick={props.closeAutoFreightOnPOModal}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </>
  );
}

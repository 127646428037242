import {
  ADD_GRID_CONFIG,
  SET_APP_LANGUAGE,
  SET_CLIENT_DETAILS,
  SET_COMMON_DETAILS,
  SET_GRID_CONFIGS,
  SET_LOGIN_DETAILS,
  SET_PARTNER_DETAILS_BY_USERNAME,
  SET_SYSTEM_OPTIONS,
  UPDATE_GRID_CONFIG,
  UPDATE_BOLD_BI_TOKEN,
  UPDATE_BOLD_BI_REPORTS,
  SET_UNAUTHORIZED,
  UPDATE_TEMP_GRID_CONFIG,
  RESET_TEMP_GRID_CONFIG,
  SET_VENDOR_LIST,
} from "../constants";

export const setAppLanguage = (payload) => ({
  type: SET_APP_LANGUAGE,
  payload,
});
export const setLoginDetails = (payload) => ({
  type: SET_LOGIN_DETAILS,
  payload,
});

export const setPartnerDetails = (payload) => ({
  type: SET_PARTNER_DETAILS_BY_USERNAME,
  payload,
});

export const setSystemOptions = (payload) => ({
  type: SET_SYSTEM_OPTIONS,
  payload,
});

export const setClientDetails = (payload) => ({
  type: SET_CLIENT_DETAILS,
  payload,
});

export const setCommonDetails = (payload) => ({
  type: SET_COMMON_DETAILS,
  payload,
});

export const setGridConfigs = (payload) => ({
  type: SET_GRID_CONFIGS,
  payload,
});

export const updateGridConfig = (payload) => ({
  type: UPDATE_GRID_CONFIG,
  payload,
});

export const addGridConfig = (payload) => ({
  type: ADD_GRID_CONFIG,
  payload,
});

export const updateTempGridConfig = (payload) => ({
  type: UPDATE_TEMP_GRID_CONFIG,
  payload,
});

export const resetTempGridConfig = () => ({
  type: RESET_TEMP_GRID_CONFIG,
});

export const updateBoldBIToken = (payload) => ({
  type: UPDATE_BOLD_BI_TOKEN,
  payload,
});

export const updateBoldBIReports = (payload) => ({
  type: UPDATE_BOLD_BI_REPORTS,
  payload,
});

export const setUnAuthorized = (payload) => ({
  type: SET_UNAUTHORIZED,
  payload,
});

export const setVendorList = (payload) => ({
  type: SET_VENDOR_LIST,
  payload,
});

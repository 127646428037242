import HomeIcon from "../assets/icons/navbar/home.svg";
import PaymentIcon from "../assets/icons/navbar/purchasing.svg";
import DemandIcon from "../assets/icons/navbar/demand.svg";
import LogisticsIcon from "../assets/icons/navbar/logistics.svg";
import ReceivingIcon from "../assets/icons/navbar/receiving.svg";
import DeliveryIcon from "../assets/icons/navbar/shipping.svg";
import ReceiptIcon from "../assets/icons/navbar/billing.svg";
import ReportsIcon from "../assets/icons/navbar/analytics.svg";
import AdminIcon from "../assets/icons/navbar/masterData.svg";
import SettingsIcon from "../assets/icons/navbar/settings.svg";

export const menuItems = [
  {
    title: "Home",
    link: "/home",
    icon: HomeIcon,
    subMenus: [],

  },
  {
    title: "Demand",
    link: "/demand",
    icon: DemandIcon,
    subMenus: [],
  },
  {
    title: "Purchasing",
    link: "/purchasing",
    icon: PaymentIcon,
    subMenus: [],
  },
  {
    title: "Logistics",
    link: "/logistics",
    icon: LogisticsIcon,
    subMenus: [],
  },
  {
    title: "Receiving",
    link: "/receiving",
    icon: ReceivingIcon,
    subMenus: [],
  },
  {
    title: "Shipping",
    link: "/shipping",
    icon: DeliveryIcon,
    subMenus: [],
  },
  {
    title: "Billing",
    link: "/billing",
    icon: ReceiptIcon,
    subMenus: [],
  },
  {
    title: "Analytics",
    link: "/analytics",
    icon: ReportsIcon,
    subMenus: [],
  },
  {
    title: "Master Data",
    link: "/masterData",
    icon: AdminIcon,
    subMenus: [],
  },
  {
    title: "Setup",
    link: "/setup",
    icon: SettingsIcon,
    subMenus: [],
  },
];

export const buyerItems = [
  {
    title: "Home",
    link: "/home",
    icon: HomeIcon,
    subMenus: [],

  },
  {
    title: "Demand",
    link: "/demand",
    icon: DemandIcon,
    subMenus: [],
  },
  {
    title: "Purchasing",
    link: "/purchasing",
    icon: PaymentIcon,
    subMenus: [],
  },
  {
    title: "Logistics",
    link: "/logistics",
    icon: LogisticsIcon,
    subMenus: [],
  },
  {
    title: "Receiving",
    link: "/receiving",
    icon: ReceivingIcon,
    subMenus: [],
  },

  {
    title: "Billing",
    link: "/billing",
    icon: ReceiptIcon,
    subMenus: [],
  },
  {
    title: "Analytics",
    link: "/analytics",
    icon: ReportsIcon,
    subMenus: [],
  },
  {
    title: "Master Data",
    link: "/masterData",
    icon: AdminIcon,
    subMenus: [],
  },
  {
    title: "Setup",
    link: "/setup",
    icon: SettingsIcon,
    subMenus: [],
  },
];


export const limitedMenuItmes = [
  {
    title: "Home",
    link: "/home",
    icon: HomeIcon,
    subMenus: [],

  },

  {
    title: "Master Data",
    link: "/masterData",
    icon: AdminIcon,
    subMenus: [],
  },
  {
    title: "Setup",
    link: "/setup",
    icon: SettingsIcon,
    subMenus: [],
  },
];


export const carrierMenuItmes = [
  {
    title: "Home",
    link: "/home",
    icon: HomeIcon,
    subMenus: [],

  },
  {
    title: "Logistics",
    link: "/logistics",
    icon: LogisticsIcon,
    subMenus: [],
  },
  {
    title: "Receiving",
    link: "/receiving",
    icon: ReceivingIcon,
    subMenus: [],
  },
  {
    title: "Billing",
    link: "/billing",
    icon: ReceiptIcon,
    subMenus: [],
  },
  {
    title: "Analytics",
    link: "/analytics",
    icon: ReportsIcon,
    subMenus: [],
  },
  {
    title: "Master Data",
    link: "/masterData",
    icon: AdminIcon,
    subMenus: [],
  },
  {
    title: "Setup",
    link: "/setup",
    icon: SettingsIcon,
    subMenus: [],
  },

];


export const vendorMenuItmes = [
  {
    title: "Home",
    link: "/home",
    icon: HomeIcon,
    subMenus: [],

  },

  {
    title: "Purchasing",
    link: "/purchasing",
    icon: PaymentIcon,
    subMenus: [],
  },

  {
    title: "Receiving",
    link: "/receiving",
    icon: ReceivingIcon,
    subMenus: [],
  },
  {
    title: "Shipping",
    link: "/shipping",
    icon: DeliveryIcon,
    subMenus: [],
  },
  {
    title: "Billing",
    link: "/billing",
    icon: ReceiptIcon,
    subMenus: [],
  },
  {
    title: "Analytics",
    link: "/analytics",
    icon: ReportsIcon,
    subMenus: [],
  },
  {
    title: "Master Data",
    link: "/masterData",
    icon: AdminIcon,
    subMenus: [],
  },
  {
    title: "Setup",
    link: "/setup",
    icon: SettingsIcon,
    subMenus: [],
  },
];

import { Tooltip } from "antd";
import React from "react";
import NumberFormat from "react-number-format";

export default function AdditionalHeaderFields({
  poDetails = {},
  poLineList = [],
  totalWeight = 0,
}) {
  return (
    <div className="additional-po-details">
      <h6 className="secondary-title" style={{ marginBottom: 15 }}>
        PO Details
      </h6>
      <div className="row">
        <div className="col-6">
          <div
            className="POModifySummaryHeaderfields1"
            style={{ marginBottom: 10 }}
          >
            <label className="custom-lbl-style">Total Cases</label>
            <span className="custom-value-style">
              <NumberFormat
                value={poDetails?.totalCases || 0}
                displayType={"text"}
                thousandSeparator={true}
                allowEmptyFormatting
              />
            </span>
          </div>
        </div>
        <div className="col-6">
          <div
            className="POModifySummaryHeaderfields1"
            style={{ marginBottom: 10 }}
          >
            <label className="custom-lbl-style">Total Cubes</label>
            <span className="custom-value-style">
              <NumberFormat
                value={poDetails?.totalCubes || 0}
                displayType={"text"}
                thousandSeparator={true}
                allowEmptyFormatting
              />
            </span>
          </div>
        </div>
        <div className="col-6">
          <div
            className="POModifySummaryHeaderfields1"
            style={{ marginBottom: 10 }}
          >
            <label className="custom-lbl-style">Total Weight</label>
            <span className="custom-value-style">
              <NumberFormat
                value={totalWeight || 0}
                displayType={"text"}
                thousandSeparator={true}
                allowEmptyFormatting
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          </div>
        </div>
        <div className="col-6">
          <div
            className="POModifySummaryHeaderfields1"
            style={{ marginBottom: 10 }}
          >
            <label className="custom-lbl-style">Total Pallets</label>
            <span className="custom-value-style">
              <Tooltip
                placement="right"
                title={poDetails?.totalPallets?.toFixed(4)}
                color={"#800080"}
                className="tooltipvalue"
              >
                {poDetails?.totalPallets?.toFixed(2) || 0}
              </Tooltip>
            </span>
          </div>
        </div>
        <div
          className="POModifySummaryHeaderfields1"
          style={{ marginBottom: 10 }}
        >
          <label className="custom-lbl-style">Total # of Items</label>
          <span className="custom-value-style">{poLineList.length}</span>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function ItemNumberDetails({ itemDetails = {} }) {
  return (
    <>
      {Object.keys(itemDetails).length === 0 && (
        <div>Fetching data. Please wait a moment</div>
      )}
      {Object.keys(itemDetails).length > 0 && (
        <>
          <div className="row">
            <div className="col-4">
              <label>Item Code</label>
              <span>{itemDetails?.itemCode}</span>
            </div>

            <div className="col-4">
              <label>Warehouse</label>
              <span>{itemDetails?.wareHouse}</span>
            </div>

            <div className="col-4">
              <label>Unit of Measure</label>
              <span>{itemDetails?.unitOfMeasure}</span>
            </div>

            <div className="col-4">
              <label>Item Cube</label>
              <span>{itemDetails?.itemTransportation?.itemCubeValue}</span>
            </div>

            <div className="col-4">
              <label>TI</label>
              <span>{itemDetails?.itemTransportation?.vendorTi}</span>
            </div>

            <div className="col-4">
              <label>HI</label>
              <span>{itemDetails?.itemTransportation?.vendorHi}</span>
            </div>

            <div className="col-4">
              <label>Gross Shipment Weight</label>
              <span>
                {itemDetails?.itemTransportation?.grossShipWeight}{" "}
                {itemDetails?.itemTransportation?.grossShipWeightMeasure}
              </span>
            </div>

            <div className="col-4">
              <label>Length</label>
              <span>
                {itemDetails?.itemTransportation?.length}{" "}
                {itemDetails?.itemTransportation?.lengthMeasure}
              </span>
            </div>

            <div className="col-4">
              <label>Width</label>
              <span>
                {itemDetails?.itemTransportation?.width}{" "}
                {itemDetails?.itemTransportation?.widthMeasure}
              </span>
            </div>

            <div className="col-4">
              <label>Height</label>
              <span>
                {itemDetails?.itemTransportation?.height}{" "}
                {itemDetails?.itemTransportation?.heightMeasure}
              </span>
            </div>

            <div className="col-8">
              <label>Description</label>
              <span>{itemDetails?.itemDescription} </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

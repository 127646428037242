import React, { useEffect } from "react";
import dateFormat from "dateformat";
import QuestionIcn from "../assets/icons/navbar/question-help.png";
import supportIcn from "../assets/icons/navbar/helpsupport.svg";
import logoutIcn from "../assets/icons/navbar/Logout.svg";
import UserIcon from "../assets/icons/profile.svg";
import { Menu, Dropdown, Divider } from "antd";
import UserService from "../services/user.service";
import { store } from "../store";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  loginDetailsSelector,
  unAuthorizedSelector,
} from "../store/selectors/app.selector";
const now = new Date();

export default function TopBanner() {
  const navigation = useNavigate();
  const location = useLocation();
  const userDetails = useSelector(loginDetailsSelector);

  const unAuthorized = useSelector(unAuthorizedSelector);

  console.log(location);
  useEffect(() => {
    if (unAuthorized) {
      navigation("/unAuthorized");
    }

    // else {
    //   navigation(
    //     location.pathname.includes("/unAuthorized")
    //       ? "/home"
    //       : location.pathname
    //   );
    // }
  }, [unAuthorized]);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Menu.Item key="1">
          <b style={{ marginLeft: "48px" }}>
            {userDetails?.firstName + " " + userDetails?.lastName}
          </b>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={() => navigation("/profile")}>
          <img src={supportIcn} className="img-fluid submenu-option-icon" />
          Profile
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">
          <img src={supportIcn} className="img-fluid submenu-option-icon" />
          User Preferences
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="4">
          <img src={supportIcn} className="img-fluid submenu-option-icon" />{" "}
          Help & Support
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="4"
          onClick={async () => {
            store.dispatch({ type: "SET_VENDOR_LIST", payload: [] });
            await UserService.doLogout();
            store.dispatch({ type: "USER_LOGOUT" });
          }}
        >
          <img src={logoutIcn} className="img-fluid submenu-option-icon" />
          Logout
        </Menu.Item>
      </Menu.Item>
    </Menu>
  );

  const helpSection = (
    <Menu style={{ width: "300px", padding : "5px 0px 0px 0px" }}>
      <Menu.Item key="0">
        {/* <img src={supportIcn} className="img-fluid submenu-option-icon" />{" "} */}
        <span style={{fontWeight : "bold"}}> Help & Support : </span> <br/>
        <span> petal@limnlabs.com</span>
      </Menu.Item>
      <Menu.Item key="1">
        <span style={{fontWeight : "bold"}}> Onboarding Queries : </span> <br/>
        <span> onboarding@limnlabs.com</span>
      </Menu.Item>
      <Menu.Item key="2">
        <span style={{fontWeight : "bold"}}> Contact Us : </span> <br/>
        <span> 833-5466-522</span>
      </Menu.Item>
      <Menu.Item key="3">
        <span style={{fontWeight : "bold"}}> Training Documentation : </span> <br/>
        <a  href= "https://petaltraining.limnlabs.com/Introduction.html" 
            target="_blank" 
            style={{textDecoration : "underline", 
                    wordBreak: "break-all", 
                    cursor: "pointer"
                  }}
        > https://petaltraining.limnlabs.com/Introduction.html</a>
      </Menu.Item>
      <Divider style={{margin : "auto"}}/>
      <Menu.Item key="4" style={{backgroundColor: "#e8e8e8", color: "#000"}}>
        <span> Copyright © 2022 LimnLabs Inc., All rights reserved</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="header-part">
        <h3 style={{ fontSize: 20 }}>PETAL</h3>
        <div className="right-header">
          <div className="current-day-time-text">
            {dateFormat(now, "dddd, mmmm dS, yyyy")}
          </div>
          <div className="img-user-help" style={{ marginLeft: 20 }}>
            <Dropdown overlay={helpSection} trigger={["click"]}>
              <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
              >
                <img src={QuestionIcn} className="img-help" />
              </a>
            </Dropdown>
            
            <Dropdown style={{ padding: "0px" }} overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <img src={UserIcon} className="img-user" />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

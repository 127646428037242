import { environment } from "../../environment";
import {
  buyerItems,
  carrierMenuItmes,
  limitedMenuItmes,
  menuItems,
  vendorMenuItmes,
} from "../../layout/menuLinks";

// eslint-disable-next-line import/prefer-default-export
export const loginDetailsSelector = (state) => state.app.loginDetails;
export const serviceLoaderSelector = (state) => state.app.serviceLoader;
export const partnerDetailsSelector = (state) => state.app.partnerDetails;
export const systemOptionSelector = (state) => state.app.systemOptions;
export const clientDetailsSelector = (state) => state.app.clientDetails;
export const commonDetailsSelector = (state) => state.app.commonDetails;
export const boldBITokenSelector = (state) => state.app.boldBIToken;
export const boldBIReportsSelector = (state) => state.app.boldBIReports;
export const gridConfigsSelector = (state) => state.app.gridConfigs;
export const unAuthorizedSelector = (state) => state.app.unAuthorized;

export const getGridConfig = (gridId) => {
  return (state) =>
    (state.app.gridConfigs || []).find(
      (gridConfig) => gridConfig.gridId === gridId
    );
};

export const getTempGridConfig = (gridId) => {
  return (state) => state.app.tempGridConfig[gridId];
};

export const isAccessRolesSelector = (state) => {
  const userDetails = state.app.loginDetails;
  const userAccessRoles = userDetails.accessRoles;

  return userAccessRoles === null ? true : false;
};

export const getMenuListSelector = (state) => {
  const userDetails = state.app.loginDetails;
  const userAccessRoles = userDetails.accessRoles;
  if (!userDetails || !userAccessRoles) {
    return [];
  }

  if (environment.petal_menu_limited === "true") {
    return filterMenuItems(limitedMenuItmes, userAccessRoles);
  } else {
    if (userDetails.clientType === "CARRIER") {
      return filterMenuItems(carrierMenuItmes, userAccessRoles);
    }

    if (userDetails.clientType === "VENDOR") {
      return filterMenuItems(vendorMenuItmes, userAccessRoles);
    }

    if (userDetails.clientType === "BUYER") {
      return filterMenuItems(buyerItems, userAccessRoles);
    }

    return filterMenuItems(menuItems, userAccessRoles);
  }
};

export const filterMenuItems = (menuItems, userAccessRoles) => {
  return menuItems.filter((menu) =>
    (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some(
          (accessMenu) =>
            accessMenu.name.toLowerCase() === menu.title.toLowerCase()
        )
      )
  );
};

export const hasAccessToMenu = (menuName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some(
          (menu) => menu?.name?.toLowerCase() === menuName.toLowerCase()
        )
      );
  };
};

export const hasAccessToTile = (menuName, tileName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some((menu) => {
          if (menu?.name?.toLowerCase() === menuName.toLowerCase()) {
            return (menu.tiles || []).some(
              (tile) => tile?.name?.toLowerCase() === tileName.toLowerCase()
            );
          }
        })
      );
  };
};

export const hasWriteAccessToTile = (menuName, tileName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some((menu) => {
          if (menu?.name?.toLowerCase() === menuName.toLowerCase()) {
            return (menu.tiles || []).some(
              (tile) =>
                tile?.name?.toLowerCase() === tileName.toLowerCase() &&
                tile?.permission?.toLowerCase() === "write"
            );
          }
        })
      );
  };
};

export const hasAccessToTab = (menuName, tileName, tabName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some((menu) => {
          if (menu?.name?.toLowerCase() === menuName.toLowerCase()) {
            return (menu.tiles || []).some((tile) => {
              if (tile?.name?.toLowerCase() === tileName.toLowerCase()) {
                return (tile.tabs || []).some(
                  (tab) => tab.name.toLowerCase() === tabName.toLowerCase()
                );
              }
            });
          }
        })
      );
  };
};

export const hasWriteAccessToTab = (menuName, tileName, tabName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some((menu) => {
          if (menu?.name?.toLowerCase() === menuName.toLowerCase()) {
            return (menu.tiles || []).some((tile) => {
              if (tile?.name?.toLowerCase() === tileName.toLowerCase()) {
                return (tile.tabs || []).some(
                  (tab) =>
                    tab.name.toLowerCase() === tabName.toLowerCase() &&
                    tab.permission.toLowerCase() === "write"
                );
              }
            });
          }
        })
      );
  };
};

export const hasReadAccessToTab = (menuName, tileName, tabName) => {
  return (state) => {
    const userDetails = state.app.loginDetails;
    const userAccessRoles = userDetails.accessRoles;
    return (userAccessRoles || [])
      .filter((role) => role.active)
      .some((role) =>
        (role.accessMenuDtoList || []).some((menu) => {
          if (menu?.name?.toLowerCase() === menuName.toLowerCase()) {
            return (menu.tiles || []).some((tile) => {
              if (tile?.name?.toLowerCase() === tileName.toLowerCase()) {
                return (tile.tabs || []).some(
                  (tab) =>
                    tab.name.toLowerCase() === tabName.toLowerCase() &&
                    tab.permission.toLowerCase() === "read"
                );
              }
            });
          }
        })
      );
  };
};

export const hasAccessToRoute = ({
  menuName,
  tileName,
  tabName,
  writeAccess,
  skipPermissionsCheck,
}) => {
  if (skipPermissionsCheck) {
    return () => skipPermissionsCheck;
  }
  if (menuName && tileName && tabName && writeAccess) {
    return hasWriteAccessToTab(menuName, tileName, tabName);
  }
  if (menuName && tileName && tabName) {
    return hasAccessToTab(menuName, tileName, tabName);
  }
  if (menuName && tileName && writeAccess) {
    return hasWriteAccessToTile(menuName, tileName);
  }
  if (menuName && tileName) {
    return hasAccessToTile(menuName, tileName);
  }
  if (menuName) {
    return hasAccessToMenu(menuName);
  }
  return () => false;
};

export const hasAccessToNotificationsSelector = (state) => {
  const userDetails = state.app.loginDetails;
  const userAccessRoles = userDetails?.accessRoles;

  const staticRolesList = [
    "Turn Buyer",
    "National Buyer",
    "Transportation Admin",
    "Claim Admin",
    "Claim Coordinator",
    "Order Processor",
    "Load Processor",
    "Shipping Admin",
    "Billing Admin",
  ];
  return userAccessRoles
    .map((accessRole) => accessRole.role.roleName)
    .some((role) => staticRolesList.indexOf(role) > -1);
};

import React, { useCallback, useEffect, useState } from "react";
import "./style/load-manager.css";
import "./style/load-details.css"
import {  Form,Input, Select } from "antd";
import loaderGif from "../../../assets/icons/common/loaderGif.gif";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

export default function ItemDetailsModal(props) {
  const [itmDetls, setItmDetls] = useState();
  useEffect(() => {
    console.log(props.itemDetails)
    setItmDetls(props.itemDetails)
  }, [props.itemDetails]);
  return (
    <>
       <div  className="row ">
        {itmDetls &&  itmDetls? 
        <>
        <Form name="addSystemOption" layout="vertical" requiredMark={true}>
          <div  style={{ marginTop: 5 }}>
              <div
                className="row "
            style={{marginLeft:"10px"}}
              >
                <div className="col-3">
                  <div className="logisticLMHeaderfields" >
                    <Form.Item label="Item Code" name="itemCode" colon={false}>
                    <span></span>
                      <Input value={itmDetls?.itemCode}/>
                    </Form.Item>
                    <Form.Item label="Item Description" name="itemDescription" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDescription}/>
                    </Form.Item>
                    <Form.Item label="Location Code" name="loactionCode" colon={false}>
                    <span></span>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Unit of Measure" name="measure" colon={false}>
                  <span></span>
                    <Input value={itmDetls?.unitOfMeasure}/>
                  </Form.Item>
                  <Form.Item label="Item Category" name="status" colon={false}>
                  <span></span>
                    <Input value={itmDetls?.internalItemCtgry}/>
                  </Form.Item>
                  </div>
                </div>
                <div className="col-3" >
                  <div className="logisticLMHeaderfields1">
                    <Form.Item label="Upc" name="upc" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDetails?.upc}/>
                    </Form.Item>
                    <Form.Item label="Bio Engineering Flag" name="bio" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDetails?.bioEngFlag}/>
                    </Form.Item>
                    <Form.Item label="Item Catelog" name="itemCatelog" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDetails?.itemCatalog}/>
                    </Form.Item>
                    <Form.Item label="Trade Unit Changes" name="tradeUnit" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDetails?.tradeUnitWeight}/>
                    </Form.Item>
                    <Form.Item label="# of units in trade pack" name="tradePack" colon={false}>
                    <span></span>
                    <Input value={itmDetls?.itemDetails?.unitsInTradePack}/>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-3" >
                  <div className="logisticLMHeaderfields1">
                  <Form.Item label="Storage Type" name="storageType" colon={false}>
                  <span></span>
                    <Select >
                      <option>{itmDetls?.itemClassification?.storageType}</option>
                    </Select>
                    </Form.Item>
                    <Form.Item label="Temp Range" name="tempRange" colon={false}>
                    <div className="col-5" style={{ float:"left"}}>
                    <span></span>
                    <Input value={itmDetls?.itemClassification?.temperatureMin}/>
                    </div>
                    <label style={{marginLeft:"10px",marginTop:"10px"}}>-</label>
                    <div className="col-5" style={{ float:"right"}}>
                    <span></span>
                    <Input value={itmDetls?.itemClassification?.temperatureMax}/>
                    </div>
                    </Form.Item>
                    <Form.Item label="Post Harvest Processing" name="harvest" colon={false}>
                    <Select>
                      <option>{itmDetls?.itemClassification?.postHarvestProcessing}</option>
                    </Select>
                    </Form.Item>
                    <Form.Item label="Growing Method" name="growingMethod" colon={false}>
                    <Select>
                    <option>{itmDetls?.itemClassification?.growingMethod}</option>
                    </Select>
                    </Form.Item>
                    <Form.Item label="Pallet Ti - Hi" name="pallet" colon={false}>
                    <div className="col-5" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.palletTi}/>
                    </div>
                    <label style={{marginLeft:"10px",marginTop:"15px"}}>-</label>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.palletHi}/>
                    </div>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-3">
                  <div className="logisticLMHeaderfields1">
                  <Form.Item label="Gross Weight" name="grossWeight" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.grossWeight}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.grossWeightMeasure}/></div>
                    </Form.Item>
                    <Form.Item label="Gross Ship Weight" name="shipWeight" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.grossShipWeight}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.grossShipWeightMeasure}/></div>
                    </Form.Item>
                    <Form.Item label="Net Weight" name="netWeight" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.netWeight}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.netWeightMeasure}/></div>
                    </Form.Item>
                    <Form.Item label="Length" name="length" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.length}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.lengthMeasure}/></div>
                    </Form.Item>
                    <Form.Item label="Width" name="width" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.width}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.widthMeasure}/></div>
                    </Form.Item>
                    <Form.Item label="Height" name="height" colon={false}>
                  <div className="col-6" style={{float:"left"}}>
                    <Input value={itmDetls?.itemTransportation?.height}/></div>
                    <div className="col-5" style={{float:"right"}}>
                    <Input value={itmDetls?.itemTransportation?.heightMeasure}/></div>
                    </Form.Item>
                  </div>
                </div>
                
               
              </div>
            </div>
        </Form>
        </>
        :<img className="loaderClassInItemDetails" src={loaderGif} />}
        <div className="col-8">
          </div>
          <div className="col-4">
         
          <ButtonComponent
            className="logisticLMRemoveYesButton"
            style={{width:"130px"}}
            onClick={props.itemDetailsModalClose}
          >
            Close
          </ButtonComponent>
        </div>
      </div>
    </>
  );
}

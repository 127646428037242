import "./App.css";
import "./styles/customStyle.css";
import "./styles/commonStyle.css";
import "./components/grid/grid.css";
import Router from "./app.router";
import { useCallback, useEffect, useState } from "react";
import UserService from "./services/user.service";
import LLAdminUserService from "./services/llAdmin-user.service";
import {
  setClientDetails,
  setCommonDetails,
  setGridConfigs,
  setLoginDetails,
  setSystemOptions,
  updateBoldBIReports,
  updateBoldBIToken,
  setUnAuthorized,
  resetTempGridConfig,
  setVendorList,
} from "./store/actions/app.action";
import { useDispatch } from "react-redux";
import ClientService from "./services/client.service";
import CommonService from "./services/common.service";
import { registerLicense } from "@syncfusion/ej2-base";
import { BoldBiServices } from "./services/boldbi-reports.service";
import TradingPartnerService from "./services/trading-partner.service";
import { message } from "antd";

export default function App() {
  const dispatch = useDispatch();
  const [appReady, setAppReady] = useState(false);
  useEffect(() => {
    getUserDetails();
    getCommonDetails();
    registerSyncFusionLicense();
    getGridConfigs();
    dispatch(resetTempGridConfig());
  }, []);

  const registerSyncFusionLicense = () => {
    registerLicense(
      "ORg4AjUWIQA/Gnt2VVhiQlFaclxJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViXn5ecnJQQWFeUkc="
    );
  };

  const getUserDetails = useCallback(async () => {
    try {
      const userName = UserService.getUsername();
      const response = await LLAdminUserService.getUserDetails({
        name: userName,
      });
      if (response.status === 200) {
        response.data["locations"] = response.data["locations"] || [];
        dispatch(setLoginDetails(response.data));
        if (
          response.data["systemOptionDto"] &&
          Object.keys(response.data["systemOptionDto"]).length > 0
        ) {
          dispatch(setSystemOptions(response.data["systemOptionDto"]));
        } else {
          dispatch(setSystemOptions({}));
        }

        if (
          response.data["clientId"] !== null &&
          response.data["clientId"] !== "99999"
        ) {
          await getClientDetails(response.data);
        }

        if (response.data["status"] !== "Active") {
          dispatch(setUnAuthorized(true));
        } else {
          dispatch(setUnAuthorized(false));
        }
        // dispatch(setUnAuthorized(false));
        setAppReady(true);
        getPartnerList();
      }
    } catch (err) {
      if (err?.data?.errors[0] === "User Not Authorized") {
        setAppReady(true);
        dispatch(setUnAuthorized(true));
        message.error("User Not Authorized")
      }
    }
  }, []);

  const getBoldBIToken = useCallback(async (commonDetails) => {
    try {
      const response = await CommonService.getBoldBITokenVal();
      if (response.status === 200) {
        const boldBIToken = response.data;
        dispatch(updateBoldBIToken(boldBIToken));
        const keyValueMap = commonDetails.propDefaultValues.reduce(
          (acc, obj) => {
            const { key, value } = obj;
            acc[key] = value;
            return acc;
          },
          {}
        );
        const baseURL = keyValueMap["boldreport.baseurl"];
        const sitePath = keyValueMap["boldreport.sitepath"];
        getReports(boldBIToken, baseURL, sitePath);
      }
    } catch (err) {
      console.log("failed to fetch Bold BI Token");
    }
  }, []);

  const getReports = useCallback(async (token, baseURL, sitePath) => {
    try {
      const response = await BoldBiServices.getBoldBiReports(
        token,
        baseURL,
        sitePath
      );
      dispatch(updateBoldBIReports(response));
    } catch (err) {
      dispatch(updateBoldBIReports([]));
      console.log("failed to fetch bold bi reports");
    }
  }, []);

  const getGridConfigs = useCallback(async () => {
    try {
      const response = await CommonService.getAllColumnGrid({});
      if (response.status === 200) {
        dispatch(setGridConfigs(response.data));
      }
    } catch (err) {
      console.log("failed to fetch grid configs");
    }
  }, []);

  const getClientDetails = useCallback(async (data) => {
    try {
      const response = await ClientService.getClientDetails(data.clientId);
      if (response.status === 200) {
        dispatch(setClientDetails(response.data));
      }
    } catch (err) {}
  }, []);

  const getCommonDetails = useCallback(async () => {
    try {
      const response = await CommonService.getCommonDetails({});
      if (response.status === 200) {
        if (Object.keys(response.data).length > 0) {
          dispatch(setCommonDetails(response.data));
          getBoldBIToken(response.data);
        }
      }
    } catch (err) {}
  }, []);

  //Get Partner Details
  const getPartnerList = useCallback(async () => {
    try {
      dispatch(setVendorList([]));
      const response = await TradingPartnerService.getVendorInfo({});
      if (response.status === 200) {
      }
    } catch (err) {}
  }, []);

  return <>{appReady && <Router />}</>;
}

import {
    DESELECT_PURCHASE_ORDERS,
    SELECT_PURCHASE_ORDERS,
    AUTO_FREIGHT_PO_SELECT,
    AUTO_FREIGHT_PO_DESELECT,
    AUTO_FREIGHT_LOAD_SELECT,
    AUTO_FREIGHT_LOAD_DESELECT,
    CLEAR_SELECTED_PURCHASE_ORDERS,
    SELECT_PO_FOR_ADDTOEXISTINGLOAD,
    CLEAR_SELECTED_PURCHASE_ORDERS_FOR_ADDTOEXISTINGLOAD,
    DESELECT_PO_FOR_ADDTOEXISTINGLOAD,
} from "../constants";

const initialState = {
    selectedPurchaseOrders: [],
    selectedPurchaseOrdersAddLoad: [],
    autoFreightPO: [],
    autoFreightLoad: [],
}

export default (state = initialState, { type, payload }) => {
    // console.log('payload :: ', state);
    switch (type) {
        case SELECT_PURCHASE_ORDERS:
            const poIndex = state.selectedPurchaseOrders.findIndex(po => po.id === payload.id)
            if (poIndex === -1) {
                console.log('In Selected purchase orders');
                return {
                    ...state, selectedPurchaseOrders: [...state.selectedPurchaseOrders, payload]
                };
            }
        case DESELECT_PURCHASE_ORDERS:
            console.log('In Deselect purchase orders');
            const index = state.selectedPurchaseOrders.findIndex(po => po.id === payload.id)

            return {
                ...state, selectedPurchaseOrders: [
                    ...state.selectedPurchaseOrders.slice(0, index),
                    ...state.selectedPurchaseOrders.slice(index + 1)
                ]
            };

        case CLEAR_SELECTED_PURCHASE_ORDERS:
            return {
                ...state, selectedPurchaseOrders: []
            };
        case CLEAR_SELECTED_PURCHASE_ORDERS_FOR_ADDTOEXISTINGLOAD:
            return {
                ...state, selectedPurchaseOrdersAddLoad: []
            };
        case AUTO_FREIGHT_PO_SELECT:
            const autoFreightPOIndex = state.autoFreightPO.findIndex(po => po?.id === payload?.id);

            if (autoFreightPOIndex === -1) {
                return { ...state, autoFreightPO: [...state.autoFreightPO, payload] }
            }
        case AUTO_FREIGHT_PO_DESELECT:
            return { ...state, autoFreightPO: [] }
        case AUTO_FREIGHT_LOAD_SELECT:
            if (state.autoFreightLoad.length > 0) {
                const autoFreightLoadIndex = state.autoFreightLoad.findIndex(load => load?.loadId === payload?.loadDetailsDto?.loadId);

                if (autoFreightLoadIndex === -1) {
                    return { ...state, autoFreightLoad: [...state.autoFreightLoad, payload.loadDetailsDto] }
                }
            }
        case AUTO_FREIGHT_LOAD_DESELECT:
            return {
                ...state, autoFreightLoad: []
            }
        case SELECT_PO_FOR_ADDTOEXISTINGLOAD:
            const poAddIndex = state.selectedPurchaseOrdersAddLoad.findIndex(po => po.id === payload.id)
            if (poAddIndex === -1) {
                console.log('Selected purchase orders For Add to Load');
                return {
                    ...state, selectedPurchaseOrdersAddLoad: [...state.selectedPurchaseOrdersAddLoad, payload]
                };
            }
        case DESELECT_PO_FOR_ADDTOEXISTINGLOAD:
            console.log('In Deselect purchase orders');
            const delindex = state.selectedPurchaseOrdersAddLoad.findIndex(po => po.id === payload.id)

            return {
                ...state, selectedPurchaseOrdersAddLoad: [
                    ...state.selectedPurchaseOrdersAddLoad.slice(0, delindex),
                    ...state.selectedPurchaseOrdersAddLoad.slice(delindex + 1)
                ]
            };
        default:
            return state;
    }
}
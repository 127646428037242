import http from "./http.service";
import config from "./constant";
import FileSaver from "file-saver";
import { store } from "../store";

const saveAsTemplatePO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.saveAsTemplate,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const createPO = async (params) => {
  try {
    // const reqParams = {
    //   ...params,
    // };
    const response = await http.post(config.Purchasing.createPO, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPoList = async () => {
  try {
    const response = await http.get(config.Purchasing.getPoList, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const poSearch = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.searchPO, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getItemCodeDetails = async (reqParams) => {
  try {
    const response = await http.post(
      config.ItemMaster.itemvendorpoList,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTemplates = async () => {
  try {
    const response = await http.get(config.Purchasing.getTemplates, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadAttachment = async (formData) => {
  try {
    const response = await http.post(
      config.Purchasing.addAttachment,
      formData,
      { "content-type": "multipart/form-data" }
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTemplateById = async (templateId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.getTemplateDetails}/${templateId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const poUpload = async (formData) => {
  try {
    const response = await http.post(config.Purchasing.poUpload, formData, {
      "content-type": "multipart/form-data",
    });
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const poUploadSummary = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      config.Purchasing.poUploadSummary,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const poErrorFileDownload = async (params) => {
  try {
    const response = await http.get(
      `${config.Purchasing.poUploadFailure}/${params.id}`,
      {},
      {},
      {
        responseType: "blob",
      }
    );
    // showXlsxFile(response.data, params.uploadId);
    FileSaver.saveAs(response.data, "po-Error-template.xlsx");
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const poExport = async (params, fileName) => {
  try {
    const response = await http.post(
      `${config.Purchasing.export}`,
      params,
      {},
      {
        responseType: "blob",
      }
    );
    // showXlsxFile(response.data, params.uploadId);
    FileSaver.saveAs(response.data, `${fileName}.${params.fileType}`);
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const printPDF = async (poNumber, poNum) => {
  try {
    const response = await http.get(
      `${config.Purchasing.printPDF}/${poNumber}`,
      {},
      {},
      {
        responseType: "blob",
      }
    );

    FileSaver.saveAs(
      response.data,
      "PurchaseOrder - " + poNum + " - " + new Date().getTime() + ".pdf"
    );
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPoDetails = async (poNumber) => {
  try {
    const response = await http.get(
      `${config.Purchasing.getPoList}/${poNumber}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTargetPoDetails = async (poNumber) => {
  try {
    const response = await http.get(
      `/purchaseOrder/pos/byPoNumber/${poNumber}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const submitDemandPo = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.demandPo, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const rejectPO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.rejectPO, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const searchPOByStatus = async (params) => {
  try {
    const response = await http.post(
      config.Purchasing.searchPoByStatus,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const cancelPO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.cancelPO, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const confirmPO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.confirmByPo, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const defaultHeadCharges = async (params) => {
  try {
    const { app } = store.getState();
    const { clientDetails } = app;
    const reqParams = {
      ...params,
      clientType: clientDetails?.clientType,
    };
    const response = await http.post(
      config.Purchasing.defaultHeadCharges,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const defaultLineCharges = async (params) => {
  try {
    const { app } = store.getState();
    const { clientDetails } = app;
    const reqParams = {
      ...params,
      clientType: clientDetails?.clientType,
    };
    const response = await http.post(
      config.Purchasing.defaultLineCharges,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updatePO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.put(config.Purchasing.createPO, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getCopyPoDetails = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.copyNewPo, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchTargetPOs = async (poHeaderId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.fetchTargetPOsInfo}/${poHeaderId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchTargetVendorPOs = async (poHeaderId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.fetchTargetVendorPOsInfo}/${poHeaderId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const searchShippingPO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.searchShippingPO,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updatePoStatus = async (poId) => {
  try {
    const status = "SHIPPED";
    const response = await http.put(
      `${config.Purchasing.updatePoStatus}/${poId}/${status}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postCopyOrMove = async (params, moveFlag = false) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      `${config.Purchasing.copyOrMove}/${moveFlag}`,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const splitOnPO = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Purchasing.splitOnPO, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const lineLevelChargeUpdate = (poLineListCopy = [], chargeListCopy = []) => {
  try {
    let totalQuantity = 0;
    let finalCharges = 0;
    let totalHeaderFixCharge = 0;
    let totalHeaderLineCharge = 0;

    chargeListCopy.forEach((ele) => {
      if (ele.level === "Header" && ele.basis === "Fixed") {
        totalHeaderFixCharge += +ele.amount;
      }
      if (ele.level === "Header" && ele.basis === "Per Unit") {
        totalHeaderLineCharge += +ele.amount;
      }
    });

    if (poLineListCopy?.length > 0) {
      poLineListCopy.forEach((element) => {
        totalQuantity += +element.quantity;
      });

      if (totalQuantity > 0) {
        finalCharges =
          Math.round(
            (totalHeaderFixCharge / totalQuantity + Number.EPSILON) * 100
          ) /
            100 +
          totalHeaderLineCharge;
      } else {
        finalCharges = totalHeaderFixCharge + totalHeaderLineCharge;
      }

      poLineListCopy.forEach((element) => {
        element.chargeAmount = finalCharges;
      });

      chargeListCopy.forEach((ele) => {
        poLineListCopy.forEach((line) => {
          if (ele.itemNumber === line.itemNumber) {
            if (ele.level === "Line" && ele.basis === "Fixed") {
              if (+line.quantity > 0) {
                let finalLineCharges = (
                  Math.round(
                    (+ele.amount / +line.quantity + Number.EPSILON) * 100
                  ) / 100
                ).toFixed(4);
                line.chargeAmount += +finalLineCharges;
              } else {
                line.chargeAmount += +ele.amount;
              }
            }

            if (ele.level === "Line" && ele.basis === "Per Unit") {
              line.chargeAmount += +ele.amount;
            }
          }
        });
      });
      poLineListCopy.forEach((element) => {
        element.deliveredCost = element.chargeAmount;
      });
    } else {
      finalCharges = totalHeaderFixCharge + totalHeaderLineCharge;
    }
    return {
      status: 200,
      data: { totalCharges: finalCharges, poLineListCopy: [...poLineListCopy] },
    };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchPoReceiptInfo = async (lineId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.fecthPoReceiptInfo}/${lineId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllPoNumbers = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.get(
      config.Purchasing.getAllPoNumbers,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllShippingPoNumbers = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.get(
      config.Purchasing.getAllShippingPoNumbers,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const latestByBuyer = async (userName) => {
  try {
    const response = await http.get(
      `${config.Purchasing.latestByBuyer}/${userName}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const latestByVendor = async (vendorId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.latestByVendor}/${vendorId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const itemNumberSearch = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(
      config.Purchasing.itemNumberSearch,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getItemCodeDetailsItemId = async (itemId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getItemCodeDetailsItemId}${itemId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const callLogisticsOnTiHiChanges = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(
      config.Purchasing.callLogisticsOnTiHiChanges,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateHeaderNotes = async (headerId, data) => {
  try {
    const response = await http.put(
      `/purchaseOrder/pos/updateNotes/${headerId}`,
      data
    );
    return { status: response.status, data: response.data };
  } catch (error) {}
};

const updateLineLevelNotes = async (headerId, data) => {
  try {
    const response = await http.put(
      `/purchaseOrder/pos/updateLineNotes/${headerId}`,
      data
    );
    return { status: response.status, data: response.data };
  } catch (error) {}
};

const callLogisticsOnTiHiChangesOnItemMaster = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(
      config.Purchasing.callLogisticsOnTiHiChangesOnItemMaster,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const defaultTemplateCharges = async (data) => {
  try {
    const reqParams = {
      ...data,
    };
    const response = await http.post(
      config.Purchasing.defaultTemplateCharges,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadPO = async (formData) => {
  try {
    const response = await http.post(config.Purchasing.uploadPO, formData);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const changeVendorPo = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.changeVendorPo,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchItemMasterByItemCode = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.fetchItemMasterByItemCode,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchVendorItemMasterByItemCode = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.fetchVendorItemMasterByItemCode,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchSubDetails = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Purchasing.fetchSubDetails,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchVendorItemPriceDetails = async (params) => {
  try {
    const response = await http.post(
      config.Purchasing.fetchVendorItemPriceDetails,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateAutoFreightOnLoadLevelWhenCancelPO = async (poId, loadId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.updateAutoFreightOnLoadLevelWhenCancelPO}/poId/${poId}/loadId/${loadId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteChargeIds = async (chargeIds) => {
  try {
    const response = await http.delete(
      config.Purchasing.deleteChargeIds,
      chargeIds
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteTemplate = async (chargeIds) => {
  try {
    const response = await http.post(
      config.Purchasing.deleteTemplate,
      chargeIds
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const validateUpdatePOByLoadId = async (poId) => {
  try {
    const response = await http.get(
      `${config.Purchasing.validateUpdatePOByLoadId}/${poId}`
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const PurchaseOrderService = {
  getPoList,
  getItemCodeDetails,
  getTemplates,
  uploadAttachment,
  searchShippingPO,
  updatePoStatus,
  createPO,
  poSearch,
  saveAsTemplatePO,
  getTemplateById,
  poUpload,
  poUploadSummary,
  poErrorFileDownload,
  getPoDetails,
  cancelPO,
  rejectPO,
  defaultHeadCharges,
  defaultLineCharges,
  confirmPO,
  updatePO,
  getCopyPoDetails,
  fetchTargetPOs,
  getTargetPoDetails,
  submitDemandPo,
  postCopyOrMove,
  fetchTargetVendorPOs,
  splitOnPO,
  lineLevelChargeUpdate,
  fetchPoReceiptInfo,
  searchPOByStatus,
  poExport,
  printPDF,
  getAllPoNumbers,
  getAllShippingPoNumbers,
  latestByBuyer,
  itemNumberSearch,
  getItemCodeDetailsItemId,
  latestByVendor,
  callLogisticsOnTiHiChanges,
  updateHeaderNotes,
  updateLineLevelNotes,
  callLogisticsOnTiHiChangesOnItemMaster,
  defaultTemplateCharges,
  uploadPO,
  changeVendorPo,
  fetchItemMasterByItemCode,
  fetchVendorItemMasterByItemCode,
  fetchSubDetails,
  fetchVendorItemPriceDetails,
  updateAutoFreightOnLoadLevelWhenCancelPO,
  deleteChargeIds,
  deleteTemplate,
  validateUpdatePOByLoadId,
};

export default PurchaseOrderService;

// Make sure checkRoutePermissions are configured correctly. Based on permission defined on this object, AuthRoute component
// will determine whether user can or cannot access the page

import React from "react";
import UserService from "./services/user.service";

export const DashboardRoute = {
  link: "/home",
  title: "Dashboard",
  checkRoutePermissions: {
    skipPermissionsCheck: true,
  },
  component: React.lazy(() => import("./pages/dashboard/dashboard")),
};

export const DemandRoute = {
  link: "/demand",
  title: "Demand",
  checkRoutePermissions: {
    menuName: "Demand",
  },
  component: React.lazy(() => import("./pages/demand/demand")),
};

export const DemandFromTemplateRoute = {
  link: "/demandfromtemplate/:templateId",
  title: "Demand From Template",
  checkRoutePermissions: {
    menuName: "Demand",
  },
  component: React.lazy(() =>
    import("./pages/demand/create-demand-from-template")
  ),
};

export const CreateDemandFromDemandComponentRoute = {
  link: "/copydemand",
  title: "Create Demand From Demand Component",
  checkRoutePermissions: {
    menuName: "Demand",
  },
  component: React.lazy(() =>
    import("./pages/demand/create-demand-from-demand")
  ),
};

export const LogisticRoute = {
  link: "/logistics",
  title: "Logistics",
  checkRoutePermissions: {
    menuName: "Logistics",
  },
  component: React.lazy(() => import("./pages/logistic/logistic")),
};

export const LoadManagerRoute = {
  link: "/logistics/load-manager",
  title: "Load Manager",
  checkRoutePermissions: {
    menuName: LogisticRoute.checkRoutePermissions.menuName,
    tileName: "Load Manager",
  },
  component: React.lazy(() =>
    import("./pages/logistic/load-manager/load-manager")
  ),
};

export const LoadDetailsRoute = {
  link: "/logistics/load-manager/details",
  title: "Load Details",
  checkRoutePermissions: {
    menuName: LogisticRoute.checkRoutePermissions.menuName,
    tileName: "Load Details",
  },
  component: React.lazy(() =>
    import("./pages/logistic/load-manager/loadDetails-LM-form")
  ),
};

export const LoadSummaryRoute = {
  link: "/logistics/load-summary",
  title: "Load Summary",
  checkRoutePermissions: {
    menuName: LogisticRoute.checkRoutePermissions.menuName,
    tileName: "Load Summary",
  },
  component: React.lazy(() =>
    import(
      "./pages/logistic/load-manager/carrier-version/load-manager-carrier-summary"
    )
  ),
};

export const LoadSummaryLoadDetailsRoute = {
  link: "/logistics/load-summary/:loadNumber",
  title: "Load Details",
  checkRoutePermissions: {
    menuName: LogisticRoute.checkRoutePermissions.menuName,
    tileName: "Load Summary",
  },
  component: React.lazy(() =>
    import("./pages/logistic/load-manager/carrier-version/summary-load-details")
  ),
};

export const PurchasingRoute = {
  link: "/purchasing",
  title: "Purchasing",
  checkRoutePermissions: {
    menuName: "Purchasing",
  },
  component: React.lazy(() => import("./pages/purchasing/purchasing")),
};

export const PurchasingPoRoute = {
  link: "/purchasing/PO",
  title: "Purchasing PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: "Purchase Order Management",
  },
  component: React.lazy(() => import("./pages/purchasing/purchasingTabs")),
};

export const PurchasingPoTemplateIdRoute = {
  link: "/purchasing/PO/:templateId",
  title: "Purchasing PO Template Id",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: "Create PO From Demand",
  },
  component: React.lazy(() => import("./pages/purchasing/CreatePO")),
};

export const ModifyBuyerPORoute = {
  link: "/purchasing/PO/modify-buyer/:poNumber",
  title: "Modify Buyer PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: PurchasingPoRoute.checkRoutePermissions.tileName,
    tabName: "PO Summary",
  },
  component: React.lazy(() =>
    import("./pages/purchasing/modify-purchasing-buyer")
  ),
};

export const ModifyVendorPORoute = {
  link: "/purchasing/PO/modify-vendor/:poNumber",
  title: "Modify Vendor PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: PurchasingPoRoute.checkRoutePermissions.tileName,
    tabName: "PO Summary",
  },
  component: React.lazy(() =>
    import("./pages/purchasing/modify-purchasing-vendor")
  ),
};

export const CopyPORoute = {
  link: "/purchasing/PO/copyPO",
  title: "Copy PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: PurchasingPoRoute.checkRoutePermissions.tileName,
    tabName: "PO Summary",
  },
  component: React.lazy(() =>
    import("./pages/purchasing/modify-purchasing-buyer")
  ),
};

export const DemandPORoute = {
  link: "/purchasing/demand-PO",
  title: "Demand PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
    tileName: "Create PO From Demand",
  },
  component: React.lazy(() =>
    import("./pages/purchasing/demandPO/po-from-demand")
  ),
};

export const DemandExistingPORoute = {
  link: "/purchasing/demand-existing-PO",
  title: "Demand Existing PO",
  checkRoutePermissions: {
    menuName: PurchasingRoute.checkRoutePermissions.menuName,
  },
  component: React.lazy(() =>
    import("./pages/purchasing/demandPO/add-to-po-from-demand")
  ),
};

export const MasterDataRoute = {
  link: "/masterData",
  title: "Master Data",
  checkRoutePermissions: {
    menuName: "Master Data",
  },
  component: React.lazy(() => import("./pages/masterData/masterData")),
};

export const ItemMasterRoute = {
  link: `${MasterDataRoute.link}/item-master`,
  title: "Item Master",
  checkRoutePermissions: {
    menuName: MasterDataRoute.checkRoutePermissions.menuName,
    tileName: "Item Master",
  },
  component: React.lazy(() =>
    import("./pages/masterData/item-master/item-master")
  ),
};

export const ItemMasterAddRoute = {
  link: `${ItemMasterRoute.link}/add`,
  title: "Add New Item",
  checkRoutePermissions: {
    menuName: ItemMasterRoute.checkRoutePermissions.menuName,
    tileName: ItemMasterRoute.checkRoutePermissions.tileName,
    tabName: "Item Details",
    writeAccess: true,
  },
  component: React.lazy(() =>
    import("./pages/masterData/item-master/item-add")
  ),
};

export const CarrierRatesRoute = {
  link: `${MasterDataRoute.link}/carrier-rates`,
  title: "Carrier Rates",
  checkRoutePermissions: {
    menuName: MasterDataRoute.checkRoutePermissions.menuName,
    tileName: "Carrier Rates",
  },
  component: React.lazy(() =>
    import("./pages/masterData/trading-partner/carrier-rates/carrier-rates")
  ),
};

export const VendorPriceListRoute = {
  link: `${MasterDataRoute.link}/vendor-price-list`,
  title: "Vendor Price List",
  checkRoutePermissions: {
    menuName: MasterDataRoute.checkRoutePermissions.menuName,
    tileName: "Vendor Price List",
  },
  component: React.lazy(() =>
    import(
      "./pages/masterData/trading-partner/vender-price-list/vender-price-list"
    )
  ),
};

export const ThirdPartyChargesRoute = {
  link: `${MasterDataRoute.link}/third-party-charges`,
  title: "Third Party Charges",
  checkRoutePermissions: {
    menuName: MasterDataRoute.checkRoutePermissions.menuName,
    tileName: "Third Party Charges",
  },
  component: React.lazy(() =>
    import(
      "./pages/masterData/trading-partner/third-party-charges/third-party-charges"
    )
  ),
};

export const TradingPartnersRoute = {
  link: `${MasterDataRoute.link}/trading-partners`,
  title: "Trading Partners",
  checkRoutePermissions: {
    menuName: MasterDataRoute.checkRoutePermissions.menuName,
    tileName: "Trading Partners",
  },
  component: React.lazy(() =>
    import("./pages/masterData/trading-partner/trading-list")
  ),
};

export const ModifyTradingPartnerRoute = {
  link: `${TradingPartnersRoute.link}/modify-partner`,
  title: "Modify Partner",
  checkRoutePermissions: {
    menuName: TradingPartnersRoute.checkRoutePermissions.menuName,
    tileName: TradingPartnersRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/masterData/trading-partner/modify-partner")
  ),
};

export const SetupRoute = {
  link: "/setup",
  title: "Setup",
  checkRoutePermissions: {
    menuName: "Setup",
  },
  component: React.lazy(() => import("./pages/setup/setup")),
};

export const BusinessGroupRoute = {
  link: `${SetupRoute.link}/business-group`,
  title: "Business Group",
  checkRoutePermissions: {
    menuName: SetupRoute.checkRoutePermissions.menuName,
    tileName: "Business Group",
  },
  component: React.lazy(() =>
    import("./pages/setup/business-group/business-group")
  ),
};

export const BusinessGroupComanyRoute = {
  link: `${BusinessGroupRoute.link}/company/create`,
  title: "Comany",
  checkRoutePermissions: {
    menuName: BusinessGroupRoute.checkRoutePermissions.menuName,
    tileName: BusinessGroupRoute.checkRoutePermissions.tileName,
    tabName: "Company",
  },
  component: React.lazy(() =>
    import("./pages/setup/business-group/company/company-create")
  ),
};

export const BusinessGroupLocationRoute = {
  link: `${BusinessGroupRoute.link}/location`,
  title: "Location",
  checkRoutePermissions: {
    menuName: BusinessGroupRoute.checkRoutePermissions.menuName,
    tileName: BusinessGroupRoute.checkRoutePermissions.tileName,
    tabName: "Location",
  },
  component: React.lazy(() =>
    import("./pages/setup/business-group/business-group")
  ),
};

export const BusinessGroupLocationCreateRoute = {
  link: `${BusinessGroupRoute.link}/location/create`,
  title: "Location Details",
  checkRoutePermissions: {
    ...BusinessGroupLocationRoute.checkRoutePermissions,
  },
  component: React.lazy(() =>
    import("./pages/setup/business-group/location/location-create")
  ),
};

export const SetupAdminRoute = {
  link: `${SetupRoute.link}/admin`,
  title: "Setup Admin",
  checkRoutePermissions: {
    menuName: SetupRoute.checkRoutePermissions.menuName,
    tileName: "Admin",
  },
  component: React.lazy(() => import("./pages/setup/admin/admin")),
};

export const SetupAdminUserManagementRoute = {
  link: `${SetupRoute.link}/user_Management`,
  title: "Setup Admin User Management",
  checkRoutePermissions: {
    menuName: SetupAdminRoute.checkRoutePermissions.menuName,
    tileName: SetupAdminRoute.checkRoutePermissions.tileName,
    tabName: "User Management",
  },
  component: React.lazy(() => import("./pages/setup/admin/admin")),
};

export const SetupModifyUserRoute = {
  link: `${SetupAdminUserManagementRoute.link}/modifyUser`,
  title: "Setup Modify User",
  checkRoutePermissions: {
    ...SetupAdminUserManagementRoute.checkRoutePermissions,
  },
  component: React.lazy(() =>
    import("./pages/setup/admin/user-management/modify-user")
  ),
};

export const SetupAddUserRoute = {
  link: `${SetupAdminUserManagementRoute.link}/addUser`,
  title: "Setup Add User",
  checkRoutePermissions: {
    ...SetupAdminUserManagementRoute.checkRoutePermissions,
  },
  component: React.lazy(() =>
    import("./pages/setup/admin/user-management/add-user")
  ),
};

export const SetupApplicationConfigurationRoute = {
  link: `${SetupRoute.link}/application-configuration`,
  title: "Application Configuration",
  checkRoutePermissions: {
    menuName: SetupRoute.checkRoutePermissions.menuName,
    tileName: "Application Config",
  },
  component: React.lazy(() =>
    import("./pages/setup/application-configuration/application-configuration")
  ),
};

export const SetupLookupsRoute = {
  link: `${SetupRoute.link}/lookups`,
  title: "Look ups",
  checkRoutePermissions: {
    menuName: SetupRoute.checkRoutePermissions.menuName,
    tileName: "LookUps",
  },
  component: React.lazy(() => import("./pages/setup/lookups/lookups")),
  exceptionRule: (user) => UserService.isLlAdmin(),
};

export const SetupUserPreferencesRoute = {
  link: `${SetupRoute.link}/user-preferences`,
  title: "User Preferences",
  checkRoutePermissions: {
    menuName: SetupRoute.checkRoutePermissions.menuName,
    tileName: "User Preferences",
  },
  component: React.lazy(() =>
    import("./pages/setup/user-preferences/user-preferences")
  ),
};

export const Analytics = {
  link: "/analytics",
  title: "Analytics",
  checkRoutePermissions: {
    skipPermissionsCheck: true,
  },
  component: React.lazy(() => import("./pages/analytics/analytics")),
};

export const BiReports = {
  link: "/analytics/:name",
  generateUrl: (name) => `/analytics/${name}`,
  title: "Report",
  checkRoutePermissions: {
    skipPermissionsCheck: true,
  },
  component: React.lazy(() => import("./pages/analytics/BiReports")),
};

export const ReceivingRoute = {
  link: "/receiving",
  title: "Receiving",
  checkRoutePermissions: {
    menuName: "Receiving",
  },
  component: React.lazy(() => import("./pages/receiving/receiving")),
};

export const ReceivingClaimAdminRoute = {
  link: `${ReceivingRoute.link}/claim-admin`,
  title: "Claim Admin",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: "Claim Admin",
  },
  component: React.lazy(() => import("./pages/receiving/admin/admin")),
};

export const ReceivingClaimSummaryRoute = {
  link: `${ReceivingRoute.link}/claim-summary`,
  title: "Claim Summary",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: "Claim Summary",
  },
  component: React.lazy(() =>
    import("./pages/receiving/claim-management/claim-summary")
  ),
};

export const ReceivingCreateClaimRoute = {
  link: `${ReceivingClaimSummaryRoute.link}/create-claim`,
  title: "Create Claim",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: ReceivingClaimSummaryRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/receiving/claim-management/create-claim")
  ),
};

export const ReceivingCreateClaimClaimNumRoute = {
  link: `${ReceivingCreateClaimRoute.link}/:claimNum`,
  title: "Create Claim",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: ReceivingClaimSummaryRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/receiving/claim-management/create-claim")
  ),
};

export const ReceivingCreateClaimViewClaimNumRoute = {
  link: `${ReceivingRoute.link}/create-claim/view/:mode/:claimNum`,
  title: "Create Claim",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: ReceivingClaimSummaryRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/receiving/claim-management/create-claim-view")
  ),
};

export const ReceivingAdminClaimDetailRoute = {
  link: `${ReceivingClaimAdminRoute.link}/claim-details/:claimNum`,
  title: "Claim Detail",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: ReceivingClaimAdminRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() => import("./pages/receiving/admin/claim-details")),
};

export const ReceivingReceiptRoute = {
  link: `${ReceivingRoute.link}/receipt`,
  title: "Receipt",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: "Receiving",
  },
  component: React.lazy(() => import("./pages/receiving/receipt/receipt")),
};

export const ReceivingReceiptDetailsFormRoute = {
  link: `${ReceivingReceiptRoute.link}/:poId`,
  title: "Receipt Detail Form",
  checkRoutePermissions: {
    menuName: ReceivingRoute.checkRoutePermissions.menuName,
    tileName: ReceivingReceiptRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/receiving/receipt/receipt-details-form")
  ),
};

export const BillingRoute = {
  link: "/billing",
  title: "Billing",
  checkRoutePermissions: {
    menuName: "Billing",
  },
  component: React.lazy(() => import("./pages/billing/billing")),
};

export const BillingCustomerInvoicesRoute = {
  link: `${BillingRoute.link}/customer-invoices`,
  title: "Billing Customer Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: "Customer Invoices",
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-vendor/customer-invoice-list")
  ),
};

export const BillingCustomerGenerateInvoiceRoute = {
  link: `${BillingCustomerInvoicesRoute.link}/generate-invoice`,
  title: "Billing Customer Generate Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCustomerInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-vendor/generate-customer-invoice")
  ),
};

export const BillingCustomerInvoicesNumberRoute = {
  link: `${BillingCustomerInvoicesRoute.link}/:invoiceNumber`,
  title: "Billing Customer Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCustomerInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-vendor/generate-customer-invoice")
  ),
};

export const BillingCustomerInvoiceNumberRoute = {
  link: `${BillingRoute.link}/customer-invoice/:invoiceNumber`,
  title: "Billing Customer Invoice",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCustomerInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-vendor/customer-invoice-view")
  ),
};

export const BillingCarrierInvoicesRoute = {
  link: `${BillingRoute.link}/carrier-invoices`,
  title: "Billing Carrier Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: "Carrier Invoices",
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-carrier/carrier-invoice-list")
  ),
};

export const BillingCarrierGenerateInvoiceRoute = {
  link: `${BillingCarrierInvoicesRoute.link}/generate-invoice`,
  title: "Billing Carrier Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCarrierInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-carrier/generate-carrier-invoice")
  ),
};

export const BillingCarrierInvoicesNumberRoute = {
  link: `${BillingCarrierInvoicesRoute.link}/:invoiceNumber`,
  title: "Billing Carrier Invoices",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCarrierInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-carrier/generate-carrier-invoice")
  ),
};

export const BillingCarrierInvoiceNumberRoute = {
  link: `${BillingRoute.link}/carrier-invoice/:invoiceNumber`,
  title: "Billing Carrier Invoice",
  checkRoutePermissions: {
    menuName: BillingRoute.checkRoutePermissions.menuName,
    tileName: BillingCarrierInvoicesRoute.checkRoutePermissions.tileName,
  },
  component: React.lazy(() =>
    import("./pages/billing/billing-carrier/carrier-invoice-view")
  ),
};

export const HeaderColumns = Object.freeze({
  USER_MANAGEMENT: [
    {
      field: "userName",
      headerText: "Username",
      textAlign: "Left",
      visible: true
    },
    {
      field: "firstName",
      headerText: "First Name",
      textAlign: "Left",
      visible: true
    },
    {
      field: "lastName",
      headerText: "Last Name",
      textAlign: "Left",
      visible: true
    },
    { field: "email", headerText: "Email", textAlign: "Left", visible: true },
    {
      field: "vmibuyer",
      headerText: "VMI Buyer",
      width: "70",
      textAlign: "center",
      visible: true
    },
    {
      field: "status",
      headerText: "Status",
      width: "80",
      textAlign: "center",
      visible: true
    },
    {
      field: "buyer",
      headerText: "buyer",
      width: "80",
      textAlign: "center",
      visible: false
    }
  ],
  USER_MANAGEMENT_LLADMIN: [
    {
      field: "userName",
      headerText: "Username",
      textAlign: "Left",
      visible: true
    },
    {
      field: "firstName",
      headerText: "First Name",
      textAlign: "Left",
      visible: true
    },
    {
      field: "lastName",
      headerText: "Last Name",
      textAlign: "Left",
      visible: true
    },
    { field: "email", headerText: "Email", textAlign: "Left", visible: true },
    {
      field: "status",
      headerText: "Status",
      width: "70",
      textAlign: "center",
      visible: true
    }
  ],
  TRADING_PARTNER: [
    {
      field: "partnerNumber",
      headerText: "Partner #",
      width: "70",
      textAlign: "right",
      visible: true
    },
    {
      field: "partnerName",
      headerText: "Partner Name",
      textAlign: "Left",
      visible: true
    },
    {
      field: "address",
      headerText: "Address",
      textAlign: "Left",
      visible: true
    },
    {
      field: "dunsNumber",
      headerText: "Duns #",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "vendorFlag",
      headerText: "Vendor",
      width: "60",
      textAlign: "center",
      visible: true
    },
    {
      field: "customerFlag",
      headerText: "Customer",
      width: "60",
      textAlign: "center",
      visible: true
    },
    {
      field: "carrierFlag",
      headerText: "Carrier",
      width: "60",
      textAlign: "center",
      visible: true
    },
    {
      field: "partnerMode",
      headerText: "Partner Mode",
      width: "80",
      textAlign: "center",
      visible: false
    },

    {
      field: "active",
      headerText: "Status",
      width: "80",
      textAlign: "center",
      visible: true
    }
  ],

  ITEM_MASTER_CREATE: [
    {
      field: "itemCode",
      headerText: "Item code",
      width: "5",
      textAlign: "left",
      visible: true
    },
    {
      field: "itemDescription",
      headerText: "Item Description",
      width: "15",
      textAlign: "Left",
      visible: true
    },
    {
      field: "wareHouse",
      headerText: "Warehouse",
      width: "8",
      textAlign: "Left",
      visible: true
    },
    {
      field: "internalItemCtgry",
      headerText: "Category",
      width: "5",
      textAlign: "Left",
      visible: true
    },
    {
      field: "statusFlag",
      headerText: "Status",
      width: "5",
      textAlign: "center",
      visible: true
    }
  ],
  CALENDER_CREATE: [
    {
      field: "period",
      headerText: "PERIOD NUM",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "startDate",
      headerText: "START DATE",
      width: "90",
      textAlign: "Left",
      visible: true
    },
    {
      field: "endDate",
      headerText: "END DATE",
      width: "90",
      textAlign: "Left",
      visible: true
    },
    {
      field: "year",
      headerText: "YEAR",
      width: "60",
      textAlign: "right",
      visible: true
    },
    {
      field: "quarter",
      headerText: "QUARTER",
      width: "70",
      textAlign: "right",
      visible: true
    }
  ],

  COMPANY: [
    {
      field: "name",
      headerText: "Company Name",
      textAlign: "left",
      visible: true
    },
    {
      field: "shortName",
      headerText: "Company Short Name",
      width: "90",
      textAlign: "Left",
      visible: true
    },
    {
      field: "dunsNum",
      headerText: "DUNS #",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "regNum",
      headerText: "Registration #",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "currency",
      headerText: "Currency",
      width: "60",
      textAlign: "left",
      visible: true
    },
    {
      field: "cityState",
      headerText: "City & State",
      textAlign: "left",
      visible: true
    },
    {
      field: "active",
      headerText: "Status",
      width: "60",
      textAlign: "center",
      visible: true
    }
  ],

  CLIENTS: [
    {
      field: "clientId",
      headerText: "Client #",
      width: "70",
      textAlign: "right",
      visible: true
    },
    {
      field: "clientType",
      headerText: "Client Type",
      textAlign: "Left",
      visible: true
    },
    {
      field: "clientName",
      headerText: "Client Name",
      textAlign: "Left",
      visible: true
    },
    {
      field: "shortName",
      headerText: "Short Name",
      width: "90",
      textAlign: "left",
      visible: true
    },
    {
      field: "contact",
      headerText: "Contact",
      width: "90",
      textAlign: "left",
      visible: true
    },
    {
      field: "address",
      headerText: "Address",
      textAlign: "left",
      visible: true
    },
    {
      field: "phone",
      headerText: "Phone",
      width: "80",
      textAlign: "right",
      visible: true
    },
    {
      field: "online",
      headerText: "Status",
      width: "60",
      textAlign: "center",
      visible: true
    }
  ],
  LOOKUPS: [
    {
      field: "value",
      headerText: "Value",
      width: "5",
      textAlign: "left",
      visible: true
    },
    {
      field: "description",
      headerText: "Description",
      width: "15",
      textAlign: "Left",
      visible: true
    },
    {
      field: "delete",
      headerText: "",
      width: "5",
      textAlign: "center",
      visible: true
    }
  ],

  LOCATION: [
    {
      field: "locationCode",
      headerText: "Location Code",
      textAlign: "right",
      width: "75",
      visible: true
    },
    {
      field: "locationName",
      headerText: "Location Name",
      textAlign: "left",
      visible: true
    },
    {
      field: "shortName",
      headerText: "Location Short Name",
      textAlign: "left",
      visible: true
    },
    {
      field: "company",
      headerText: "Company Name",
      textAlign: "left",
      visible: false
    },
    {
      field: "duns",
      headerText: "DUNS #",
      width: "75",
      textAlign: "right",
      visible: true
    },
    {
      field: "cityCountry",
      headerText: "City & State",
      textAlign: "left",
      visible: true
    },
    {
      field: "active",
      headerText: "Status",
      width: "60",
      textAlign: "center",
      visible: true
    }
  ],

  CREATE_DEMAND_DETAILS: [
    { field: "index", type: "createDemand", width: "0", visible: true },
    { field: "selectBox", type: "createDemand", width: "30", visible: true },
    {
      field: "itemNumber",
      headerText: "ITEM #",
      width: "110",
      textAlign: "Left",
      editType: "dropDownEdit",
      visible: true
    },
    {
      field: "itemDescription",
      headerText: "ITEM DESCRIPTION",
      width: "210",
      textAlign: "Left",
      visible: true
    },
    {
      field: "packSize",
      headerText: "PACK SIZE",
      width: "60",
      textAlign: "right",
      visible: true
    },
    {
      field: "quantity",
      headerText: "QUANTITY",
      width: "210",
      textAlign: "center",
      visible: true
    }
  ],

  MANAGE_DEMAND_DETAILS: [
    { type: "checkbox", width: "50", textAlign: "center", visible: true },
    {
      field: "demandNumber",
      headerText: "Demand #",
      textAlign: "right",
      visible: true
    },
    {
      field: "demandDesc",
      headerText: "Demand Description",
      width: "90",
      textAlign: "Left",
      visible: true
    },
    {
      field: "demandStatus",
      headerText: "status",
      width: "90",
      textAlign: "Left",
      visible: true
    },
    {
      field: "nationalBuyerName",
      headerText: "National Buyer",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "turnBuyerName",
      headerText: "Buyer",
      width: "90",
      textAlign: "right",
      visible: true
    },
    {
      field: "routingMethod",
      headerText: "Routing",
      textAlign: "right",
      visible: true
    },
    {
      field: "dateFrom",
      headerText: "Date From",
      width: "80",
      textAlign: "center",
      visible: true
    },
    {
      field: "dateTo",
      headerText: "Date To",
      width: "80",
      textAlign: "center",
      visible: true
    }
  ],

  MANAGE_DEMAND_LINES: [
    { type: "checkbox", width: "50", textAlign: "center", visible: true },
    {
      field: "demandNumber",
      headerText: "Demand#",
      width: 90,
      textAlign: "right",
      visible: true
    },
    {
      field: "demandDesc",
      headerText: "DMD DESC",
      width: 110,
      textAlign: "right",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "warehouseName",
      headerText: "RCV WHSE",
      width: 110,
      textAlign: "right",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "itemNumber",
      headerText: "ITEM #",
      width: 110,
      textAlign: "Left",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "itemDescription",
      headerText: "ITEM DESC",
      width: 110,
      textAlign: "Left",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    // {
    //   field: "nationalBuyerName",
    //   headerText: "Nat Buyer",
    //   width: 110,
    //   textAlign: "center",
    //   clipMode: 'EllipsisWithTooltip',
    //   visible: true
    // },
    // {
    //   field: "turnBuyerName",
    //   headerText: "Buyer",
    //   width: 110,
    //   textAlign: "center",
    //   clipMode: 'EllipsisWithTooltip',
    //   visible: true
    // },

    // { field: "startDate", headerText: "START DATE", width: 90, textAlign: "center", clipMode: 'EllipsisWithTooltip', visible: true },
    // { field: "endDate", headerText: "END DATE", width: 90, textAlign: "center", clipMode: 'EllipsisWithTooltip', visible: true },
    // {
    //   field: "transitDays",
    //   headerText: "TRANSIT DAYS",
    //   width: 90,
    //   textAlign: "center",
    //   visible: true
    // },

    {
      field: "shipDate",
      headerText: "SHP DT",
      width: 100,
      textAlign: "right",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "arrivalDate",
      headerText: "ARV DT",
      textAlign: "right",
      width: 100,
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "quantity",
      headerText: "QUANTITY",
      width: 90,
      textAlign: "center",
      visible: true
    },
    {
      field: "availableQuantity",
      headerText: "AVL QTY",
      width: 90,
      textAlign: "center",
      visible: true
    },
    {
      field: "totalQuantity",
      headerText: "TOTAL QTY",
      width: 90,
      textAlign: "center",
      visible: true
    },
    {
      field: "routingMethod",
      headerText: "Routing",
      width: 90,
      textAlign: "center",
      clipMode: "EllipsisWithTooltip",
      visible: true
    },
    {
      field: "status",
      headerText: "STATUS",
      width: 90,
      textAlign: "center",
      clipMode: "EllipsisWithTooltip",
      visible: true
    }
  ],
  UPLOAD_DEMAND: [
    {
      field: "fileName",
      headerText: "FILENAME",
      width: "210",
      textAlign: "right",
      visible: true
    },
    {
      field: "username",
      headerText: "USERNAME",
      width: "210",
      textAlign: "Left",
      visible: true
    },
    {
      field: "totalRecords",
      headerText: "TOTAL RECORDS",
      width: "110",
      textAlign: "Left",
      visible: true
    },
    {
      field: "successCount",
      headerText: "SUCCESS COUNT",
      width: "110",
      textAlign: "right",
      visible: true
    },
    {
      field: "failureCount",
      headerText: "FAILURE COUNT",
      width: "110",
      textAlign: "right",
      visible: true
    },
    {
      field: "status",
      headerText: "STATUS",
      width: "110",
      textAlign: "right",
      visible: true
    }
  ],
  DEMAND_FROM_DEMANDS: [
    { type: "checkbox", width: "50", textAlign: "center", visible: true },
    {
      field: "demandNumber",
      headerText: "Demand #",
      width: "100",
      textAlign: "right",
      visible: true
    },
    {
      field: "demandDesc",
      headerText: "Demand Description",
      width: "100",
      textAlign: "Left",
      visible: true
    },
    {
      field: "nationalBuyerName",
      headerText: "National Buyer",
      width: "100",
      textAlign: "right",
      visible: true
    },
    {
      field: "turnBuyerName",
      headerText: "Buyer",
      width: "100",
      textAlign: "right",
      visible: true
    },
    {
      field: "routingMethod",
      headerText: "Routing",
      width: "100",
      textAlign: "right",
      visible: true
    },
    {
      field: "receiveWarehouse",
      headerText: "Recv Whse",
      width: "100",
      textAlign: "right",
      visible: true
    },
    {
      field: "itemNumber",
      headerText: "Item #",
      width: 100,
      textAlign: "Left",
      visible: true
    },
    {
      field: "itemDescription",
      headerText: "Item Description",
      width: 100,
      textAlign: "Left",
      visible: true
    },
    {
      field: "startDate",
      headerText: "Date From",
      width: "160",
      editType: "datepickeredit",
      visible: true
    },
    {
      field: "endDate",
      headerText: "Date To",
      width: "160",
      editType: "datepickeredit",
      visible: true
    },
    {
      field: "transitDays",
      headerText: "Transit Days",
      width: "80",
      textAlign: "center",
      visible: true
    },
    {
      field: "totalQuantity",
      headerText: "Quantity",
      width: 70,
      textAlign: "center",
      visible: true
    }
  ],
  DEMAND_FROM_DEMANDS_LINES: [
    {
      field: "itemNumber",
      headerText: "ITEM #",
      width: 110,
      textAlign: "Left",
      visible: true
    },
    {
      field: "itemDescription",
      headerText: "ITEM DESCRIPTION",
      width: 110,
      textAlign: "Left",
      visible: true
    },
    // {
    //   field: "packSize",
    //   headerText: "PACK SIZE",
    //   width: 60,
    //   textAlign: "right"
    // },
    {
      field: "shipDate",
      headerText: "SHIP DATE",
      width: 80,
      textAlign: "right",
      visible: true
    },
    {
      field: "arrivalDate",
      headerText: "ARRV DATE",
      textAlign: "right",
      width: 80,
      visible: true
    },
    {
      field: "quantity",
      headerText: "QUANTITY",
      width: 70,
      textAlign: "center",
      visible: true
    }
  ]
});

export const filterSettings = {
  columns: [],
  type: "FilterBar",
  mode: "Immediate",
  showFilterBarStatus: false,
  immediateModeDelay: 10,
  operators: {},
  showFilterBarOperator: true,
  ignoreAccent: true
};

export const poFilterSettings = {
  columns: [],
  type: "Excel",
  mode: "Immediate",
  showFilterBarStatus: true,
  immediateModeDelay: 10,
  operators: {},
  showFilterBarOperator: true,
  ignoreAccent: true
};

export const itemMapfilterSettings = {
  columns: [],
  type: "Excel",
  mode: "Immediate",
  showFilterBarStatus: true,
  immediateModeDelay: 10,
  operators: {},
  showFilterBarOperator: true,
  ignoreAccent: true
};

export const demandPoFilterSettings = {
  columns: [],
  type: "CheckBox",
  mode: "Immediate",
  showFilterBarStatus: false,
  immediateModeDelay: 10,
  operators: {},
  showFilterBarOperator: true,
  ignoreAccent: true
};

export const getDefaultFilterOperator = (dataType = "string") => {
  if (dataType === "number") {
    return { operator: "equal" };
  } else if (dataType === "enum") {
    return { operator: "startswith" };
  }
  return { operator: "contains" };
};
export const pageOptions = {
  pageSize: 50,
  pageSizes: ["50", "100", "150", "200", "500", "1000"]
};

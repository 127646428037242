import React from "react";

export default function UserDetails({ selectedDetails = {} }) {
  return (
    <>
      {Object.keys(selectedDetails).length === 0 && (
        <div>Fetching data. Please wait a moment</div>
      )}
      {Object.keys(selectedDetails).length > 0 && (
        <>
          <div>
            <label>Username</label>
            <span>{selectedDetails?.userName}</span>
          </div>
          <div>
            <label>First Name</label>
            <span>{selectedDetails?.firstName}</span>
          </div>

          <div>
            <label>Last Name</label>
            <span>{selectedDetails?.lastName}</span>
          </div>

          <div>
            <label>Phone Number</label>
            <span>{selectedDetails?.phoneNumber || "-"}</span>
          </div>

          <div>
            <label>Email Address</label>
            <span>{selectedDetails?.email}</span>
          </div>

          <div>
            <label>Client Type</label>
            <span>{selectedDetails?.clientType}</span>
          </div>
        </>
      )}
    </>
  );
}

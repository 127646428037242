export function convertMMDDHHMMFomat(value) {
  try {
    const current = new Date(value).toISOString();
    const splitDate = current.split("T");
    const date = splitDate[0].split("-");
    const time = splitDate[1].split(":");
    const convertDate = `${date[1]}/${date[2]} ${time[0]}:${time[1]}`;
    return convertDate;
  } catch (err) {
    return "-";
  }
}

import http from "./http.service";
import config from "./constant";
import FileSaver from "file-saver";

const getLoadList = async (params) => {
  try {
    const response = await http.post(config.Logistics.getLoadList, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadDataById = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadDataById}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadDataByNum = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadDataByNumber}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addNotesToLoad = async (params, num) => {
  try {
    const response = await http.post(
      `${config.Logistics.addNotesToLoad}${num}`,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getPODetailsById = async (poNumber) => {
  try {
    const response = await http.get(
      `${config.Billing.getPODetails}${poNumber}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getCarriersAndSegments = async () => {
  try {
    const response = await http.get(config.Logistics.getCarrierAndSegments);
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCarrierAndTransportSegmentsByPOIds = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.getCarrierAndTransportSegmentsByPOIds,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const addLoad = async (params) => {
  try {
    const response = await http.post(config.Logistics.addLoad, params);
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateLoad = async (params) => {
  try {
    const response = await http.post(config.Logistics.updateLoad, params);
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const loadAutoFreight = async (loadData) => {
  try {
    const response = await http.post(
      config.Logistics.loadAutoFreight,
      loadData
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const getNotesByLoadId = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.getNotesByLoadId}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getCustomerLoads = async (params) => {
  try {
    const response = await http.post(config.Logistics.getCustomerLoads, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getItemCodeDetailsItemId = async (itemId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getItemCodeDetailsItemId}${itemId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getItemCodeDetailsItemIdInWhse = async (itemId, whseList) => {
  try {
    const response = await http.post(
      config.Logistics.getItemCodeDetailsItemIdInWhse,
      {
        itemCode: itemId,
        whseList: whseList,
      }
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateLoadCarrierArrivalDate = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.loadUpdateCarrier,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const autoFreightOnPO = async (params) => {
  try {
    const response = await http.post(config.Logistics.autoFreightOnPO, params);
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const autoFreightOnLoad = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.autoFreightOnLoad,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const segmentDetailsOnSegmentId = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.segmentDetatilsOnSegmentId,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const getCustomerListLoadSummary = async (itemId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getCustomersLoadSummary}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadsByCustId = async (itemId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadsByCustomerId}${itemId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const removePOsOnLoad = async (params) => {
  try {
    const response = await http.post(config.Logistics.removePOsOnLoad, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addPOToLoad = async (params) => {
  try {
    const response = await http.post(config.Logistics.addPOToLoad, params);
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const getLoadDetailsForAddToLoad = async (itemId) => {
  try {
    const response = await http.get(
      `${config.Logistics.loadDetailsForAddToLoad}${itemId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const fetchLoadDetailsForAddToLoad = async () => {
  try {
    const response = await http.get(
      `${config.Logistics.loadDetailsForAddToLoad}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getAuditInfoByLoadId = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.getAuditLogsByLoadId}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadLocationDetails = async (locId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadlocationDetails}${locId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadTradingPartnerDetails = async (carId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadTradingPartnerDetails}${carId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPOTradingPartnerDetails = async (carId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getPOTradingPartnerDetails}${carId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const changeLoadStatus = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.changeLoadStatus}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLoadsOnSearch = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.getLoadsOnSearch}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const searchByLoadNumberForAdd = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.searchByLoadNumberForAdd}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadUpdateStatus = async (lodId, statusId) => {
  try {
    const response = await http.get(
      `${config.Logistics.loadStatusUpdate}${lodId}/status/${statusId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadConfirmStatus = async (lodId) => {
  try {
    const response = await http.get(
      `${config.Logistics.loadStatusConfirmUpdate}${lodId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateArriDatesOnPoLoad = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.updateDatesOnPoLoad,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const updateArriDatesOnPoLoads = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.updateDatesOnPoLoads,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCarrierArrivalDatesOnPoLoad = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.updateCarrierArrivalDatesOnPoLoad,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateShipDatesOnPoLoads = async (params) => {
  try {
    const response = await http.post(
      config.Logistics.updateShipDatesOnPoLoads,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const getAllPONumbers = async () => {
  try {
    const response = await http.get(`${config.Logistics.getAllPONumbers}`, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadDataByCarrier = async (cName) => {
  try {
    const response = await http.get(
      `${config.Logistics.fetchLoadDetailsByCarrier}${cName}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadDataByWH = async (whName) => {
  try {
    const response = await http.get(
      `${config.Logistics.fetchLoadDetailsByWH}${whName}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const fetchVendorItemMasterByItemCode = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Logistics.fetchVendorItemMasterByItemCode,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getLoadNotificationData = async (num) => {
  try {
    const response = await http.get(
      `${config.Logistics.fetchLoadNotificationData}${num}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadConfirm = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(config.Logistics.confirmLoad, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const loadConfirmCarrier = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Logistics.confirmLoadCarrier,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const validateRecalPO = async (ldNum, poNum) => {
  try {
    const response = await http.get(
      `${config.Logistics.validateRecalPO}${ldNum}/${poNum}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const logisticsExport = async (params) => {
  try {
    const logisticsSummary = "Logistics";
    const response = await http.post(
      `${config.Logistics.logisticsExport}`,
      params,
      {},
      {
        responseType: "blob",
      }
    );
    // showXlsxFile(response.data, params.uploadId);
    FileSaver.saveAs(response.data, `${logisticsSummary}.${params.fileType}`);
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};
const generatePDFByLoadId = async (ldId, ldNum) => {
  try {
    const response = await http.get(
      `${config.Logistics.generatePDFByLoadId}${ldId}/${ldNum}`,
      {},
      {},
      {
        responseType: "blob",
      }
    );
    FileSaver.saveAs(response.data, `Load_${ldNum}.pdf`);
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchLoadTypesLOVs = async (params) => {
  try {
    const response = await http.get(
      config.Logistics.fetchLoadTypesLOVs,
      params
    );
    return { status: response.status, data: response.data };
  } catch (error) {
    return Promise.reject(error);
  }
};
const getAllPONumbersCarrier = async () => {
  try {
    const response = await http.get(
      `${config.Logistics.getAllPONumbersCarrier}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addAndRemovePOFromLoad = async (params) => {
  try {
    const reqParams = {
      ...params,
    };
    const response = await http.post(
      config.Logistics.addAndRemovePOFromLoad,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLoadCustDetails = async (clntId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getCustDetails}${clntId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBuyerCustDetails = async (clntId) => {
  try {
    const response = await http.get(
      `${config.Logistics.getBuyerCustDetails}${clntId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const LoadMangerService = {
  getLoadList,
  getLoadDataById,
  getCarriersAndSegments,
  getCarrierAndTransportSegmentsByPOIds,
  addLoad,
  updateLoad,
  addNotesToLoad,
  getPODetailsById,
  getNotesByLoadId,
  getCustomerLoads,
  getItemCodeDetailsItemId,
  getItemCodeDetailsItemIdInWhse,
  loadAutoFreight,
  updateLoadCarrierArrivalDate,
  autoFreightOnPO,
  autoFreightOnLoad,
  getCustomerListLoadSummary,
  getLoadsByCustId,
  removePOsOnLoad,
  addPOToLoad,
  getLoadDetailsForAddToLoad,
  getAuditInfoByLoadId,
  getLoadLocationDetails,
  getLoadTradingPartnerDetails,
  getPOTradingPartnerDetails,
  changeLoadStatus,
  segmentDetailsOnSegmentId,
  getLoadsOnSearch,
  getLoadDataByNum,
  loadUpdateStatus,
  loadConfirmStatus,
  updateArriDatesOnPoLoad,
  updateCarrierArrivalDatesOnPoLoad,
  updateArriDatesOnPoLoads,
  fetchLoadDetailsForAddToLoad,
  getAllPONumbers,
  loadDataByCarrier,
  loadDataByWH,
  fetchVendorItemMasterByItemCode,
  getLoadNotificationData,
  loadConfirm,
  loadConfirmCarrier,
  validateRecalPO,
  logisticsExport,
  generatePDFByLoadId,
  fetchLoadTypesLOVs,
  getAllPONumbersCarrier,
  addAndRemovePOFromLoad,
  getLoadCustDetails,
  getBuyerCustDetails,
  searchByLoadNumberForAdd,
  updateShipDatesOnPoLoads,
};

export default LoadMangerService;

// Auth
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
// App
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";

export const SET_LOGIN_DETAILS = "SET_LOGIN_DETAILS";

export const SET_SERVICE_LOADER = "SET_SERVICE_LOADER";

export const SET_PARTNER_DETAILS_BY_USERNAME =
  "SET_PARTNER_DETAILS_BY_USERNAME";

export const SET_SYSTEM_OPTIONS = "SET_SYSTEM_OPTIONS";

export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";

export const SET_GRID_CONFIGS = "SET_GRID_CONFIGS";

export const UPDATE_GRID_CONFIG = "UPDATE_GRID_CONFIG";

export const ADD_GRID_CONFIG = "ADD_GRID_CONFIG";

export const UPDATE_TEMP_GRID_CONFIG = "UPDATE_TEMP_GRID_CONFIG";

export const RESET_TEMP_GRID_CONFIG = "RESET_TEMP_GRID_CONFIG";

export const UPDATE_BOLD_BI_TOKEN = "UPDATE_BOLD_BI_TOKEN";

export const UPDATE_BOLD_BI_REPORTS = "UPDATE_BOLD_BI_REPORTS";

// Logistics
export const SELECT_PURCHASE_ORDERS = "SELECT_PURCHASE_ORDERS";

export const DESELECT_PURCHASE_ORDERS = "DESELECT_PURCHASE_ORDERS";

export const CLEAR_SELECTED_PURCHASE_ORDERS = "CLEAR_SELECTED_PURCHASE_ORDERS";

export const AUTO_FREIGHT_PO_SELECT = "AUTO_FREIGHT_PO_SELECT";

export const AUTO_FREIGHT_PO_DESELECT = "AUTO_FREIGHT_PO_DESELECT";

export const AUTO_FREIGHT_LOAD_DESELECT = "AUTO_FREIGHT_LOAD_DESELECT";

export const AUTO_FREIGHT_LOAD_SELECT = "AUTO_FREIGHT_LOAD_SELECT";

export const SET_COMMON_DETAILS = "SET_COMMON_DETAILS";

export const SELECT_PO_FOR_ADDTOEXISTINGLOAD =
  "SELECT_PO_FOR_ADDTOEXISTINGLOAD";

export const DESELECT_PO_FOR_ADDTOEXISTINGLOAD =
  "DESELECT_PO_FOR_ADDTOEXISTINGLOAD";
//Claims
export const SET_CLAIM_SUMMARY_DATA = "SET_CLAIM_SUMMARY_DATA";

export const SET_BILLING_SEARCH = "SET_BILLING_SEARCH";

export const SET_PO_SUMMARY_DATA = "SET_PO_SUMMARY_DATA";
export const SET_SHIPPING_SUMMARY_DATA = "SET_SHIPPING_SUMMARY_DATA";
export const SET_RECEIVING_SUMMARY_DATA = "SET_RECEIVING_SUMMARY_DATA";
export const CLEAR_SELECTED_PURCHASE_ORDERS_FOR_ADDTOEXISTINGLOAD =
  "CLEAR_SELECTED_PURCHASE_ORDERS_FOR_ADDTOEXISTINGLOAD";

export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED";

export const SET_VENDOR_LIST = "SET_VENDOR_LIST";

import { combineReducers } from "redux";
import AuthReducer from "./auth.reducer";
import AppReducer from "./app.reducer";
import LogisticsReducer from "./logistics.reducer";
import ClaimsReducer from "./claims.reducer";
import BillingReducer from "./billing.reducer";
import SummarySearchReducer from "./search.reducer";

const appReducer = combineReducers({
  auth: AuthReducer,
  app: AppReducer,
  logistics: LogisticsReducer,
  claims: ClaimsReducer,
  billing: BillingReducer,
  summarySearch: SummarySearchReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from "react";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs();
  console.log(breadcrumbs);
  return (
    <React.Fragment>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <span key={match.pathname}>
          <div className="brdCmb-home" key={match.url}>
            <Link
              className={index === breadcrumbs.length - 1 ? "disabled" : ""}
              to={match.pathname}
            >
              {breadcrumb}
            </Link>
            {index < breadcrumbs.length - 1 && " / "}
          </div>
        </span>
      ))}
    </React.Fragment>
  );
};
export default Breadcrumbs;

export default {
  User: {
    addUser: "/admin/usermanagement/users",
    searchByUsername: "/admin/usermanagement/users/search/",
    getUserDetails: "/admin/usermanagement/users/name/",
    userList: "/admin/usermanagement/users/list",
    buyerList: "/admin/usermanagement/users/po/list",
    resetPassword: "/admin/usermanagement/users/reset-password",
    getUserDetailsById: "/admin/usermanagement/users/id/",
  },
  LLUser: {
    addUser: "/admin/usermanagement/llAdmin/users",
    searchByUsername: "/admin/usermanagement/users/search/",
    getUserDetails: "/admin/usermanagement/users/name/",
    getUserDetailsByID: "/admin/usermanagement/users/id/",
    userList: "/admin/usermanagement/llAdmin/users/list",
    resetPassword: "/admin/usermanagement/llAdmin/users/reset-password",
    validateUsername: "/admin/usermanagement/users/validate/",
  },
  Roles: {
    getRoles: "/admin/accessmangement/roles/list",
  },
  DashboardNotifications: {
    getNotifications: "/common/notifications/notifyevent",
    changeApprovalNotification: "/common/notifications/changeapproval",
    getActiveUsers: "/admin/usermanagement/activeUsers",
    getNotificationsByUserList: "/common/notifications/notifyEventsByUsers",
    purgeNotifications: "/common/notifications/purgeNotifications",
  },

  DashboardNews: {
    getNews: "/admin/product/bulletin",
    addNews: "/admin/product/bulletin/create",
    updateNews: "/admin/product/bulletin/maintain",
    deleteNews: "/admin/product/bulletin/delete",
  },
  UserNotification: {
    getUserNotification: "/common/system-notifications/systemNotifications",
  },
  Company: {
    addCompany: "/admin/businessGroup/companies",
    modifyCompany: "/admin/businessGroup/companies",
    companyList: "/admin/businessGroup/companies/list",
    companyDetails: "/admin/businessGroup/companies/",
  },

  Location: {
    addLocation: "/admin/businessGroup/locations",
    modifyLocation: "/admin/businessGroup/locations",
    locationList: "/admin/businessGroup/locations/list",
    locationDetails: "/admin/businessGroup/locations/",
    locationDetailsWithShortName: "/admin/businessGroup/locations/shortName/",
    locationFunctionType: "/admin/businessGroup/locations/type",
    locationPoList: "/admin/businessGroup/locations/list/po",
  },
  Common: {
    commonDetails: "/admin/commondata",
    getPartnerType: "/common/trading-partner/getPartnerType",
    viewAttachment: "/common/attachments/download",
    auditInfo: "/common/audit/info",
    notifyevent: "/common/notifications/notifyevent",
    getAllColumnGrid: "/common/userPref/columnGrid",
    updateColumnGridById: "/common/userPref/columnGrid/gridId/",
    createGridConfig: "/common/userPref/columnGrid/save",
    getSearchInitState: "/common/searchGrid",
  },
  systemNotification: {
    getSystemNotifications: "/common/system-notifications/systemNotifications",
    saveSystemNotifications: "/common/system-notifications/systemNotifications",
  },
  Client: {
    addClient: "/admin/client",
    modifyClient: "/admin/client",
    clientList: "/admin/client/list",
    clientSearch: "/admin/client/names",
    generateClientId: "/admin/client/generate-clientid",
    clientBulkUpload: "/admin/client/clientBulkUpload",
    clientUploadSummary: "/admin/client/uploadsummary",
    uploadImage: "/admin/client/image",
    duplicateCheck: "/admin/client/name/{clientName}",
  },

  Lookups: {
    lookUpList: "/admin/lookup/data/list",
    lookUpAdd: "/admin/lookup/client/data",
    fetchSegments: "/admin/lookup/data/segment/list",
    addSegment: "/admin/lookup/client/segment/data",
    addClaim: "/admin/lookup/client/claim/data",
    getClaims: "/admin/lookup/data/claim/list",
    llAdminLookUpList: "/admin/lookup/lladmin/data/list",
    llAdminLookUpAdd: "/admin/lookup/lladmin/data",
    addLookUpCode: "/admin/lookup/lladmin/add/data",
  },
  TradingPartner: {
    partnerList: "/admin/tradingpartner/list/allByClientId",
    allVendorList: "/admin/tradingpartner/list/vendor/allVendors",

    addPartner: "/admin/tradingpartner/add",
    modifyPartner: "/admin/tradingpartner/update",
    getPartnerDetails: "/admin/tradingpartner/get",
    searchByPartnerName: "/admin/tradingpartner/search",
    partnerUpdate: "/admin/tradingpartner/update",
    getDetailsByPartnerID: "/admin/tradingpartner/get",
    getDetailsByPartnerName: "/admin/tradingpartner/search",
    addAttachment: "/admin/upload-attachments",
    validatePartner: "/admin/tradingpartner/validate",
    vendorInfoList: "/admin/tradingpartner/list/vendor/allByClientId",
    itemvendorInfo: "/admin/tradingpartner/vendor/iteminfo",
    exportPartnerList: "/common/export",
    tprBulkUpload: "/admin/tradingpartner/tpRelationshipUpload",
    tprUploadSummary: "/admin/tradingpartner/uploadsummary",
    getCarrierDetails: "/admin/tradingpartner/list/carrier/allByClientId",
  },
  ItemMaster: {
    itemCreateList: "/admin/itemmaster/itemcreate/items",
    itemCreateListByWarehouse: "/admin/itemmaster/itemcreate/{warehouse}/items",
    itemCreateListSearchItemCode:
      "/admin/itemmaster/itemcreate/items/search/itemcode",
    itemCreateListSearchWarehouseItemCode:
      "/admin/itemmaster/itemcreate/items/search/warehouse/itemcode",
    itemCreateAdd: "/admin/itemmaster/itemcreate/add",
    itemCreateUpdate: "/admin/itemmaster/itemcreate/updateitem",
    itemCreateUpload: "/admin/itemmaster/itemcreate/excel/upload",
    itemCreateUploadSummary: "/admin/itemmaster/itemcreate/uploadsummary",
    itemMapUpload: "/admin/itemmaster/itemmap/excel/upload",
    itemMapUploadSummary: "/admin/itemmaster/itemmap/uploadsummary",
    itemMapList: "/admin/itemmaster/itemmap/items",
    itemMapSubsUpload: "/admin/itemmaster/itemmap/excel/subs/upload",
    itemMapSubsUploadSummary: "/admin/itemmaster/itemmap/subs/uploadsummary",
    itemUnMappedList: "/admin/itemmaster/itemcreate/unmappeditems",
    addMapping: "/admin/itemmaster/itemmap/addorupdate",
    getItemCodeDetailsWithRCVId: "/admin/itemmaster/itemcreate",
    itemvendorpoList: "/admin/tradingpartner/vendor/itemvendorpo/map/iteminfo",
    itemvendorInfo: "/admin/tradingpartner/vendor/iteminfo",
  },
  ApplicationConfig: {
    systemOptionsAdd: "/admin/applicationConfiguration/systemOptions",
    getSystemOptions: "/admin/applicationConfiguration/systemOptions/list",
    addCalender: "/admin/applicationConfiguration/fiscalyear/items",
    addPeriods:
      "/admin/applicationConfiguration/fiscalyear/items/fiscalyearperiods",
    calenderList: "/admin/applicationConfiguration/fiscalyear/items/list",
    addPeriods:
      "/admin/applicationConfiguration/fiscalyear/items/fiscalyearperiods",
    getAutoLoadList: "/admin/applicationConfiguration/autoLoad/list",
    addAutoLoad: "/admin/applicationConfiguration/autoLoad",
    addTransportOptions: "/admin/applicationConfiguration/transportOptions",
    getTransportOptionsList:
      "/admin/applicationConfiguration/transportOptions/list",
    addDocuments: "/admin/applicationConfiguration/documents",
    getDocumentList: "/admin/applicationConfiguration/documents/list",
    getTerms: "/admin/applicationConfiguration/terms/list",
    getTerms: "/admin/applicationConfiguration/terms/list",
    postTerm: "/admin/applicationConfiguration/terms",
  },
  UserPreferences: {
    procurementAdd: "/admin/setup/user-preferences/procurement-defaults",
    procurementList: "/admin/setup/user-preferences/procurement-defaults/list",
    notificationAdd: "/admin/setup/user-preferences/notifications",
    notificationList: "/admin/setup/user-preferences/notifications/list",
  },
  ThirdPartyCharges: {
    getCharges: "/admin/tradingpartner/charges/types",
    addChargs: "/admin/tradingpartner/charges/types/addorupdate",
    getDefaultCharges: "/admin/tradingpartner/charges/default",
    addDefaultCharges: "/admin/tradingpartner/charges/default/addorupdate",
    getChargesInactive: "/admin/tradingpartner/charges/inactiveTypes",
    getChargesActive: "/admin/tradingpartner/charges/activeTypes",
    getItemCategories: "/admin/itemmaster/itemcreate/itemcategories",
    getDefaultChargesInactive: "/admin/tradingpartner/charges/inactivecharge",
    getDefaultChargesActive: "/admin/tradingpartner/charges/activecharge",
    bybupartnername: "/admin/tradingpartner/charges/bybupartnername",
    defautlChargeUpload: "/admin/tradingpartner/charges/upload",
    defautlChargeUploadFailure: "/admin/tradingpartner/charges/upload/failures",
    defautlChargeUploadSummary: "/admin/tradingpartner/charges/upload/summary",
    defautlChargeExport: "/common/export",
  },
  VendorPrice: {
    getVendorPriceList: "/admin/tradingpartner/vendor/prices",
    addVendor: "/admin/tradingpartner/vendor/price/addorupdate",
    vendorUpload: "/admin/tradingpartner/vendor/excel/priceupload",
    vendorUploadSummary: "/admin/tradingpartner/vendor/price/uploadsummary",
    vendorPriceExport: "/common/export",
  },
  CarrierRates: {
    getSegmentList: "/admin/tradingpartner/carrier/segments",
    addSegment: "/admin/tradingpartner/carrier/segments/addorupdate",
    inactiveSegments: "/admin/tradingpartner/carrier/inactivesegments",
    vendorLocations: "/admin/tradingpartner/carrier/vendorLocations",
    shippingList: "/admin/shippingSegment/groups/getAllShippingData",
    receivingList: "/admin/shippingSegment/groups/getAllRecevingData",
  },
  Purchasing: {
    getPoList: "/purchaseOrder/pos",
    createPO: "/purchaseOrder/pos",
    searchByVendor: "/purchaseOrder/pos/vendor/search",
    searchByCustomer: "/purchaseOrder/pos/customer/search",
    getTemplates: "/purchaseOrder/templates",
    addAttachment: "/common/attachments/upload",
    searchPO: "/purchaseOrder/pos/search",
    saveAsTemplate: "/purchaseOrder/templates",
    getTemplateDetails: "/purchaseOrder/templates/createpo",
    poUpload: "/purchaseOrder/uploads",
    uploadPO: "/purchaseOrder/uploads/latest",
    poUploadSummary: "/purchaseOrder/uploads/summary",
    poUploadFailure: "/purchaseOrder/uploads/failures",
    rejectPO: "/purchaseOrder/pos/rejectPO",
    cancelPO: "/purchaseOrder/pos/cancelPO",
    defaultHeadCharges: "/purchaseOrder/pos/defaultHeadCharges",
    defaultLineCharges: "/purchaseOrder/pos/defaultLineCharges",
    confirmByPo: "/purchaseOrder/pos/confirmPO",
    copyNewPo: "/purchaseOrder/pos/copy",
    searchShippingPO: "/purchaseOrder/pos/shipSearch",
    updatePoStatus: "/purchaseOrder/pos/updateStatus",
    searchShippingPO: "/purchaseOrder/pos/shipSearch",
    splitOnPO: "/purchaseOrder/pos/splitPO/splitOnPO",
    copyOrMove: "/purchaseOrder/pos/copyOrMove/moveFlag",
    fetchTargetPOsInfo:
      "/purchaseOrder/pos/copyOrMove/fetchTargetPOsInfo/poHeaderId",
    fetchTargetVendorPOsInfo:
      "/purchaseOrder/pos/splitPO/fetchTargetPOsInfo/poHeaderId",
    fecthPoReceiptInfo: "/purchaseOrder/receipts",
    searchPoByStatus: "/purchaseOrder/pos/pobystatus",
    export: "/common/export",
    printPDF: "/purchaseOrder/pos/print",
    getAllPoNumbers: "/purchaseOrder/pos/getAllPoNumbers",
    getAllShippingPoNumbers: "/purchaseOrder/pos/shipping/getAllPoNumbers",
    latestByBuyer: "/purchaseOrder/pos/latestByBuyer",
    latestByVendor: "/purchaseOrder/pos/latestByVendor",
    callLogisticsOnTiHiChanges: "/purchaseOrder/pos/callLogisticsOnTiHiChanges",
    itemNumberSearch: "/admin/tradingpartner/vendor/itemvendorpo/search",
    callLogisticsOnTiHiChangesOnItemMaster:
      "/purchaseOrder/pos/callLogisticsOnTiHiChangesOnItemMaster",
    defaultTemplateCharges: "/purchaseOrder/pos/defaultTemplateCharges",
    changeVendorPo: "/purchaseOrder/pos/changeVendor",
    fetchItemMasterByItemCode:
      "/admin/itemmaster/itemcreate/fetchItemMasterByItemCode",
    fetchVendorItemMasterByItemCode:
      "/admin/itemmaster/itemcreate/fetchVendorItemMasterByItemCode",
    fetchSubDetails: "/admin/itemmaster/itemmap/subs",
    fetchVendorItemPriceDetails:
      "/admin/tradingpartner/vendor/itemvendorpo/list/price",
    updateAutoFreightOnLoadLevelWhenCancelPO:
      "/logistics/loadManager/pricing/updateAutoFreightOnLoadLevelWhenCancelPO",
    deleteChargeIds: "/purchaseOrder/pos/deleteChargeIds",
    deleteTemplate: "/purchaseOrder/templates/delete",
    validateUpdatePOByLoadId: "/purchaseOrder/pos/validateUpdatePOByLoadId",
    demandPo: "/purchaseOrder/demand/addtopo",
  },
  Claims: {
    getCheckClaimNumber: "/claim/checkClaimNum",
    createClaim: "/claim/createClaim",
    getClaimByClaimNumber: "/claim/getClaim",
    getClaimsByUserName: "/claim/getClaims",
    getLoadDetailsByLoadNum: "/claim/getLoadDetails",
    getLoactionById: "/claim/getLocation",
    getPartnerById: "/claim/getPartner",
    getItemCodeById: "/claim/getItemCodeById",
    getPoByPONum: "/claim/getPo",
    auditLog: "/common/audit/info",
    poClaimSummaryList: "/claim/getPoClaimSummary",
    poDropdownList: "/claim/getEligiblePOs",
    claimApproverDropdownList: "/claim/getClaimApprover",
    updateClaimStatus: "/claim/updateClaimStatus",
    claimsExport: "/common/export",
    getCarrierDetails: "/admin/tradingpartner/list/getAllPartnerByClientId",
  },
  Billing: {
    getBillingVendorInvoice: "/billing/vendorinvoice/search",
    getPONumberList: "/billing/vendorinvoice/poNumbers",
    // getCustomerList: "/admin/client/customerNames",
    // getCustomerNamesList: "/admin/tradingpartner/list/allByClientId",
    getCustomerNamesList: "/common/trading-partner/getCustomers/",
    locationDetails: "/admin/businessGroup/locations/",
    getPartnerDetails: "/common/trading-partner/getCustomer/",
    getPODetails: "/purchaseOrder/pos/byPoNumber/",
    getPoByPONum: "/billing/vendorinvoice",
    createorupdate: "/billing/vendorinvoice/createorupdate",
    getTerm: "/admin/applicationConfiguration/terms/list",
    locationList: "/admin/businessGroup/locations/remitToList",
    getVendorInvoiceNum: "/billing/vendorinvoice",
    getCostModifiers: "/admin/tradingpartner/charges/activeTypes",
    printPDF: "/billing/vendorinvoice/print",
  },
  BillingCarrier: {
    getBillingCarrierInvoice: "/billing/carrierinvoice/search",
    getCustomerNamesList: "/common/trading-partner/getCustomers/",
    getCarrierNamesList: "/common/trading-partner/getCustomers/",
    getLoadList: "/billing/carrierinvoice/loadNumbers",
    getLoadByLoadNum: "/billing/carrierinvoice",
    //locationList: "/admin/businessGroup/locations/list",
    locationList: "/admin/businessGroup/locations/remitToList",
    getTerm: "/admin/applicationConfiguration/terms/list",
    createorupdate: "/billing/carrierinvoice/createorupdate",
    getVendorInvoiceNum: "/billing/carrierinvoice",
    printPDF: "/billing/carrierinvoice/print",
  },
  Logistics: {
    getLoadList: "/logistics/loadManager/getSearchLoads",
    getLoadDataById: "/logistics/loadManager/getLoadById/",
    getLoadDataByNumber: "/logistics/loadManager/getLoadByLoadNumber/",
    addNotesToLoad: "/logistics/loadManager/addNotesByLoadId/",
    getCarrierAndSegments: "/logistics/loadManager/getCarrierAndSegments",
    getCarrierAndTransportSegmentsByPOIds:
      "/logistics/loadManager/getCarrierAndTranportSegmentsByPOIds",
    fetchLoadTypesLOVs: "/logistics/loadManager/fetchLoadTypesLOVs",
    addLoad: "/logistics/loadManager/pricing/addLoad",
    updateLoad: "/logistics/loadManager/updateLoad",
    getNotesByLoadId: "/logistics/loadManager/fetchNotesByLoadId/",
    getCustomerLoads: "/logistics/loadManager/getSearchCustomerLoads",
    getItemCodeDetailsItemId:
      "/admin/itemmaster/itemcreate/fetchItemMasterByItemCode/",
    getItemCodeDetailsItemIdInWhse:
      "/admin/itemmaster/itemcreate/fetchItemMasterByItemCode/warehouse",
    loadAutoFreight: "/logistics/loadManager/autoFreight",
    loadUpdateCarrier: "/logistics/carrier/updateLoad",

    getCustomersLoadSummary:
      "/admin/tradingpartner/list/customer/allByClientId",
    getLoadsByCustomerId: "/logistics/carrier/getLoadNumbersByCusotmerId/",
    removePOOnLoad: "/logistics/loadManager/pricing/removePOOnLoad",
    removePOsOnLoad: "/logistics/loadManager/pricing/removePOsOnLoad",

    autoFreightOnPO:
      "/logistics/loadManager/pricing/updateAutoFreightOnPoLevel",
    autoFreightOnLoad:
      "/logistics/loadManager/pricing/updateAutoFreightOnLoadLevel",
    addPOToLoad: "/logistics/loadManager/pricing/addPOToExistingLoad",
    loadDetailsForAddToLoad:
      "/logistics/loadManager/fetchLoadDetailsForAddToLoad/",
    getAuditLogsByLoadId: "/logistics/loadManager/auditInfoByLoadId/",
    getLoadlocationDetails: "/admin/businessGroup/locations/",
    getLoadTradingPartnerDetails: "admin/tradingpartner/getCarrierDetails/",
    getPOTradingPartnerDetails: "admin/tradingpartner/get/",
    changeLoadStatus: "logistics/loadManager/changeLoadStatus/",
    segmentDetatilsOnSegmentId:
      "logistics/loadManager/segmentDetatilsOnSegmentId",
    getLoadsOnSearch: "logistics/loadManager/searchByLoadNumber/",
    searchByLoadNumberForAdd: "logistics/loadManager/searchByLoadNumberForAdd/",
    loadStatusUpdate: "logistics/loadManager/changeLoadStatus/",
    loadStatusConfirmUpdate: "logistics/loadManager/confirmDatesChangesOnLoad/",
    updateDatesOnPoLoad: "logistics/loadManager/updateDatesOnPoLoad",
    updateDatesOnPoLoads: "logistics/loadManager/updateDatesOnPoLoads",
    updateShipDatesOnPoLoads: "logistics/loadManager/updateShipDatesOnPoLoads",
    updateCarrierArrivalDatesOnPoLoad: "logistics/carrier/updateDatesOnLoad",
    getAllPONumbers: "/purchaseOrder/pos/logistics/getAllPoNumbers",
    fetchLoadDetailsByCarrier:
      "logistics/loadManager/fetchLoadDetailsForAddToLoadByCarrier/",
    fetchLoadDetailsByWH:
      "logistics/loadManager/fetchLoadDetailsForAddToLoadByRcvWareHouse/",
    fetchVendorItemMasterByItemCode:
      "/admin/itemmaster/itemcreate/fetchVendorItemMasterByItemCode",
    fetchLoadNotificationData:
      "/common/notifications/activeToDoNotifications/Transportation/",
    confirmLoad:
      "/logistics/loadManager/confirmDatesChangesOnLoadWithNotifications",
    confirmLoadCarrier:
      "/logistics/loadManager/changeLoadStatusWithNotification",
    validateRecalPO: "/logistics/loadManager/pricing/validateRecalcOnPO/",
    logisticsExport: "/common/export",
    generatePDFByLoadId: "logistics/loadManager/generatePDFByLoadId/",
    getAllPONumbersCarrier:
      "/purchaseOrder/pos/logisticsForCarrier/getAllPoNumbers",
    addAndRemovePOFromLoad:
      "/logistics/loadManager/pricing/addAndRemovePOFromLoad",
    getCustDetails: "admin/tradingpartner/getCustomerDetails/",
    getBuyerCustDetails: "admin/client/",
  },
  Demand: {
    getDemandList: "/purchaseOrder/demand/list",
    createDemand: "/purchaseOrder/demand/create",
    updateDemand: "/purchaseOrder/demand/update",
    updateDemandStatus: "/purchaseOrder/demand/update/cancel",
    updateDemandLineDetail: "/purchaseOrder/demand/update/linedetail",
    getTemplateList: "/purchaseOrder/demand/template/list",
    createTempalte: "/purchaseOrder/demand/template/create",
    updateTemplate: "/purchaseOrder/demand/template/update",
    getDemandDetails: "/purchaseOrder/demand/items/{id}/details/",
    getDemandFromTemplate: "/purchaseOrder/demand/template/items/{id}",
    getDemandDetailsFromExisting: "/purchaseOrder/demand/items/{id}",
    getDemandDetailsFromExistingMultiple:
      "/purchaseOrder/demand/items/createFromDemand",
    searchDemand: "/purchaseOrder/demand/search",
    searchList: "/purchaseOrder/demand/search/list",
    createPODemand: "/purchaseOrder/demand/po",
    searchForPoList: "/purchaseOrder/demand/search/forpo/list",
    uploadDemand: "/purchaseOrder/demand/upload",
    uploadDemandSummary: "/purchaseOrder/demand/upload/summary",
    downloadErrorSummary: "/purchaseOrder/demand/upload/{id}/failed",
    createDemandFromTemplate: "/purchaseOrder/demand/create/{copies}",
    templateUpdate: "/purchaseOrder/demand/template/update",
    templateDelete: "/purchaseOrder/demand/template/delete",
    poDetailsList: "/purchaseOrder/pos/pobyshipto/",
  },
  Shipping: {
    getAllGroups: "/admin/shippingSegment/groups/getAllShippingData",
    createZone: "/admin/shippingSegment/zones",
    addZipCode: "admin/shippingSegment/zips",
    updateZipCode: "admin/shippingSegment/zips",
    addGroup: "admin/shippingSegment/groups",
    addOrUpdateZoneMap: "admin/shippingSegment/groups",
    deleteZone: "/admin/shippingSegment/zones",
    deleteZip: "/admin/shippingSegment/zips",
    updateZoneName: "/admin/shippingSegment/zones",
    deleteGroup: "/admin/shippingSegment/groups",
  },
  BoldBI: {
    getBoldBIToken: "/common/boldReports/tokenForUser",
  },
};

import { SET_BILLING_SEARCH } from "../constants";

const initialState = {
  billingSearch: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BILLING_SEARCH:
      return { ...state, billingSearch: payload };
    default:
      return state;
  }
};

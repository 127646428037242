import React, { useState } from "react";
import SideBar from "./sideBar";
import TopBanner from "./topBanner";
import "./layout.css";
import Breadcrumbs from "./breadcrumb";
import { useSelector } from "react-redux";
import { loginDetailsSelector } from "../store/selectors/app.selector";

import backArrowCircle from "../assets/icons/navbar/backArrowCircle.svg";
import Logo from "../assets/icons/CSLogo.svg";

import { IoChevronBackCircleSharp, IoChevronForwardCircle } from "react-icons/io5";

import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
  LeftCircleFilled,
  RightCircleFilled
} from "@ant-design/icons";

export default function Layout({ children }) {
  const userDetails = useSelector(loginDetailsSelector);
  const [menuVisible, setMenuVisible] = useState(true);

  const toggleOpen = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="main-container">
      <div className="d-flex flex-column">
        <span className="d-flex logoImage">
          <img
            src={Logo}
            width={60}
            height={56}
            style={{ display: "block", margin: "0 auto", cursor: "pointer" }}
          />
        </span>

        <div className="d-flex arrow">
          {menuVisible ? 
            <IoChevronBackCircleSharp style={{ margin: "0 auto", marginBottom: 5, fontSize: 26, color: "#737ae6" }} onClick={toggleOpen}/> :
            <IoChevronForwardCircle style={{ margin: "0 auto", marginBottom: 5, fontSize: 26, color: "#737ae6" }} onClick={toggleOpen}/>
          }
        </div>
      </div>

      <div className="top-banner">
        <TopBanner />
      </div>
      <div className="render-wrapper">
        <div className={`left-section ${menuVisible ? "menu-expand" : "menu-collapsed"}`}>
          <SideBar />
        </div>
        <div className="breadcrumb-section">
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div>
              <Breadcrumbs />
            </div>
            {userDetails?.clientId && (
              <span style={{ marginRight: 40 }}>
                Client ID :{" "}
                <b>
                  {userDetails?.clientId}/{userDetails?.clientType}
                </b>
              </span>
            )}
          </div>
        </div>

        <div className={`right-wrapper ${menuVisible ? "right-wrapper-expand" : "right-wrapper-collapse"}`}>{children}</div>
      </div>
    </div>
  );
}

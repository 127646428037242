import React from "react";

export default function AccessDenied() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "36px",
          }}
        >
          Access Denied
        </div>
        <div
          style={{
            fontSize: "18px",
          }}
        >
          You dont have permission to view this page.
        </div>
      </div>
    </>
  );
}

export const environment = {
  react_app_url: window.react_app_url,
  react_app_client_context: window.react_app_client_context,
  keycloak_realm: window.keycloak_realm,
  keycloak_auth_server_url: window.keycloak_auth_server_url,
  keycloak_clientId: window.keycloak_clientId,
  react_app_service: window.react_app_service,
  react_app_page: window.react_app_page,
  react_app_pagesize: window.react_app_pagesize,
  petal_menu_limited: window.petal_menu_limited,
}


const DEV = {
  SERVICE_URL: "https://dev0-everest.limnlabs.com/api/everest/v1",
  
};

const PROD = {
  SERVICE_URL: "https://dev0-everest.limnlabs.com/api/everest/v1",
};


const config = process.env.REACT_APP_STAGE === "PROD" ? PROD : DEV;

export default { ...config };

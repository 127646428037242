import React from "react";
import NumberFormat from "react-number-format";

export default function PoCostSummary({
  totalCostCharge = 0,
  totalItemCost = 0,
  totalCost = 0,
  costSummaryDetails = {},
}) {
  return (
    <div className="po-cost-summary">
      <h6 className="secondary-title" style={{ marginBottom: 20 }}>
        PO Summary
      </h6>
      <div className="row">
        <div className="col-12">
          <div className="item-section">
            <label>Total Items List Cost</label>
            <NumberFormat
              value={costSummaryDetails?.totalItemCost || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>

          <div className="item-section">
            <label>Total 3rd Party Charges</label>
            <NumberFormat
              value={costSummaryDetails?.total3rdCharge || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>
        </div>
        <div className="col-9">
          <div className="item-section">
            <label>Internal</label>
            <NumberFormat
              value={costSummaryDetails?.totalInternal || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>

          <div className="item-section">
            <label>External</label>
            <NumberFormat
              value={costSummaryDetails?.totalExternal || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>
        </div>

        <div className="col-12">
          <div className="item-section">
            <label>Total Freight</label>
            <NumberFormat
              value={costSummaryDetails?.totalFreight || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>

          <div
            className="item-section"
            style={{
              border: "1px solid #1c1c1c",
              padding: "8px 0",
              borderLeft: "none",
              borderRight: "none",
            }}
          >
            <label>Total Cost</label>
            <NumberFormat
              value={totalCost || 0}
              displayType={"text"}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
              allowEmptyFormatting
            />
          </div>
        </div>
      </div>
    </div>
  );
}

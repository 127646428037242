import Keycloak from "keycloak-js";
import { store } from "../store";
import { SET_AUTH_TOKEN } from "../store/constants";
import { environment } from "../environment.js";
import { message, notification } from "antd";

//const _kc = new Keycloak("/keycloak.json");

let keycloakJSON = {
  realm: environment.keycloak_realm,
  url: environment.keycloak_auth_server_url,
  "ssl-required": "external",
  clientId: environment.keycloak_clientId,
  "public-client": true,
  "confidential-port": 443,
  "enable-cors": true,
  "disable-trust-manager": true,
};
const _kc = new Keycloak(keycloakJSON);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeyCloak = (onAuthenticatedCallback) => {
  try {
    _kc
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        console.log(_kc);
        console.log("kv");
        let clientId = _kc.idTokenParsed.client_id;
        console.log("clientId==>" + clientId);
        store.dispatch({
          type: SET_AUTH_TOKEN,
          payload: _kc?.token || null,
        });

        if (!authenticated) {
          notification.error({
            message: "Error",
            description: "Authentication fails. Please contact admin or call @833-5466-522.",
            placement: "top",
          });
          console.log("user is not authenticated..!");
        }
        onAuthenticatedCallback();
      });
  } catch (err) {}
};

const doLogout = () => {
  try {
    var logoutOptions = { redirectUri: window.location.origin };
    return _kc.logout(logoutOptions);
  } catch (err) {}
};

const getToken = () => {
  try {
    return _kc.token;
  } catch (err) {}
};

const getClientId = () => {
  try {
    return _kc.idTokenParsed.client_id;
  } catch (err) {}
};
const isLoggedIn = () => {
  try {
    return !!_kc.token;
  } catch (err) {}
};

const updateToken = (successCallback) => {
  try {
    _kc.updateToken(5).then(successCallback).catch(_kc.login());
  } catch (err) {}
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const isLlAdmin = () => {
  const userName = getUsername();
  return userName === "dev" || userName === "esuper";
};

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeyCloak,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getClientId,
  isLlAdmin,
};

export default UserService;

import React, { useCallback, useEffect, useState, useRef } from "react";
import "./style/load-manager.css";
import "./style/load-details.css";
import { Form, message, Button, AutoComplete, Popover, Tooltip } from "antd";
import CommonService from "../../../services/common.service";
import { Modal } from "antd";
import notesGridImg from "../../../assets/icons/grid/notesGrid.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import NumberFormat from "react-number-format";
import ItemDetailsModal from "./item-Details-table-modal";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Inject,
  Page,
  DetailRow,
  Grid,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import LoadMangerService from "../../../services/loadManager.service";
import AutoFreightOnPO from "./autofreightOnPO-modal";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import AutoFreightOnLoad from "./autofreightOnLoad-modal";
import PartnerDetails from "../../../pages/purchasing/components/partnerDetails";
import ShipDetails from "../../../components/location/locationDetails";
import ItemNumberDetails from "../../purchasing/components/itemDetails";
import notesColorIcon from "../../../assets/icons/common/notesColor.svg";
import notesColorYellowIcon from "../../../assets/icons/common/notesColorYellow.svg";
import notesIcon from "../../../assets/icons/common/notes.svg";
import notesYellowIcon from "../../../assets/icons/common/notesYellow.svg";
import UserManagementService from "../../../services/userManagement.service";
import UserDetails from "../../purchasing/components/userDetails";

let headerNotes = [];

export default function LoadDetailsFormView(props) {
  const params = useParams();
  let gridInstance = useRef(null);
  const editSettings = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: false,
    newRowPosition: "Top",
  };
  const [auditlogModalVisible, setAuditlogVisibleModal] = useState(false);
  const [loadViewInfo, setLoadViewInfo] = useState([]);
  const [selectedRowNotesDataNotes, setSelectedRowNotesDataNotes] = useState(
    []
  );
  const [loader, setLoader] = useState(false);
  const [loaderimage, setLoaderimage] = useState(false);
  const [idNotes, setIdNotes] = useState();
  const [loadStatusNotes, setLoadStatusNotes] = useState();
  const [itemDetailsModalVisible, setItemDetailsVisibleModal] = useState(false);
  const [itemDetails, setItemDetails] = useState();
  const [newLoadModalVisible, setNewLoadVisibleModal] = useState(false);
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState([]);
  const [auditInfo, setAuditInfo] = useState([]);
  const [removeModalVisible, setRemoveVisibleModal] = useState(false);
  const [autoFreightOnLoadVisible, setAutoFreightOnLoadVisible] =
    useState(false);
  const [
    autoFreightOnLoadConfirmationModal,
    setAutoFreightOnLoadConfirmationModal,
  ] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedLoadDetails, setSelectedLoadDetails] = useState({});
  const [visibleLoadLocationDetails, setVisibleLoadLocationDetails] =
    useState(false);
  const [visibleLoadCarrierVendorDetails, setVisibleLoadCarrierVendorDetails] =
    useState(false);
  const [visibleItemDetails, setVisibleItemDetails] = useState(false);
  const { Option } = AutoComplete;
  const [selectedDetails, setSelectedDetails] = useState({});
  const [groupedReceivingTbl, setGroupedReceivingTbl] = useState([]);
  const [groupedShippingTbl, setGroupedShippingTbl] = useState([]);
  const [claimNumbers, setClaimNumbers] = useState([]);
  const navigate = useNavigate();
  const [shippingMappingList, setShippingMapList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [visibleUserDetails, setVisibleUserDetails] = useState(false);

  function handleAutoFreightOnLoadConfirmationModal() {
    setAutoFreightOnLoadConfirmationModal(false);
    setAutoFreightOnLoadVisible(true);
  }
  function closeAutoFreightOnLoadConfirmationModal() {
    setAutoFreightOnLoadConfirmationModal(false);
  }

  function handleRemovePOOnLoad() {
    setRemoveVisibleModal(false);
    setNewLoadVisibleModal(true);
  }
  function RemoveModalClose() {
    setRemoveVisibleModal(false);
  }
  function itemDetailsModalClose() {
    setItemDetailsVisibleModal(false);
  }
  function auditlogModalClick() {
    setAuditlogVisibleModal(true);
    handleGetAuditDetails();
  }
  function auditlogModalClose() {
    setAuditlogVisibleModal(false);
  }
  const location = useLocation();
  let toFixed;
  toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
  useEffect(() => {
    handleGetLoadData();
  }, []);
  const handleGetLoadData = useCallback(async () => {
    try {
      setLoaderimage(true);
      const response = await LoadMangerService.getLoadDataById(params["id"]);
      if (response?.status === 200) {
        let totalValueMapList = [];
        let totalCount = 0;

        response.data.totalValueMap = response?.data?.totalValueMap || {};
        response.data.seqShipFromPos = response?.data?.seqShipFromPos || {};
        if (Object.keys(response?.data?.totalValueMap).length > 0) {
          Object.keys(response?.data?.totalValueMap).forEach(
            (shortName, idx) => {
              // response.data.seqShipFromPos[shortName]?.forEach(
              //   (element, index) => {
              //     totalValueMapList.push({
              //       ...response.data.totalValueMap[shortName],
              //       ...element,
              //       seqId: index === 0 ? idx + 1 : "",
              //     });
              //   }
              // );
              totalValueMapList.push({
                ...response.data.totalValueMap[shortName],
                shipFrom: {
                  locationId:
                    response.data.totalValueMap[shortName]?.locationId,
                  shortName: shortName,
                },
                poList: [...(response.data.seqShipFromPos[shortName] || [])],
                seqId: idx + 1,
              });

              [...(response.data.seqShipFromPos[shortName] || [])].forEach(
                (ele) => {
                  totalCount = totalCount + ele.poLines.length;
                }
              );
            }
          );
        }
        setShippingMapList(totalValueMapList);
        setTotalCount(totalCount);
        setLoadViewInfo(response?.data);
        handleGroupedRecev(response?.data?.purchaseOrders);
        handleGroupedShipFrom(response?.data?.purchaseOrders);
        setClaimNumbers(
          (response?.data?.loadDetailsDto?.cliamNumber &&
            response?.data?.loadDetailsDto?.cliamNumber?.split(",")) ||
            []
        );
        if (response?.data) {
          setTimeout(() => {
            gridInstance?.detailExpandAll();
          }, 100);
        }
        setLoaderimage(false);
      }
    } catch (err) {
      message.error(err);
      setLoaderimage(false);
    }
  });
  const handleGroupedRecev = useCallback((info) => {
    const groupItems = info?.reduce((element, obj) => {
      element[obj?.shipTo?.shortName] = element[obj?.shipTo?.shortName] || [];
      element[obj?.shipTo?.shortName]?.push(obj);
      return element;
    }, Object.create(null));
    var updatedData = [];
    Object.keys(groupItems)?.forEach((element, idx) => {
      if (groupItems[element]?.length > 0) {
        var items = [
          { ...groupItems[element][0], seqId: idx + 1 },
          ...groupItems[element]?.slice(1, groupItems[element]?.length),
        ];
        updatedData?.push(...items);
      }
    });
    setGroupedReceivingTbl(updatedData);
  }, []);
  const handleGroupedShipFrom = useCallback((info) => {
    const groupItems = info?.reduce((element, obj) => {
      element[obj?.shipFrom?.shortName] =
        element[obj?.shipFrom?.shortName] || [];
      element[obj?.shipFrom?.shortName]?.push(obj);
      return element;
    }, Object.create(null));
    var updatedData = [];
    Object.keys(groupItems)?.forEach((element, idx) => {
      if (groupItems[element]?.length > 0) {
        var items = [
          { ...groupItems[element][0], seqId: idx + 1 },
          ...groupItems[element]?.slice(1, groupItems[element]?.length),
        ];
        updatedData?.push(...items);
      }
    });
    setGroupedShippingTbl(updatedData);
  }, []);
  useEffect(() => {
    setAttachments(loadViewInfo?.loadDetailsDto?.attachments);
  }, [loadViewInfo]);

  const autoFreightOnLoadClose = () => {
    setAutoFreightOnLoadVisible(false);
    getLoadDetailsbyId(loadViewInfo?.loadDetailsDto?.loadId);
  };

  function newLoadModalClose() {
    setNewLoadVisibleModal(false);
    getLoadDetailsbyId(loadViewInfo?.loadDetailsDto?.loadId);
  }
  const [notesVisible, setNotesVisible] = useState(false);
  function notesModalChange() {
    setNotesVisible(true);
    handleViewNotes(loadViewInfo);
    setIdNotes(
      loadViewInfo?.loadDetailsDto
        ? loadViewInfo?.loadDetailsDto?.loadId
        : loadViewInfo?.id
    );
    setLoadStatusNotes(loadViewInfo?.loadDetailsDto?.loadStatus);
  }
  const handleViewNotes = useCallback(async (args) => {
    try {
      setLoader(true);
      const response = await LoadMangerService.getNotesByLoadId(
        args?.loadDetailsDto?.loadId
      );
      if (response?.status === 200) {
        response?.data?.forEach((element) => {
          element.showMore = false;
        });
        headerNotes = response?.data || [];
        setSelectedRowNotesDataNotes(response?.data);
        setLoader(false);
      }
    } catch (err) {
      message.error(err);
      setLoader(false);
    }
  });
  function notesModalChangePO(e, args) {
    setSelectedRowNotesDataNotes(args?.notes);
    setIdNotes(
      loadViewInfo?.loadDetailsDto ? loadViewInfo?.loadDetailsDto?.loadId : 0
    );
    setLoadStatusNotes(loadViewInfo?.loadDetailsDto?.loadStatus);
    setNotesVisible(true);
  }
  function notesModalClose() {
    setNotesVisible(false);
  }
  function handleViewPOInfo(e, args) {
    {
      window.open(`/purchasing/buyer/view/${args?.id}`);
    }
  }
  const loadPOClickStyle = (args) => {
    return (
      <label>
        <button
          onClick={(e) => handleViewPOInfo(e, args)}
          className="loadManagerTablelabelButton"
        >
          {args?.poNumber}
        </button>
        <button
          className="loadManagerTableNotesButton"
          onClick={(e) => notesModalChangePO(e, args)}
        >
          <img
            src={args?.notes?.length > 0 ? notesYellowIcon : notesIcon}
            style={{ width: "15px", height: "15px" }}
          />
        </button>
      </label>
    );
  };
  const loadPONumReceiving = (args) => {
    return (
      <label>
        <button
          onClick={(e) => handleViewPOInfo(e, args)}
          className="loadManagerTablelabelButton"
        >
          {args?.poNumber}
        </button>
      </label>
    );
  };

  const loadCustNameReceiving = (args) => {
    return (
      <label>
        <button
          onClick={(e) =>
            handleGetLoadCarrAndVendorDetails(e, args?.customerNumber)
          }
          className="loadManagerTablelabelButton"
          title={args?.customerName}
        >
          {args?.customerName}
        </button>
      </label>
    );
  };
  const shipClickStyle = (args) => {
    return <span>{moment(args?.shipDate).format("MM/DD/YYYY")}</span>;
  };

  const claimNumberTemplate = (
    <div>
      {claimNumbers.length > 0 &&
        claimNumbers.map((option, idx) => (
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            className="custom-value-style"
            onClick={() => {
              navigate(
                `/receiving/create-claim/view/claimView/${option?.trim()}`
              );
            }}
            key={idx}
          >
            {option}
          </span>
        ))}
    </div>
  );

  const freightClickStyle = (args) => {
    return (
      <>
        <span>
          <NumberFormat
            value={
              Math.floor(args?.loadPricingDto?.freightCost)
                ? args?.loadPricingDto?.freightCost + ".00"
                : toFixed(args?.loadPricingDto?.freightCost, 2)
            }
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };
  function handleViewItemDetails(e, itemId) {
    getItemCodeDetails(itemId);
    setItemDetailsVisibleModal(true);
    setItemDetails();
  }
  const getItemCodeDetails = useCallback(async (id) => {
    try {
      const response = await LoadMangerService.getItemCodeDetailsItemId(id);
      if (response?.status === 200) {
        setItemDetails(response?.data);
      }
    } catch (err) {
      message.error(err);
    }
  });

  const template = useCallback((props) => {
    return (
      <div id="poLinesCarrierItems">
        <GridComponent dataSource={props?.poLines} gridLines="Both">
          <ColumnsDirective>
            <ColumnDirective width="30" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="100" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="130" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="95" textAlign="Left"></ColumnDirective>
            <ColumnDirective
              field="receiverSerialNum"
              headerText="RCDR S/NO"
              width="90"
              textAlign="Left"
            ></ColumnDirective>
            <ColumnDirective
              field="itemNumber"
              headerText="ITEM CODE"
              width="85"
              textAlign="Left"
              template={poLinesItemNum}
            ></ColumnDirective>
            <ColumnDirective
              field="vendorItemNumber"
              headerText="VEN X CODE"
              width="95"
              allowEditing={true}
              textAlign="Left"
              template={poLinesItemNumByVendor}
            ></ColumnDirective>
            <ColumnDirective
              field="itemDescription"
              headerText="ITEM DESC"
              width="90"
              allowEditing={true}
              textAlign="Left"
            ></ColumnDirective>
            <ColumnDirective
              field="landedCost"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesLandedCost}
            ></ColumnDirective>
            <ColumnDirective
              field="cost"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesListCost}
            ></ColumnDirective>
            <ColumnDirective
              field="freightChargeAmount"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesFright}
            ></ColumnDirective>
            <ColumnDirective
              field="quantity"
              headerText=""
              width="85"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="poQunatity"
              width="60"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="qtyShipped"
              width="60"
              textAlign="Center"
              template={poLinesShippedQty}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="rcvdQty"
              width="60"
              textAlign="Center"
              allowEditing={true}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="cubes"
              width="65"
              textAlign="Right"
              allowEditing={true}
              template={poLinesCubes}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="pallets"
              width="60"
              textAlign="Right"
              allowEditing={true}
              template={poLinesPallets}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="weight"
              width="80"
              textAlign="Right"
              allowEditing={true}
              template={shippingWeightTemplatePOLines}
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Edit, Page, DetailRow]} />
        </GridComponent>
      </div>
    );
  });

  const POtemplate = useCallback((props) => {
    return (
      <div id="poLinesCarrier">
        <GridComponent
          dataSource={props?.poList}
          detailTemplate={template}
          detailDataBound={detailDataBoundItems}
          gridLines="Both"
          className="poListTable"
        >
          <ColumnsDirective>
            <ColumnDirective width="30" textAlign="Left"></ColumnDirective>

            <ColumnDirective width="100" textAlign="Left"></ColumnDirective>
            <ColumnDirective
              field="poNumber"
              headerText="PO Number"
              width="130"
              textAlign="Left"
              template={loadPOClickStyle}
            ></ColumnDirective>
            <ColumnDirective
              field="status"
              headerText="Status"
              width="95"
              textAlign="Left"
            ></ColumnDirective>
            <ColumnDirective
              field="vendorName"
              headerText="Vendor"
              width="90"
              textAlign="Left"
              template={loadViewCarrierorVendorName}
            ></ColumnDirective>
            <ColumnDirective
              field="shipDate"
              headerText="SHIP"
              width="85"
              allowEditing={true}
              textAlign="Left"
              template={shipClickStyle}
            ></ColumnDirective>
            <ColumnDirective
              field="arrivalDate"
              headerText="ARRIVAL"
              width="95"
              textAlign="Left"
              template={arrivalDatePOTemplate}
            ></ColumnDirective>

            <ColumnDirective
              field="pickupNum"
              headerText="PICKUP #"
              width="90"
              allowEditing={true}
              textAlign="Right"
            ></ColumnDirective>

            <ColumnDirective width="85" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="85" textAlign="Left"></ColumnDirective>
            <ColumnDirective
              field="totalFreight1"
              headerText=""
              width="85"
              textAlign="Right"
            ></ColumnDirective>
            <ColumnDirective width="85" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="60" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="60" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="60" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="65" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="60" textAlign="Left"></ColumnDirective>
            <ColumnDirective width="80" textAlign="Left"></ColumnDirective>

            {/* <ColumnDirective
              field="landedCost"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesLandedCost}
            ></ColumnDirective>
            <ColumnDirective
              field="cost"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesListCost}
            ></ColumnDirective> */}
            {/* <ColumnDirective
              field="carrierChargeAmount"
              headerText=""
              width="85"
              textAlign="Right"
              template={poLinesFright}
            ></ColumnDirective>
            <ColumnDirective
              field="quantity"
              headerText=""
              width="85"
              textAlign="Center"
            ></ColumnDirective> */}
            {/* <ColumnDirective
              headerText=""
              field="poQunatity"
              width="60"
              textAlign="Center"
            ></ColumnDirective> */}
            {/* <ColumnDirective
              headerText=""
              field="qtyShipped"
              width="60"
              textAlign="Center"
              template={poLinesShippedQty}
            ></ColumnDirective> */}
            {/* <ColumnDirective
              headerText=""
              field="rcvdQty"
              width="60"
              textAlign="Center"
              allowEditing={true}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="cubes"
              width="65"
              textAlign="Right"
              allowEditing={true}
              template={poLinesCubes}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="pallets"
              width="60"
              textAlign="Right"
              allowEditing={true}
              template={poLinesPallets}
            ></ColumnDirective>
            <ColumnDirective
              headerText=""
              field="weight"
              width="80"
              textAlign="Right"
              allowEditing={true}
              template={shippingWeightTemplatePOLines}
            ></ColumnDirective> */}
          </ColumnsDirective>
          <Inject services={[Edit, Page, DetailRow]} />
        </GridComponent>
      </div>
    );
  });

  function detailDataBound(e) {
    let detail = new Grid({
      dataSource: e?.data?.poList,
    });
    detail?.appendTo(e.detailElement.querySelector(".custom-grid"));
  }

  function detailDataBoundItems(e) {
    let detail1 = new Grid({
      dataSource: e?.data?.poLines,
    });
    detail1?.appendTo(e.detailElement.querySelector(".custom-grid"));
  }
  const poLinesItemNum = useCallback((args) => {
    return (
      <>
        <span
          className="itemCodeCls"
          onClick={(e) => handleViewItemDetails(e, args?.itemNumber)}
        >
          {args?.itemNumber}
        </span>
      </>
    );
  });
  const poLinesItemNumByVendor = (args) => {
    return (
      <>
        <span
          className="itemCodeClsVendor"
          onClick={(e) => handleViewItemDetailsByVendor(e, args)}
        >
          {args?.vendorItemNumber}
        </span>
      </>
    );
  };
  function handleViewItemDetailsByVendor(e, info) {
    getItemCodeDetailsByVendor(info);
    setVisibleItemDetails(true);
    setItemDetails();
  }
  const getItemCodeDetailsByVendor = useCallback(async (info) => {
    try {
      const response = await LoadMangerService.fetchVendorItemMasterByItemCode({
        itemCode: info?.vendorItemNumber,
        clientId: info?.targetClientId,
      });
      if (response?.status === 200) {
        setItemDetails(response?.data);
      }
    } catch (err) {
      message.error(err);
    }
  });
  const poLinesPallets = (args) => {
    return (
      <>
        <span title={args?.pallets ? args?.pallets : 0}>
          <NumberFormat
            value={args?.pallets ? args?.pallets : 0}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />{" "}
        </span>
      </>
    );
  };

  const poLinesLandedCost = (args) => {
    return (
      <>
        <span title={args?.landedCost ? args?.landedCost : 0}>
          <NumberFormat
            value={args?.landedCost ? args?.landedCost : 0}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />{" "}
        </span>
      </>
    );
  };
  const poLinesListCost = (args) => {
    return (
      <>
        <span title={args?.cost ? args?.cost : 0}>
          <NumberFormat
            value={args?.cost ? args?.cost : 0}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />{" "}
        </span>
      </>
    );
  };
  const poLinesCubes = (args) => {
    return (
      <>
        <span title={args?.cubes ? args?.cubes : 0}>
          <NumberFormat
            value={args?.cubes ? args?.cubes : 0}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />{" "}
        </span>
      </>
    );
  };
  const poLinesShippedQty = (args) => {
    return (
      <>
        {args?.qtyShipped > 0 ? (
          <b>{args?.qtyShipped}</b>
        ) : (
          <span>{args?.qtyShipped}</span>
        )}
      </>
    );
  };
  const poLinesFright = (args) => {
    return (
      <>
        <span title={args?.carrierChargeAmount}>
          <NumberFormat
            value={args?.carrierChargeAmount}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };

  const arvlDateTemplate = (args) => {
    return (
      <>
        <Tooltip
          placement="top"
          mouseLeaveDelay={0}
          color={"#800080"}
          title={
            args.nonConfirmedArrivalDate
              ? () => {
                  return (
                    <>
                      <span>
                        Old Date :{" "}
                        <b>{moment(args?.arrivalDate).format("MM/DD/YYYY")}</b>
                        <br />
                        New Date :{" "}
                        <b>
                          {moment(args?.nonConfirmedArrivalDate).format(
                            "MM/DD/YYYY"
                          )}
                        </b>
                      </span>
                    </>
                  );
                }
              : null
          }
        >
          <span
            className={
              args.nonConfirmedArrivalDate
                ? "fillColorLoadManager"
                : "removeColorLoadManager"
            }
          >
            {moment(args?.arrivalDate).format("MM/DD/YYYY")}
          </span>
        </Tooltip>
      </>
    );
  };

  const getLoadDetailsbyId = useCallback(async (loadId) => {
    try {
      const response = await LoadMangerService.getLoadDataById(loadId);
      if (response?.status === 200) {
        setLoadViewInfo(response?.data);
        props.setLoadViewInfo(response?.data);
        handleGroupedRecev(response?.data?.purchaseOrders);
        handleGroupedShipFrom(response?.data?.purchaseOrders);
        setClaimNumbers(
          (response?.data?.loadDetailsDto?.cliamNumber &&
            response?.data?.loadDetailsDto?.cliamNumber?.split(",")) ||
            []
        );
      }
    } catch (err) {
      message.error(err);
      setLoader(false);
    }
  });
  const handleGetAuditDetails = useCallback(async (args) => {
    try {
      setSelectedRowNotesDataNotes([]);
      setLoader(true);
      const response = await LoadMangerService.getAuditInfoByLoadId(
        loadViewInfo?.loadDetailsDto?.loadId
      );
      if (response?.status === 200) {
        setAuditInfo(response?.data);
        setLoader(false);
      }
    } catch (err) {
      message.error(err);
      setLoader(false);
    }
  });
  const auditDateTemplate = (args) => {
    return (
      <>
        <span title={moment(args?.createdDate).format("MM/DD/YYYY hh:mm:ss")}>
          {moment(args?.createdDate).format("MM/DD/YYYY hh:mm:ss")}
        </span>
      </>
    );
  };
  const shippingPalletsTemplate = (args) => {
    return (
      <>
        <span title={args?.totalPallets}>
          <NumberFormat
            value={args?.totalPallets}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };
  const shippingCubesTemplate = (args) => {
    return (
      <>
        <span title={args?.totalCubes}>
          <NumberFormat
            value={args?.totalCubes}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };
  const shippingWeightTemplate = (args) => {
    return (
      <>
        <span title={parseFloat(args.totalWeight)}>
          <NumberFormat
            value={parseFloat(args.totalWeight)}
            displayType={"text"}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };
  const shippingWeightTemplatePOLines = (args) => {
    return (
      <>
        <span title={parseFloat(args.weight) * parseInt(args.quantity)}>
          <NumberFormat
            value={parseFloat(args.weight) * parseInt(args.quantity)}
            displayType={"text"}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowEmptyFormatting
          />
        </span>
      </>
    );
  };
  const loadViewShipFrom = (args) => {
    return (
      <>
        <button
          onClick={(e) => handleGetLoadLocations(e, args?.shipFrom?.locationId)}
          className="loadManagerTablelabelButtonMainTable"
          title={args?.shipFrom?.locationName}
        >
          {args?.shipFrom?.shortName}
        </button>
      </>
    );
  };
  const loadViewRecShipTo = (args) => {
    return (
      <>
        <button
          onClick={(e) => handleGetLoadLocations(e, args?.shipTo?.locationId)}
          className="loadManagerTablelabelButton"
          title={args?.shipTo?.locationName}
        >
          {args?.shipTo?.shortName}
        </button>
      </>
    );
  };
  const loadViewCarrierorVendorName = (args) => {
    return (
      <>
        <button
          onClick={(e) => handleGetLoadCarrAndVendorDetails(e, args?.vendorId)}
          className="loadManagerTablelabelButtonMainTable"
          title={args?.vendorName}
        >
          {args?.vendorName}
        </button>
      </>
    );
  };
  const handleGetLoadCarrAndVendorDetails = useCallback(async (e, carId) => {
    if (carId) {
      try {
        setVisibleLoadCarrierVendorDetails(true);
        setSelectedDetails({});
        const response = await LoadMangerService.getLoadTradingPartnerDetails(
          carId
        );
        if (response?.status === 200) {
          setSelectedDetails(response?.data);
        }
      } catch (err) {
        message.error(err);
      }
    }
  });
  const handleGetLoadLocations = useCallback(async (e, locId) => {
    try {
      setVisibleLoadLocationDetails(true);
      setSelectedLoadDetails({});
      const response = await LoadMangerService.getLoadLocationDetails(locId);
      if (response?.status === 200) {
        setSelectedLoadDetails(response?.data);
      }
    } catch (err) {
      message.error(err);
    }
  });

  const arrivalDatePOTemplate = (args) => {
    return (
      <>
        <span>{moment(args?.arrivalDate).format("MM/DD/YYYY")}</span>
      </>
    );
  };
  const auditDesc = (args) => {
    return (
      <>
        <span title={args?.description}>{args?.description}</span>
      </>
    );
  };

  function dataBound() {
    if (gridInstance) {
      gridInstance?.detailExpandAll();
    }
  }
  const attachmentLinkTemplate = (args) => {
    return (
      <span
        className="third-party-txt"
        style={{ margin: 0 }}
        onClick={() => handleViewAttachment(args)}
      >
        View
      </span>
    );
  };
  const handleViewAttachment = useCallback(async (attachment) => {
    try {
      const response = await CommonService.viewAttachment(attachment);
      if (response.status === 200) {
      }
    } catch (err) {}
  }, []);

  const showMore = (args, selectedRowNotesDataNotes) => {
    // console.log(args);
    // console.log(headerNotes);
    const headerNotesCopy = [...headerNotes];
    headerNotesCopy.forEach((ele, idx) => {
      if (idx === +args.index) {
        ele.showMore = true;
      } else {
        ele.showMore = false;
      }
    });
    setSelectedRowNotesDataNotes(headerNotesCopy);
  };

  const showLess = (args, selectedRowNotesDataNotes) => {
    // console.log(args);
    // console.log(headerNotes);
    const headerNotesCopy = [...headerNotes];
    headerNotesCopy.forEach((ele, idx) => {
      // if (idx === +args.index) {
      ele.showMore = false;
      // }
    });
    setSelectedRowNotesDataNotes(headerNotesCopy);
  };

  //Get Partner Details with partnerID
  const getCustomerDetails = useCallback(async (customerName) => {
    try {
      setVisibleUserDetails(true);
      setSelectedDetails({});
      const response = await UserManagementService.getUserDetails({
        name: customerName,
      });
      if (response.status === 200) {
        setSelectedDetails(response.data);
      }
    } catch (err) {
      console.log();
      message.error("Issue in getCustomerDetails method");
    }
  }, []);

  const notesTemplate = (args) => {
    return (
      <>
        {!args.showMore && (
          <span className="po-notes">
            {args.note.substring(0, 60)}
            {args.note.length > 60 && (
              <span>
                ...{" "}
                <a
                  style={{ fontSize: 13 }}
                  className="po-hyper-link"
                  onClick={() => showMore(args, selectedRowNotesDataNotes)}
                >
                  Show More
                </a>
              </span>
            )}
          </span>
        )}

        {args.showMore && (
          <span className="po-notes">
            {args.note}

            <span>
              ...{" "}
              <a
                style={{ fontSize: 13 }}
                className="po-hyper-link"
                onClick={() => showLess(args, selectedRowNotesDataNotes)}
              >
                Show Less
              </a>
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <div className="">
          <h3
            className="receivingHeading"
            style={{
              fontSize: "17px",
              marginTop: "9px",
              paddingBottom: "0.1rem",
            }}
          >
            Load Details
          </h3>
        </div>
      </div>
      <div>
        <Form name="addSystemOption" layout="vertical" requiredMark={true}>
          <div style={{ marginTop: 5 }}>
            <div
              className="row "
              style={{
                width: "100%",
              }}
            >
              <div className="col-2">
                <div
                  className="logisticLMHeaderfields"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Item
                    label="Load Number"
                    name="load Number"
                    colon={false}
                  >
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.loadNumber}
                    </label>
                  </Form.Item>
                  <Form.Item label="Load Type" name="loadType" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.loadType}
                    </label>
                  </Form.Item>
                  <Form.Item label="Status" name="status" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.loadStatus}
                    </label>
                  </Form.Item>
                </div>
              </div>
              <div className="col-2" style={{ marginTop: "10px" }}>
                <div className="logisticLMHeaderfields1">
                  <Form.Item label="Carrier" name="carrier" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.carrierName}
                    </label>
                  </Form.Item>
                  <Form.Item label="Container" name="container" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.container}
                    </label>
                  </Form.Item>
                  <Form.Item label="Ship Date" name="shipDate" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {moment(loadViewInfo?.loadDetailsDto?.shipDate).format(
                        "MM/DD/YYYY"
                      )}
                    </label>
                  </Form.Item>
                </div>
              </div>
              <div className="col-2" style={{ marginTop: "10px" }}>
                <div className="logisticLMHeaderfields1">
                  <Form.Item
                    label="Arrival Date"
                    name="arrivalDate"
                    colon={false}
                  >
                    <label className="lodDetailsFormLabel">
                      {moment(loadViewInfo?.loadDetailsDto?.arrivalDate).format(
                        "MM/DD/YYYY"
                      )}
                    </label>
                  </Form.Item>
                  <Form.Item label="Pickups" name="pickups" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.totalPickups}
                    </label>
                  </Form.Item>
                  <Form.Item label="Deliveries" name="deliveries" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.totalDeliveries}
                    </label>
                  </Form.Item>
                </div>
              </div>
              <div className="col-2" style={{ marginTop: "10px" }}>
                <div className="logisticLMHeaderfields1">
                  <Form.Item label="Distance" name="distance" colon={false}>
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.distance}
                    </label>
                  </Form.Item>
                  <Form.Item
                    label="Toatal Cases"
                    name="totalCases"
                    colon={false}
                    title={loadViewInfo?.loadDetailsDto?.cases}
                  >
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.cases}
                    </label>
                  </Form.Item>
                  <Form.Item
                    label="Total Cubes"
                    name="totalPallets"
                    colon={false}
                    title={loadViewInfo?.loadDetailsDto?.cubes}
                  >
                    {loadViewInfo?.loadDetailsDto?.cubes &&
                    loadViewInfo?.loadDetailsDto?.cubes > 0 ? (
                      <label className="lodDetailsFormLabel">
                        <NumberFormat
                          value={loadViewInfo?.loadDetailsDto?.cubes}
                          displayType={"text"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                        />
                      </label>
                    ) : (
                      <label className="lodDetailsFormLabel">
                        {loadViewInfo?.loadDetailsDto?.cubes === 0
                          ? loadViewInfo?.loadDetailsDto?.cubes + ".00"
                          : ""}
                      </label>
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="col-2" style={{ marginTop: "10px" }}>
                <Form.Item
                  label="Total Pallets"
                  name="totalPallets"
                  colon={false}
                  title={loadViewInfo?.loadDetailsDto?.pallets}
                >
                  {loadViewInfo?.loadDetailsDto?.pallets &&
                  loadViewInfo?.loadDetailsDto?.pallets > 0 ? (
                    <label className="lodDetailsFormLabel">
                      <NumberFormat
                        value={loadViewInfo?.loadDetailsDto?.pallets}
                        displayType={"text"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </label>
                  ) : (
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.pallets === 0
                        ? loadViewInfo?.loadDetailsDto?.pallets + ".00"
                        : ""}
                    </label>
                  )}
                </Form.Item>
                <Form.Item
                  label="Toatal Weight"
                  name="totalWeight"
                  colon={false}
                  title={loadViewInfo?.loadDetailsDto?.weight}
                >
                  {loadViewInfo?.loadDetailsDto?.weight &&
                  loadViewInfo?.loadDetailsDto?.weight > 0 ? (
                    <label className="lodDetailsFormLabel">
                      <NumberFormat
                        value={loadViewInfo?.loadDetailsDto?.weight}
                        displayType={"text"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </label>
                  ) : (
                    <label className="lodDetailsFormLabel">
                      {loadViewInfo?.loadDetailsDto?.weight === 0
                        ? loadViewInfo?.loadDetailsDto?.weight + ".00"
                        : ""}
                    </label>
                  )}
                </Form.Item>
                <Form.Item
                  label="Freight Amount ($)"
                  name="freightAmount"
                  colon={false}
                >
                  <label className="lodDetailsFormLabel">
                    <NumberFormat
                      value={
                        loadViewInfo?.loadDetailsDto?.loadPricingDto?.totalCost
                      }
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={true}
                      allowEmptyFormatting
                    />
                  </label>
                </Form.Item>
              </div>
              <div className="col-2" style={{ marginTop: "10px" }}>
                <div className="POModifySummaryHeaderfields1">
                  <Form.Item label="Claim #" name="claim" colon={false}>
                    {claimNumbers?.length === 0 && <span>-</span>}
                    {claimNumbers.length === 1 && (
                      <span
                        style={
                          claimNumbers.length === 1
                            ? {
                                textDecoration: "underline",
                                cursor: "pointer",
                              }
                            : null
                        }
                        className="custom-value-style"
                        onClick={() => {
                          if (claimNumbers.length === 1) {
                            navigate(
                              `/receiving/create-claim/view/claimView/${claimNumbers[0]}`
                            );
                          }
                        }}
                      >
                        {claimNumbers[0] || "-"}
                      </span>
                    )}
                    {claimNumbers.length > 1 && (
                      <>
                        <Popover
                          style={{ minWidth: 200 }}
                          content={claimNumberTemplate}
                          title=""
                          trigger="click"
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Multiple
                          </span>
                        </Popover>
                      </>
                    )}
                  </Form.Item>
                </div>
                <div className="d-flex">
                  <Form.Item label="Audit Log" name="auditlog" colon={false}>
                    <button
                      onClick={auditlogModalClick}
                      className="lmLoadDetailsFormlabel"
                    >
                      A
                    </button>
                  </Form.Item>
                  <Form.Item label="Notes" name="notes" colon={false}>
                    <button className="notesButton" onClick={notesModalChange}>
                      <img
                        alt="notes"
                        height={25}
                        src={
                          loadViewInfo?.loadDetailsDto?.notes?.length > 0
                            ? notesColorYellowIcon
                            : notesColorIcon
                        }
                      />
                    </button>
                  </Form.Item>
                </div>

                <Form.Item
                  label="Trans Coordinator"
                  name="createdBy"
                  colon={false}
                >
                  <span
                    className="po-hyper-link"
                    onClick={(e) =>
                      getCustomerDetails(
                        loadViewInfo?.loadDetailsDto?.createdBy
                      )
                    }
                  >
                    {loadViewInfo?.loadDetailsDto?.createdBy}
                  </span>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        <div className="col-12">
          <h3
            className="receivingHeading"
            style={{
              fontSize: "17px",
              marginTop: "9px",
              paddingBottom: "0.1rem",
            }}
          >
            Shipping
          </h3>
        </div>
        <div className="loadManagerCarrierShippingGrid">
          <GridComponent
            dataSource={shippingMappingList}
            detailTemplate={POtemplate}
            detailDataBound={detailDataBound}
            gridLines="Both"
            rowHeight={36}
            allowPaging={true}
            width="100%"
            style={{ minHeight: "18rem" }}
            // height={totalCount > 5 ? `${totalCount * 35}px` : "15rem"}
            // height={shippingMappingList?.length >= 3 ? "25rem" : "15rem"}
            allowExcelExport={true}
            allowSelection={true}
            allowResizing={true}
            ref={(g) => (gridInstance = g)}
            dataBound={dataBound}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="seqId"
                headerText="SEQ"
                width="60"
                textAlign="Left"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="shipFrom.locationName"
                headerText="Ship From"
                width="100"
                textAlign="Left"
                allowEditing={true}
                template={loadViewShipFrom}
              ></ColumnDirective>
              <ColumnDirective
                field="poNumber"
                headerText="PO Number"
                width="130"
                textAlign="Left"
                allowEditing={true}
                //template={loadPOClickStyle}
              ></ColumnDirective>
              <ColumnDirective
                field="status"
                headerText="Status"
                width="95"
                textAlign="Left"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="vendorName"
                headerText="Vendor"
                width="90"
                textAlign="Left"
                allowEditing={true}
                template={loadViewCarrierorVendorName}
              ></ColumnDirective>
              <ColumnDirective
                field="shipDate"
                headerText="SHIP"
                width="80"
                allowEditing={true}
                textAlign="Left"
                template={shipClickStyle}
              ></ColumnDirective>
              <ColumnDirective
                field="arrivalDate"
                headerText="ARRIVAL"
                width="95"
                textAlign="Left"
                template={arrivalDatePOTemplate}
              ></ColumnDirective>
              <ColumnDirective
                field="pickupNum"
                headerText="PICKUP #"
                width="90"
                allowEditing={true}
                textAlign="Right"
              ></ColumnDirective>
              <ColumnDirective
                field="landedCost"
                headerText="Landed Cost ($)"
                width="85"
                textAlign="LEFT"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="listCost"
                headerText="List Cost ($)"
                width="85"
                textAlign="LEFT"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="freight"
                headerText="Freight ($)"
                width="85"
                textAlign="Right"
                allowEditing={true}
                template={(args) => freightClickStyle(args)}
              ></ColumnDirective>
              <ColumnDirective
                field="totalQuantity"
                headerText="Qty"
                width="85"
                textAlign="Center"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="totalOriginalQuantity"
                headerText="OQ"
                width="60"
                textAlign="Center"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="totalShippedQuantity"
                headerText="SQ"
                width="60"
                textAlign="Center"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="totalReceivedQuantity"
                headerText="RQ"
                width="60"
                textAlign="Center"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="totalCubes"
                headerText="Cube"
                width="65"
                textAlign="Right"
                allowEditing={true}
                template={shippingCubesTemplate}
              ></ColumnDirective>
              <ColumnDirective
                field="totalPallets"
                headerText="PLTS"
                width="60"
                textAlign="Right"
                allowEditing={true}
                template={shippingPalletsTemplate}
              ></ColumnDirective>
              <ColumnDirective
                field="totalWeight"
                headerText="WEIGHT (LB)"
                width="80"
                textAlign="Right"
                allowEditing={true}
                template={shippingWeightTemplate}
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Edit, Page, DetailRow]} />
          </GridComponent>
        </div>

        <div className="col-12" style={{ marginTop: "15px" }}>
          <div
            className="col-7"
            style={{ float: "left", marginTop: "0.5rem" }}
            id="loadManager"
          >
            <div className="col-12">
              <h3
                className="receivingHeading"
                style={{
                  fontSize: "17px",
                  marginTop: "9px",
                  paddingBottom: "0.1rem",
                }}
              >
                Receiving
              </h3>
            </div>
            <div className="recveGrid">
              <GridComponent
                dataSource={groupedReceivingTbl && groupedReceivingTbl}
                gridLines="Both"
                rowHeight={36}
                width="100%"
                height="127px"
                allowExcelExport={true}
                allowSelection={true}
                allowResizing={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="seqId"
                    headerText="SEQ"
                    width="30"
                    textAlign="Center"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="shipTo.locationName"
                    headerText="Delivery To"
                    width="80"
                    textAlign="Left"
                    template={loadViewRecShipTo}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="poNumber"
                    headerText="PO Number"
                    width="60"
                    textAlign="Left"
                    //template={loadPONumReceiving}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="status"
                    headerText="Status"
                    width="50"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="customerName"
                    headerText="Customer"
                    width="80"
                    textAlign="Left"
                    template={loadCustNameReceiving}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="arrivalDate"
                    headerText="Arrival"
                    width="70"
                    textAlign="Left"
                    allowEditing={true}
                    template={arvlDateTemplate}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="appt"
                    headerText="APPt"
                    width="70"
                    textAlign="Left"
                    allowEditing={true}
                  ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Edit, Page]} />
              </GridComponent>
            </div>
          </div>
          <div className="col-5" style={{ float: "left", marginTop: "0.5rem" }}>
            <div className="col-12">
              <div className="load-cost-summary">
                <h3
                  className="receivingHeading"
                  style={{
                    fontSize: "17px",
                    marginTop: "9px",
                    paddingBottom: "0.1rem",
                  }}
                >
                  Load Summary
                </h3>
                <div className="row">
                  <div className="col-9">
                    <div className="item-section">
                      <label>PO Cost ($)</label>
                      <NumberFormat
                        value={
                          loadViewInfo?.loadDetailsDto
                            ?.totalPOCostWithoutCharge || 0
                        }
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>

                    <div className="item-section">
                      <label>PO Charge ($)</label>
                      <NumberFormat
                        value={loadViewInfo?.loadDetailsDto?.totalPOCharge || 0}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="item-section">
                      <label style={{ fontWeight: "bold" }}>Total PO ($)</label>
                      <NumberFormat
                        value={loadViewInfo?.loadDetailsDto?.totalPOCost || 0}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="item-section">
                      <label>Freight Cost ($)</label>
                      <NumberFormat
                        value={
                          loadViewInfo?.loadDetailsDto?.loadPricingDto
                            ?.totalCost || 0
                        }
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                    <div className="item-section">
                      <label>Freight Charge</label>
                      <NumberFormat
                        value={
                          loadViewInfo?.loadDetailsDto?.freightChargeCost || 0
                        }
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="item-section">
                      <label style={{ fontWeight: "bold" }}>
                        Total Freight ($)
                      </label>
                      <NumberFormat
                        value={
                          loadViewInfo?.loadDetailsDto?.loadPricingDto
                            ?.totalCost || 0
                        }
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="item-section"
                      style={{
                        border: "1px solid #1c1c1c",
                        padding: "8px 0",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      <label style={{ fontWeight: "bold" }}>
                        Total Cost ($)
                      </label>
                      <NumberFormat
                        value={loadViewInfo?.loadDetailsDto?.totalLoadCost || 0}
                        displayType={"text"}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        allowEmptyFormatting
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-7" style={{ marginTop: "1.1rem", width: "57%" }}>
          <h3
            className="receivingHeading"
            style={{
              fontSize: "17px",
              marginTop: "9px",
              paddingBottom: "0.1rem",
            }}
          >
            Attachments
          </h3>
          <GridComponent
            dataSource={loadViewInfo?.loadDetailsDto?.attachments}
            gridLines="Both"
            rowHeight={36}
            width="100%"
            height="200px"
            allowExcelExport={true}
            editSettings={editSettings}
            allowSelection={true}
            allowResizing={true}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="classification"
                headerText="CLASSIFICATION"
                width="50"
                textAlign="Left"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="description"
                headerText="ATTACHMENT DESCRIPTION"
                width="60"
                textAlign="Left"
                allowEditing={true}
              ></ColumnDirective>
              <ColumnDirective
                field="link"
                headerText="ATTACHMENT LINK"
                width="50"
                textAlign="Left"
                allowEditing={true}
                template={attachmentLinkTemplate}
              ></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Edit, Page]} />
          </GridComponent>
        </div>
        <div>
          <Modal
            width={1200}
            maskClosable={false}
            title="Audit Log"
            visible={auditlogModalVisible}
            footer={null}
            onCancel={auditlogModalClose}
          >
            <div className="col-12">
              <GridComponent
                dataSource={auditInfo}
                gridLines="Both"
                rowHeight={36}
                width="100%"
                height="200px"
                allowExcelExport={true}
                allowSelection={true}
                allowResizing={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="createdDate"
                    headerText="Date"
                    width="35"
                    textAlign="Left"
                    template={auditDateTemplate}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="userName"
                    headerText="USER Name"
                    width="30"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="companyName"
                    headerText="Company Name"
                    width="30"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="description"
                    headerText="LOG NOTIFICATION"
                    width="100"
                    textAlign="Left"
                    template={auditDesc}
                  ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Edit, Page]} />
              </GridComponent>
            </div>
            <div className="col-12">
              <ButtonComponent
                className="btnAuditInfoClose"
                onClick={auditlogModalClose}
              >
                Close
              </ButtonComponent>
            </div>
          </Modal>
        </div>

        {newLoadModalVisible && (
          <div>
            <Modal
              width={1050}
              maskClosable={false}
              visible={newLoadModalVisible}
              footer={null}
              onCancel={newLoadModalClose}
            >
              {newLoadModalVisible && (
                <AutoFreightOnPO
                  newLoadModalClose={newLoadModalClose}
                  autoFreightPO={selectedPurchaseOrders}
                  autoFreightLoad={loadViewInfo}
                />
              )}
            </Modal>
          </div>
        )}
        {autoFreightOnLoadVisible && (
          <div>
            <Modal
              width={1050}
              maskClosable={false}
              visible={autoFreightOnLoadVisible}
              footer={null}
              onCancel={autoFreightOnLoadClose}
            >
              {autoFreightOnLoadVisible && (
                <AutoFreightOnLoad
                  newLoadModalClose={autoFreightOnLoadClose}
                  autoFreightLoad={loadViewInfo}
                />
              )}
            </Modal>
          </div>
        )}
        <div>
          <Modal
            width={400}
            maskClosable={false}
            title="Freight Update"
            visible={removeModalVisible}
            footer={null}
            onCancel={RemoveModalClose}
          >
            <div className="row">
              <div className="col-12">
                <label className="logisticLMRemoveLabel">
                  Freight entered at load level.
                  <br />
                  Do you want to change it to PO level?
                </label>
              </div>
              <div className="col-5"></div>
              <div className="col-7">
                <ButtonComponent
                  className="logisticNewLoadButton"
                  onClick={RemoveModalClose}
                  style={{ width: "80px" }}
                >
                  No
                </ButtonComponent>
                <ButtonComponent
                  className="logisticLMRemoveYesButton"
                  onClick={handleRemovePOOnLoad}
                  style={{ width: "80px" }}
                  loading={loader}
                >
                  Yes
                </ButtonComponent>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            width={400}
            maskClosable={false}
            title="Freight Update"
            visible={autoFreightOnLoadConfirmationModal}
            footer={null}
            onCancel={closeAutoFreightOnLoadConfirmationModal}
          >
            <div className="row">
              <div className="col-12">
                <label className="logisticLMRemoveLabel">
                  Freight entered at PO level.
                  <br />
                  Do you want to change it to load level?
                </label>
              </div>
              <div className="col-5"></div>
              <div className="col-7">
                <ButtonComponent
                  className="logisticNewLoadButton"
                  onClick={closeAutoFreightOnLoadConfirmationModal}
                  style={{ width: "80px" }}
                >
                  No
                </ButtonComponent>
                <ButtonComponent
                  className="logisticLMRemoveYesButton"
                  onClick={handleAutoFreightOnLoadConfirmationModal}
                  style={{ width: "80px" }}
                  loading={loader}
                >
                  Yes
                </ButtonComponent>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            width={1200}
            maskClosable={false}
            title="Notes"
            visible={notesVisible}
            footer={null}
            onCancel={notesModalClose}
          >
            <div className="col-12" style={{ marginTop: "5px" }} id="poHeader">
              <GridComponent
                dataSource={selectedRowNotesDataNotes}
                gridLines="Both"
                allowFiltering={false}
                rowHeight={36}
                width="100%"
                height="200px"
                editSettings={editSettings}
                allowSelection={true}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: "Content" }}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="createDate"
                    headerText="Date"
                    width="30"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="company"
                    headerText="Company"
                    width="30"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="userName"
                    headerText="USER NAME"
                    width="30"
                    textAlign="Left"
                  ></ColumnDirective>
                  <ColumnDirective
                    field="note"
                    headerText="NOTES"
                    width="100"
                    textAlign="Left"
                    template={notesTemplate}
                  ></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Edit, Page]} />
              </GridComponent>
            </div>
            <Button
              className="logisticNewLoadButton"
              onClick={notesModalClose}
              style={{ width: "120px" }}
            >
              Close
            </Button>
          </Modal>
        </div>
        <Modal
          width={1000}
          maskClosable={false}
          title="Item Details"
          visible={itemDetailsModalVisible}
          footer={null}
          onCancel={itemDetailsModalClose}
        >
          <ItemDetailsModal
            itemDetailsModalClose={itemDetailsModalClose}
            itemDetails={itemDetails}
          />
        </Modal>
      </div>
      <Modal
        width={1250}
        closable={false}
        title="Details"
        visible={visibleLoadLocationDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <ShipDetails locationDetails={selectedLoadDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisibleLoadLocationDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>
      <Modal
        width={680}
        closable={false}
        title="Details"
        visible={visibleLoadCarrierVendorDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <PartnerDetails selectedDetails={selectedDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisibleLoadCarrierVendorDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>
      <Modal
        width={500}
        closable={false}
        title="Item Details"
        visible={visibleItemDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <ItemNumberDetails itemDetails={itemDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => {
            setVisibleItemDetails(false);
            setItemDetails({});
          }}
          type="default"
        >
          Close
        </Button>
      </Modal>

      <Modal
        width={280}
        closable={false}
        title="Details"
        visible={visibleUserDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <UserDetails selectedDetails={selectedDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisibleUserDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>
    </>
  );
}

import {
  SET_PO_SUMMARY_DATA,
  SET_RECEIVING_SUMMARY_DATA,
  SET_SHIPPING_SUMMARY_DATA,
} from "../constants";

const initialState = {
  poSummaryData: {},
  shippingSummaryData: {},
  receivingSummaryData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PO_SUMMARY_DATA:
      return { ...state, poSummaryData: payload };
    case SET_SHIPPING_SUMMARY_DATA:
      return { ...state, shippingSummaryData: payload };
    case SET_RECEIVING_SUMMARY_DATA:
      return { ...state, receivingSummaryData: payload };
    default:
      return state;
  }
};

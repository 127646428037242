export const defaultPoLine = {
  chargeAmount: 0,
  cost: "",
  deliveredCost: 0,
  hi: "",
  isChanged: false,
  itemDescription: "",
  itemNumber: undefined,
  notes: [],
  packSize: "",
  quantity: "",
  ti: "",
  vendorItemNumber: "",
  active: false,
  isChecked: false,
  qtyShipped: 0,
  freightAmount: 0,
  qtyReceived: 0,
  isDelete: true,
  qtyChanged: false,
  costChanged: false,
  oldQty: 0,
  oldCost: 0,
  grossShipWeight: 0,
  itemTempZone: null,
  itemChange: false,
  finishedCost: 0,
  randomWeight: false,
  newItem: false,
  lineShipFrom: undefined,
  changedBy: "",
  qtyReadOnly: false,
  costReadOnly: false,
};

export const addCharges = {
  amount: "",
  basis: undefined,
  chargeCode: undefined,
  chargeName: undefined,
  chargeType: "",
  isChanged: false,
  itemNumber: undefined,
  vendorName: undefined,
  vendorVisible: false,
  mode: "CREATE",
  isDelete: true,
  source: "",
  buyerChargeCode: "",
  vendorChargeCode: "",
  readOnly: false,
};

export const routingOptions = [
  {
    value: "Backhaul",
    description: "Backhaul",
    active: false,
  },
  {
    value: "Delivered",
    description: "Delivered",
    active: false,
  },
  {
    value: "FOB",
    description: "FOB",
    active: false,
  },
];

export const allowEdit = [
  "Draft",
  "Submitted",
  "Confirmed",
  "Adj Buy",
  "Adj Ven",
  "Shipped",
];

export const allowEdit1 = [
  "Draft",
  "Submitted",
  "Confirmed",
  "Adj Buy",
  "Adj Ven",
  "Shipped",
];

export const ENABLE_CHECKBOX = [
  "Draft",
  "Submitted",
  "Confirmed",
  "Adj Buy",
  "Adj Ven",
  "Shipped",
  "Receipt Adj",
  "Received",
  "Part Received",
];

export const ENABLE_MOVE_PO = ["Submitted", "Confirmed", "Adj Buy", "Shipped"];

export const poStatusLables = {
  Draft: "Draft",
  Submitted: "Submitted",
  "Adj Ven": "Adj Ven",
  "Adj Buy": "Adj Buy",
  Received: "Received",
  "Part Received": "Part Received",
  Cancelled: "Cancelled",
  Shipped: "Shipped",
  Confirmed: "Confirmed",
  "Receipt Adj": "Receipt Adj",
  Rejected: "Rejected",
  SUBMTD: "Submitted",
  DRAFT: "Draft",
  Cancel: "Cancelled",
  Open: "Open",
};

export const poStatusClassNames = {
  Draft: "po-draft",
  Submitted: "po-submitted",
  "Adj Ven": "po-adj-ven",
  "Adj Buy": "po-adj-buy",
  Received: "po-received",
  "Part Received": "po-part-received",
  Cancelled: "po-cancelled",
  Shipped: "po-shipped",
  Confirmed: "po-confirmed",
  "Receipt Adj": "po-receipt-adj",
  Rejected: "po-rejected",
  SUBMTD: "po-submitted",
  DRAFT: "po-draft",
  Cancel: "po-cancelled",
  Open: "po-received",
  "Partially Delivered": "po-part-received",
  "Partially Received": "po-part-received",
  "In Transit": "po-shipped",
};

export const VENDOR_PO_LINE_GRID_COLUMNS = [
  {
    field: "itemDescription",
    headerText: "Item Desc",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "vendorItemNumber",
    headerText: "Internal Item #",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "weight",
    headerText: "Weight",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "qtyShipped",
    headerText: "SQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "rcvdQty",
    headerText: "RQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "poQunatity",
    headerText: "OQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "deliveredCost",
    headerText: "Line Cost",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "lineShipFrom",
    headerText: "Ship From",
    width: "100",
    textAlign: "left",
    visible: true,
  },
];

export const BUYER_PO_LINE_GRID_COLUMNS = [
  {
    field: "itemDescription",
    headerText: "Item Desc",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "vendorItemNumber",
    headerText: "Vendor Item #",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "weight",
    headerText: "Weight",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "freightAmount",
    headerText: "Freight",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "qtyShipped",
    headerText: "SQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "rcvdQty",
    headerText: "RQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "poQunatity",
    headerText: "OQ",
    width: "100",
    textAlign: "left",
    visible: true,
  },

  {
    field: "deliveredCost",
    headerText: "DLVD Cost",
    width: "100",
    textAlign: "left",
    visible: true,
  },
  {
    field: "lineShipFrom",
    headerText: "Ship From",
    width: "100",
    textAlign: "left",
    visible: true,
  },
];

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ClientLogo from "../assets/icons/navbar/clientLogo.svg";
import llAdminIcon from "../assets/icons/navbar/llAdmin.svg";
import HomeIcon from "../assets/icons/navbar/home.svg";

import UserService from "../services/user.service";

import { useSelector } from "react-redux";
import {
  getMenuListSelector,
  isAccessRolesSelector,
} from "../store/selectors/app.selector";
import { menuItems } from "./menuLinks";

export default function SideBar() {
  const isAdminUser = UserService.isLlAdmin();
  const userEntitledMenuItems = useSelector(getMenuListSelector);
  const isAccessRole = useSelector(isAccessRolesSelector);

  return (
    <>
      <div className="nav-links">
        {isAdminUser && isAccessRole && (
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={"/home"}
          >
            <img src={HomeIcon} alt="Home icon" />
            <span>{"Home"}</span>
          </NavLink>
        )}

        {userEntitledMenuItems.map((menu, idx) => (
          <NavLink
            className={(navData) => (navData.isActive ? "active" : "")}
            to={menu.link}
            key={idx}
          >
            <img src={menu.icon} height="35" width={"35"} />
            <span>{menu.title}</span>
          </NavLink>
        ))}

        <div
          style={{
            height: 1,
            backgroundColor: "#CCCCCC",
            margin: "7px 10px",
          }}
        ></div>
        <div className="additional-links">
          {isAdminUser && (
            <NavLink
              className={(navData) => (navData.isActive ? "active" : "")}
              to={"/LLAdmin"}
            >
              <img src={llAdminIcon} />
              <span>{"LL Admin"}</span>
            </NavLink>
          )}

          <div className="clientLogo">
            <img src={ClientLogo} />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Loader from "./components/loader";
import Layout from "./layout/layout";
import { useSelector } from "react-redux";
import { loginDetailsSelector } from "./store/selectors/app.selector";
import UserManagementService from "./services/userManagement.service";
import AuthRoute from "./components/auth-route/AuthRoute";
import {
  CreateDemandFromDemandComponentRoute,
  DashboardRoute,
  DemandFromTemplateRoute,
  DemandRoute,
  LoadDetailsRoute,
  LoadManagerRoute,
  LoadSummaryLoadDetailsRoute,
  LoadSummaryRoute,
  LogisticRoute,
  PurchasingPoRoute,
  PurchasingRoute,
  PurchasingPoTemplateIdRoute,
  ModifyBuyerPORoute,
  ModifyVendorPORoute,
  CopyPORoute,
  DemandPORoute,
  DemandExistingPORoute,
  MasterDataRoute,
  ItemMasterRoute,
  ItemMasterAddRoute,
  CarrierRatesRoute,
  VendorPriceListRoute,
  ThirdPartyChargesRoute,
  TradingPartnersRoute,
  ModifyTradingPartnerRoute,
  SetupRoute,
  BusinessGroupRoute,
  BusinessGroupComanyRoute,
  BusinessGroupLocationRoute,
  BusinessGroupLocationCreateRoute,
  SetupAdminRoute,
  SetupAdminUserManagementRoute,
  SetupAddUserRoute,
  SetupModifyUserRoute,
  SetupApplicationConfigurationRoute,
  SetupLookupsRoute,
  SetupUserPreferencesRoute,
  Analytics,
  BiReports,
  ReceivingRoute,
  ReceivingClaimAdminRoute,
  ReceivingClaimSummaryRoute,
  ReceivingCreateClaimRoute,
  ReceivingCreateClaimClaimNumRoute,
  ReceivingCreateClaimViewClaimNumRoute,
  ReceivingAdminClaimDetailRoute,
  ReceivingReceiptRoute,
  ReceivingReceiptDetailsFormRoute,
  BillingRoute,
  BillingCustomerInvoicesRoute,
  BillingCustomerGenerateInvoiceRoute,
  BillingCustomerInvoicesNumberRoute,
  BillingCustomerInvoiceNumberRoute,
  BillingCarrierInvoicesRoute,
  BillingCarrierGenerateInvoiceRoute,
  BillingCarrierInvoicesNumberRoute,
  BillingCarrierInvoiceNumberRoute,
} from "./appRoutes";
import StaticLayout from "./layout/staticLayout";
import PurchaseBuyerView from "./pages/purchasing/po-view-readonly";
import PurchaseVendorView from "./pages/purchasing/po-vendor-readonly";

import LoadDetailsFormView from "./pages/logistic/load-manager/loadDetails-LM-form-view";
import UnAuthorized from "./unAuthorized/unAuthorized";
import PurchaseCarrierStaticView from "./pages/purchasing/po-carrier-readonly";

const LLAdmin = React.lazy(() => import("./pages/llAdmin/llAdmin"));
const LLAdminUserList = React.lazy(() =>
  import("./pages/llAdmin/user-management/user-list")
);
const LLAdminAddUser = React.lazy(() =>
  import("./pages/llAdmin/user-management/add-user")
);
const ModifyLLAdminUser = React.lazy(() =>
  import("./pages/llAdmin/user-management/modify-user")
);
const ShippingDetailsForm = React.lazy(() =>
  import("./pages/shipping/shipment-details-form")
);
const ClientList = React.lazy(() => import("./pages/llAdmin/client/client"));
const ClientAdd = React.lazy(() => import("./pages/llAdmin/client/client-add"));
const ClientModify = React.lazy(() =>
  import("./pages/llAdmin/client/client-modify")
);

const Shipping = React.lazy(() => import("./pages/shipping/shipping"));

const ProfileComponent = React.lazy(() => import("./pages/profile/profile"));

export default function Router() {
  const userDetails = useSelector(loginDetailsSelector);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            userDetails["clientId"] === "99999" ? (
              <Navigate replace to="/home" />
            ) : (
              <Navigate replace to="/home" />
            )
          }
        />
        <Route
          path={DashboardRoute.link}
          element={<AuthRoute route={DashboardRoute} />}
        />
        <Route
          path={DemandRoute.link}
          element={<AuthRoute route={DemandRoute} />}
        />
        <Route
          path={DemandFromTemplateRoute.link}
          element={<AuthRoute route={DemandFromTemplateRoute} />}
        />
        <Route
          path={CreateDemandFromDemandComponentRoute.link}
          element={<AuthRoute route={CreateDemandFromDemandComponentRoute} />}
        />
        <Route
          path={LogisticRoute.link}
          element={<AuthRoute route={LogisticRoute} />}
        />
        <Route
          path={LoadManagerRoute.link}
          element={<AuthRoute route={LoadManagerRoute} />}
        />
        <Route
          path={LoadDetailsRoute.link}
          element={<AuthRoute route={LoadDetailsRoute} />}
        />
        <Route
          path={LoadSummaryRoute.link}
          element={<AuthRoute route={LoadSummaryRoute} />}
        />
        <Route
          path={LoadSummaryLoadDetailsRoute.link}
          element={<AuthRoute route={LoadSummaryLoadDetailsRoute} />}
        />
        <Route
          path={PurchasingRoute.link}
          element={<AuthRoute route={PurchasingRoute} />}
        />
        <Route
          path={PurchasingPoRoute.link}
          element={<AuthRoute route={PurchasingPoRoute} />}
        />
        <Route
          path={PurchasingPoTemplateIdRoute.link}
          element={<AuthRoute route={PurchasingPoTemplateIdRoute} />}
        />
        <Route
          path={ModifyBuyerPORoute.link}
          element={<AuthRoute route={ModifyBuyerPORoute} />}
        />
        <Route
          path={ModifyVendorPORoute.link}
          element={<AuthRoute route={ModifyVendorPORoute} />}
        />
        <Route
          path={CopyPORoute.link}
          element={<AuthRoute route={CopyPORoute} />}
        />
        <Route
          path={DemandPORoute.link}
          element={<AuthRoute route={DemandPORoute} />}
        />
        <Route
          path={DemandExistingPORoute.link}
          element={<AuthRoute route={DemandExistingPORoute} />}
        />
        <Route
          path={ReceivingRoute.link}
          element={<AuthRoute route={ReceivingRoute} />}
        />
        <Route
          path={ReceivingClaimSummaryRoute.link}
          element={<AuthRoute route={ReceivingClaimSummaryRoute} />}
        />
        <Route
          path={ReceivingCreateClaimRoute.link}
          element={<AuthRoute route={ReceivingCreateClaimRoute} />}
        />
        <Route
          path={ReceivingCreateClaimClaimNumRoute.link}
          element={<AuthRoute route={ReceivingCreateClaimClaimNumRoute} />}
        />
        <Route
          path={ReceivingCreateClaimViewClaimNumRoute.link}
          element={<AuthRoute route={ReceivingCreateClaimViewClaimNumRoute} />}
        />
        <Route
          path={ReceivingClaimAdminRoute.link}
          element={<AuthRoute route={ReceivingClaimAdminRoute} />}
        />
        <Route
          path={ReceivingAdminClaimDetailRoute.link}
          element={<AuthRoute route={ReceivingAdminClaimDetailRoute} />}
        />
        <Route
          path={ReceivingReceiptRoute.link}
          element={<AuthRoute route={ReceivingReceiptRoute} />}
        />

        <Route
          path={ReceivingReceiptDetailsFormRoute.link}
          element={<AuthRoute route={ReceivingReceiptDetailsFormRoute} />}
        />

        <Route
          path="/shipping/shipment-details-form/:poId"
          element={
            userDetails &&
            userDetails["clientType"] === "VENDOR" &&
            (UserManagementService.hasRole(
              userDetails["accessRoles"],
              "Turn Buyer"
            ) ||
              UserManagementService.hasRole(
                userDetails["accessRoles"],
                "National Buyer"
              )) && (
              <Layout>
                <Suspense fallback={<Loader />}>
                  <ShippingDetailsForm />
                </Suspense>
              </Layout>
            )
          }
        />
        <Route
          path="/shipping"
          element={
            userDetails &&
            userDetails["clientType"] === "VENDOR" &&
            (UserManagementService.hasRole(
              userDetails["accessRoles"],
              "Turn Buyer"
            ) ||
              UserManagementService.hasRole(
                userDetails["accessRoles"],
                "National Buyer"
              )) && (
              <Layout>
                <Suspense fallback={<Loader />}>
                  <Shipping />
                </Suspense>
              </Layout>
            )
          }
        />
        <Route
          path="/shipping/shipment-details-form"
          element={
            userDetails &&
            userDetails["clientType"] === "VENDOR" &&
            (UserManagementService.hasRole(
              userDetails["accessRoles"],
              "Turn Buyer"
            ) ||
              UserManagementService.hasRole(
                userDetails["accessRoles"],
                "National Buyer"
              )) && (
              <Layout>
                <Suspense fallback={<Loader />}>
                  <Shipping />
                </Suspense>
              </Layout>
            )
          }
        />
        <Route
          path={BillingRoute.link}
          element={<AuthRoute route={BillingRoute} />}
        />
        <Route
          path={BillingCustomerInvoicesRoute.link}
          element={<AuthRoute route={BillingCustomerInvoicesRoute} />}
        />
        <Route
          path={BillingCustomerGenerateInvoiceRoute.link}
          element={<AuthRoute route={BillingCustomerGenerateInvoiceRoute} />}
        />
        <Route
          path={BillingCustomerInvoicesNumberRoute.link}
          element={<AuthRoute route={BillingCustomerInvoicesNumberRoute} />}
        />
        <Route
          path={BillingCustomerInvoiceNumberRoute.link}
          element={<AuthRoute route={BillingCustomerInvoiceNumberRoute} />}
        />
        <Route
          path={BillingCarrierInvoicesRoute.link}
          element={<AuthRoute route={BillingCarrierInvoicesRoute} />}
        />
        <Route
          path={BillingCarrierGenerateInvoiceRoute.link}
          element={<AuthRoute route={BillingCarrierGenerateInvoiceRoute} />}
        />
        <Route
          path={BillingCarrierInvoicesNumberRoute.link}
          element={<AuthRoute route={BillingCarrierInvoicesNumberRoute} />}
        />

        <Route
          path={BillingCarrierInvoiceNumberRoute.link}
          element={<AuthRoute route={BillingCarrierInvoiceNumberRoute} />}
        />

        <Route
          path={Analytics.link}
          element={<AuthRoute route={Analytics} />}
        />
        <Route
          path={BiReports.link}
          element={<AuthRoute route={BiReports} />}
        />
        <Route
          path="/LLAdmin"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LLAdmin />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/clients"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ClientList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/clients/add"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ClientAdd />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/user_management"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LLAdminUserList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/user_management/list"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LLAdminUserList />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/user_management/add"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <LLAdminAddUser />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/user_management/modify"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ModifyLLAdminUser />
              </Suspense>
            </Layout>
          }
        />
        <Route
          path="/LLAdmin/clients/modify"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ClientModify />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path={MasterDataRoute.link}
          element={<AuthRoute route={MasterDataRoute} />}
        />
        <Route
          path={ItemMasterRoute.link}
          element={<AuthRoute route={ItemMasterRoute} />}
        />
        <Route
          path={ItemMasterAddRoute.link}
          element={<AuthRoute route={ItemMasterAddRoute} />}
        />
        <Route
          path={CarrierRatesRoute.link}
          element={<AuthRoute route={CarrierRatesRoute} />}
        />
        <Route
          path={VendorPriceListRoute.link}
          element={<AuthRoute route={VendorPriceListRoute} />}
        />
        <Route
          path={ThirdPartyChargesRoute.link}
          element={<AuthRoute route={ThirdPartyChargesRoute} />}
        />
        <Route
          path={TradingPartnersRoute.link}
          element={<AuthRoute route={TradingPartnersRoute} />}
        />
        <Route
          path={ModifyTradingPartnerRoute.link}
          element={<AuthRoute route={ModifyTradingPartnerRoute} />}
        />
        <Route
          path={SetupAdminUserManagementRoute.link}
          element={<AuthRoute route={SetupAdminUserManagementRoute} />}
        />
        <Route
          path={SetupAddUserRoute.link}
          element={<AuthRoute route={SetupAddUserRoute} />}
        />
        <Route
          path={SetupModifyUserRoute.link}
          element={<AuthRoute route={SetupModifyUserRoute} />}
        />
        <Route
          path={SetupAdminRoute.link}
          element={<AuthRoute route={SetupAdminRoute} />}
        />
        <Route
          path={SetupRoute.link}
          element={<AuthRoute route={SetupRoute} />}
        />
        <Route
          path={BusinessGroupRoute.link}
          element={<AuthRoute route={BusinessGroupRoute} />}
        />
        <Route
          path={BusinessGroupLocationCreateRoute.link}
          element={<AuthRoute route={BusinessGroupLocationCreateRoute} />}
        />
        <Route
          path={BusinessGroupLocationRoute.link}
          element={<AuthRoute route={BusinessGroupLocationRoute} />}
        />
        <Route
          path={BusinessGroupComanyRoute.link}
          element={<AuthRoute route={BusinessGroupComanyRoute} />}
        />
        <Route
          path="/setup/business-group/company"
          element={<Navigate to={BusinessGroupRoute.link} />}
        />

        <Route
          path={SetupApplicationConfigurationRoute.link}
          element={<AuthRoute route={SetupApplicationConfigurationRoute} />}
        />
        <Route
          path={SetupLookupsRoute.link}
          element={<AuthRoute route={SetupLookupsRoute} />}
        />
        <Route
          path={SetupUserPreferencesRoute.link}
          element={<AuthRoute route={SetupUserPreferencesRoute} />}
        />

        <Route
          path="/profile"
          element={
            <Layout>
              <Suspense fallback={<Loader />}>
                <ProfileComponent />
              </Suspense>
            </Layout>
          }
        />

        <Route
          path="/purchasing/buyer/view/:id"
          element={
            <StaticLayout>
              <PurchaseBuyerView />
            </StaticLayout>
          }
        />

        <Route
          path="/purchasing/carrier/view/:id"
          element={
            <StaticLayout>
              <PurchaseCarrierStaticView />
            </StaticLayout>
          }
        />

        <Route
          path="/purchasing/vendor/view/:id"
          element={
            <StaticLayout>
              <PurchaseVendorView />
            </StaticLayout>
          }
        />
        <Route
          path="/logistics/load-manager/load-details-view/:id"
          element={
            <StaticLayout>
              <LoadDetailsFormView />
            </StaticLayout>
          }
        />

        <Route
          path="/unAuthorized"
          element={
            <StaticLayout>
              <UnAuthorized />
            </StaticLayout>
          }
        />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

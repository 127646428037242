import React from "react";

export default function PartnerDetails({ selectedDetails = {} }) {
  return (
    <>
      {Object.keys(selectedDetails).length === 0 && (
        <div>Fetching data. Please wait a moment</div>
      )}
      {Object.keys(selectedDetails).length > 0 && (
        <>
          <div>
            <label>Partner Name</label>
            <span>{selectedDetails?.partnerName}</span>
          </div>
          <div>
            <label>Short Name</label>
            <span>{selectedDetails?.shortName}</span>
          </div>

          <div>
            <label>Contact Number</label>
            {selectedDetails.contacts === null ? '': 
            <span>{selectedDetails["contacts"][0]?.phone}</span>
            }
          </div>

          <div>
            <label>Address</label>
            <span>{`${selectedDetails?.addressLine1}, ${
              selectedDetails?.addressLine2
                ? selectedDetails?.addressLine2 + ","
                : ""
            } ${selectedDetails?.city}, ${selectedDetails?.state}, ${
              selectedDetails?.countryName
            } - ${selectedDetails?.zip} `}</span>
          </div>
        </>
      )}
    </>
  );
}

import http from "./http.service";
import config from "./constant";
import FileSaver from "file-saver";

const getClientList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.Client.clientList, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const generateClientId = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.Client.generateClientId, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postClient = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.Client.addClient, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const modifyClient = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.put(config.Client.addClient, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const searchClient = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.Client.clientSearch, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getClientDetails = async (clientId) => {
  try {
    const response = await http.get(`/admin/client/${clientId}`, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const duplicateCheck = async (name) => {
  try {
    const response = await http.get(
      config.Client.duplicateCheck.replace("{clientName}", name),
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const clientBulkUpload = async (formData) => {
  try {
    const response = await http.post(config.Client.clientBulkUpload, formData, {
      "content-type": "multipart/form-data",
    });
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const clientUploadSummary = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      config.Client.clientUploadSummary,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const clientErrorFileDownload = async (params) => {
  try {
    const response = await http.get(
      `/admin/client/${params.uploadId}/clientDownload`,
      {},
      {},
      {
        responseType: "blob",
      }
    );

    FileSaver.saveAs(response.data, "client-Error-template.xlsx");
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadImage = async (formData) => {
  try {
    const response = await http.post(config.Client.uploadImage, formData, {
      "content-type": "multipart/form-data",
    });
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getImage = async () => {
  try {
    const response = await http.get(
      config.Client.uploadImage,
      {},
      {},
      {
        responseType: "blob",
      }
    );

    //FileSaver.saveAs(response.data, "client-Error-template.xlsx");
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const ClientService = {
  getClientList,
  postClient,
  generateClientId,
  modifyClient,
  searchClient,
  getClientDetails,
  clientBulkUpload,
  clientErrorFileDownload,
  clientUploadSummary,
  uploadImage,
  getImage,
  duplicateCheck,
};

export default ClientService;

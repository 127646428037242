import http from "./http.service";
import config from "./constant";



const getUserList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.User.userList, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postUser = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.User.addUser, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchByUsername = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      `${config.User.searchByUsername}${params.name}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserDetailsById = async (params) => {
  try {
    const response = await http.get(
      `${config.User.getUserDetailsById}${params.id}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
}

const getUserDetails = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      `${config.User.getUserDetails}${params.name}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};


const resetPassword = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.User.resetPassword, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};


const hasRole = async (accessRoles, roleName) => {
  try {

    accessRoles.forEach((element) => {
      if (element?.role.roleName === roleName) {
        return true;
      }

    });
    return false;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};


const getBuyerList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.User.buyerList, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const UserManagementService = {
  getUserList,
  postUser,
  fetchByUsername,
  resetPassword,
  getUserDetails,
  getUserDetailsById,
  hasRole,
  getBuyerList,
};

export default UserManagementService;

import React, { useRef } from "react";

import moment from "moment";
import onIcon from "../../assets/icons/common/on.svg";
import offIcon from "../../assets/icons/common/off.svg";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";

export default function ShipDetails({ locationDetails = {} }) {
  const customizeCell = (args) => {
    if (args && args?.column && args?.column?.field === "primary") {
      args.cell.innerHTML = args.data[args?.column?.field]
        ? `<img src=${onIcon}  height="35" />`
        : `<img src=${offIcon}  height="35" />`;
    }
  };
  return (
    <>
      <div className="location-details">
        <div className="row">
          <div className="col-2">
            <div>
              <label>Location Code</label>
              <span>{locationDetails["locationCode"] || "-"}</span>
            </div>
            <div>
              <label>Location Name</label>
              <span>{locationDetails["locationName"] || "-"}</span>
            </div>
            <div>
              <label>Location Short Name</label>
              <span>{locationDetails["shortName"] || "-"}</span>
            </div>
            <div>
              <label>
                Active{" "}
                <span>
                  <img
                    src={locationDetails["active"] ? onIcon : offIcon}
                    height="35"
                  />
                </span>
              </label>
            </div>
          </div>

          <div className="col-2">
            <label>Address</label>
            {locationDetails?.address?.length > 0 && (
              <span style={{ lineHeight: "26px" }}>
                <span style={{ padding: 0 }}>
                  {locationDetails["address"][0]?.addressLine1}
                </span>
                {locationDetails["address"][0]?.addressLine2 && (
                  <span style={{ padding: 0 }}>
                    {locationDetails["address"][0]?.addressLine2}
                  </span>
                )}

                <span style={{ padding: 0 }}>
                  {locationDetails["address"][0]?.city}
                </span>
                <span style={{ padding: 0 }}>
                  {" "}
                  {locationDetails["address"][0]?.zipCode}
                </span>
                <span style={{ padding: 0 }}>
                  {locationDetails["address"][0]?.cntry}
                </span>
              </span>
            )}
          </div>

          <div className="col-4">
            <label>Working Week</label>
            <p>
              {locationDetails["workingList"] &&
                locationDetails["workingList"]?.map((option, idx) => (
                  <span key={idx}>
                    <b>{option.key}</b>{" "}
                    {option.value === null
                      ? "Not Available"
                      : `${moment(
                          option.value.split("-")[0],
                          "HH:mm:ss"
                        ).format("hh:mm A")} - ${moment(
                          option.value.split("-")[1],
                          "HH:mm:ss"
                        ).format("hh:mm A")} `}
                    {option.key === locationDetails["weekStart"]
                      ? "*Week Start"
                      : ""}
                  </span>
                ))}
            </p>
          </div>

          <div className="col-4">
            <label>Appointment Instructions</label>
            <div className="row appointment-details">
              <div className="col-6">
                <div>
                  <label>Contact Name</label>
                  <span>{locationDetails["aptContactName"] || "-"}</span>
                </div>
                <div>
                  <label>Phone</label>
                  <span>{locationDetails["aptPhoneNum"] || "-"}</span>
                </div>
                <div>
                  <label>Schedule Link</label>
                  <span>{locationDetails["aptSchdLnk"] || "-"}</span>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label>Instructions</label>
                  <span>{locationDetails["instructions"] || "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 className="secondary-title">Contacts</h6>

      <GridComponent
        dataSource={locationDetails["contacts"]}
        rowHeight={36}
        gridLines="Both"
        queryCellInfo={customizeCell}
        id="ContactDetails"
      >
        <ColumnsDirective>
          <ColumnDirective
            textAlign="center"
            field="primary"
            headerText="Primary"
            width="100"
          />
          <ColumnDirective width="100" field="title" headerText="Title" />
          <ColumnDirective field="firstName" headerText="First Name" />
          <ColumnDirective field="lastName" headerText="Last Name" />
          <ColumnDirective field="email" headerText="Email" />
          <ColumnDirective field="phoneType" headerText="Phone Type" />
          <ColumnDirective field="phone" headerText="Phone" />
          <ColumnDirective field="fax" headerText="Fax" />
        </ColumnsDirective>
      </GridComponent>
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import "./style/purchasing.css";
import "./style/modify-purchasing-sumary.css";
import { Button, Empty, Form, Popover, Tooltip, message, Checkbox } from "antd";
import downArrow from "../../assets/icons/common/downArrow.svg";
import upArrow from "../../assets/icons/common/upArrow.svg";
import notesColorIcon from "../../assets/icons/common/notesColor.svg";
import notesColorYellowIcon from "../../assets/icons/common/notesColorYellow.svg";
import notesIcon from "../../assets/icons/common/notes.svg";
import notesYellowIcon from "../../assets/icons/common/notesYellow.svg";

import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Modal } from "antd";
import PurchaseOrderService from "../../services/purchase.service";
import LocationService from "../../services/location.service";
import UserManagementService from "../../services/userManagement.service";
import TradingPartnerService from "../../services/trading-partner.service";
import {
  loginDetailsSelector,
  clientDetailsSelector,
} from "../../store/selectors/app.selector";
import { useSelector } from "react-redux";
import PoNotes from "./modals/po-notes";
import { poStatusClassNames, poStatusLables } from "./config/config";
import onIcon from "../../assets/icons/common/on.svg";
import offIcon from "../../assets/icons/common/off.svg";
import UserDetails from "./components/userDetails";
import PartnerDetails from "./components/partnerDetails";
import AdditionalHeaderFields from "./components/additionalHeaderFields";
import ShipDetails from "../../components/location/locationDetails";
import PoCostSummary from "./components/costSummary";
import NumberFormat from "react-number-format";
import ItemNumberDetails from "./components/itemDetails";
import AuditLogTable from "../../components/audit-logs/auditLogs";
import ErrorMsg from "../../services/error-msg";
import { convertMMDDHHMMFomat } from "../../services/date.service";

export default function PurchaseStaticView() {
  const userDetails = useSelector(loginDetailsSelector);
  const clientDetails = useSelector(clientDetailsSelector);
  const params = useParams();
  const navigate = useNavigate();
  const [arrowVisible, setArrowVisible] = useState(true);
  const [notesVisible, setNotesVisible] = useState(false);
  const [poDetails, setPoDetails] = useState({});
  const [POForm] = Form.useForm();
  const [totalItemCost, setTotalItemCost] = useState(0);
  const [totalCostCharge, setTotalCostCharge] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [attachments, setAttachments] = useState([]);
  const [poLineList, setPoLineList] = useState([]);
  const [chargeList, setChargeList] = useState([]);
  const [headerNotes, setHeaderNotes] = useState([]);
  const [isTrans, setIsTrans] = useState(false);
  const [status, setStatus] = useState("Submitted");
  const [vendorName, setVendorName] = useState(undefined);
  const [auditlogModalVisible, setAuditlogVisibleModal] = useState(false);
  const [meat, setMeat] = useState(false);
  const [visiblePatnerDetails, setVisiblePartnerDetails] = useState(false);
  const [visibleWarehouseDetails, setVisibleWarehouseDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [visibleUserDetails, setVisibleUserDetails] = useState(false);
  const [costSummaryDetails, setCostSummaryDetails] = useState({});
  const [itemDetails, setItemDetails] = useState({});
  const [visibleItemDetails, setVisibleItemDetails] = useState(false);
  const [lineLevelNotes, setLineLevelNotes] = useState([]);
  const [lineLevelNotesVisible, setLineLevelNotesVisible] = useState(false);
  const [totalWeight, setTotalWeight] = useState(0);
  const [shipFromLineList, setShipFromLineList] = useState([]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      getPoDetails();
    }
  }, []);

  const handleSetFormValues = useCallback((currentData = {}) => {
    const data = {
      buyervendor: currentData["buyerVendor"],
      routing: currentData["routingMethod"],
      vendorname: currentData["vendorId"],
      buyer: currentData["buyer"],
      vendorshipfrom: currentData["shipFrom"]?.locationId,
      shipto: currentData["shipTo"]?.locationId,
      shipDate: moment(currentData?.shipDate),
      arrivalDate: moment(currentData?.arrivalDate),
      pickupNum: currentData["pickupNum"],
    };

    currentData["attachments"].forEach((element, index) => {
      element.rowId = index + 1;
    });

    currentData["poCharges"].forEach((element) => {
      element.vendorName = element.vendorName;
      element.source = element.source || "DEFAULT";
    });

    currentData.changedArrivalDate = currentData.changedArrivalDate || "";
    currentData.changedBy = currentData?.changedBy || "";

    currentData.arrivalDateChanged =
      currentData.changedArrivalDate.length > 0
        ? !moment(currentData.arrivalDate).isSame(
            moment(currentData.changedArrivalDate)
          )
        : false;

    currentData["poLines"].forEach((element, idx) => {
      element.isChecked = false;
      element.isDelete = currentData["status"] === "Draft" ? true : false;
      element.vendorItemNumber = element.vendorItemNumber || "";
      element.active = true;
      element.ti = element["ti"] || "";
      element.hi = element["hi"] || "";
      element.advItem = element["advItem"] || "";
      element.oldTi = element["ti"] || "";
      element.oldHi = element["hi"] || "";
      element.rowId = idx;
      element.notes = element.notes || [];
      element.boldCostFg = element.boldCostFg || false;
      element.boldQuantityFg = element.boldQuantityFg || false;
      element.changedCost = element.changedCost || 0;
      element.changedQuantity = element.changedQuantity || 0;
      element.costChanged =
        element.changedCost > 0 && element.changedCost !== element.cost;
      element.qtyChanged =
        element.changedQuantity > 0 &&
        element.changedQuantity !== element.quantity;
      element.newItem = false;
      element.shipFrom = element.shipFrom
        ? element.shipFrom
        : currentData["shipFrom"];
    });
    currentData["splitFg"] = currentData["splitFg"] || false;

    currentData["nonEditableTransConfirmFg"] =
      currentData["nonEditableTransConfirmFg"] || false;

    currentData["claimNumber"] =
      (currentData["claimNumber"] && currentData["claimNumber"]?.split(",")) ||
      [];

    currentData["notes"].forEach((element) => {
      element.showMore = false;
    });

    const shipFromLineLevel = [
      ...new Map(
        currentData["shipFromList"].map((item) => [item["locationId"], item])
      ).values(),
    ];

    POForm.setFieldsValue(data);
    setShipFromLineList(shipFromLineLevel || []);
    setVendorName(currentData["vendorId"]);
    setStatus(currentData["status"]);
    setPoDetails(currentData);
    setTotalCostCharge(currentData["totalCharges"]);
    setTotalItemCost(currentData["totalFreight"]);
    setTotalCost(currentData["totalPoCost"]);
    setHeaderNotes(currentData["notes"] || []);
    setAttachments([...(currentData["attachments"] || [])]);
    setPoLineList(currentData["poLines"] || []);
    setChargeList(currentData["poCharges"] || []);
    lineLevelChargeUpdate(currentData["poLines"], currentData["poCharges"]);
    setIsTrans(currentData["transConfirm"]);
    setMeat(currentData["meatItems"] || false);
  }, []);

  const getPoDetails = useCallback(async () => {
    try {
      const response = await PurchaseOrderService.getPoDetails(params["id"]);
      if (response.status === 200) {
        response.data.forEach((element) => {
          element.poLines = element.poLines || [];
          element.poCharges = element.poCharges || [];
          element.attachments = element.attachments || [];
          element.notes = element.notes || [];
          element.shipFromList = element.shipFromList || [];
        });

        let currentData = response.data.filter(
          (ele) => ele.history === false
        )[0];

        handleSetFormValues(currentData);
      }
    } catch (err) {
      console.log(err);
      message.error(ErrorMsg.getPoDetailsFailure);
    }
  }, []);

  //Get Location details with location ID
  const getLocationDetails = useCallback(async (details) => {
    try {
      setSelectedDetails({});
      setVisibleWarehouseDetails(true);
      const response = await LocationService.getLocationDetails(details);
      if (response.status === 200) {
        setSelectedDetails(response.data);
      }
    } catch (err) {
      console.log(err);
      message.error(ErrorMsg.getLocationDetailsFailure);
    }
  }, []);

  const getUserDetails = useCallback(async (username) => {
    try {
      setVisibleUserDetails(true);
      const response = await UserManagementService.getUserDetails({
        name: username,
      });
      if (response.status === 200) {
        setSelectedDetails(response.data);
      }
    } catch (error) {
      message.error(ErrorMsg.getUserDetailsFailure);
    }
  }, []);

  //Get Partner Details with partnerID
  const getCustomerDetails = useCallback(async (vendorId) => {
    try {
      setVisiblePartnerDetails(true);
      setSelectedDetails({});
      const response = await TradingPartnerService.getDetailsByPartnerID(
        vendorId
      );
      if (response.status === 200) {
        setSelectedDetails(response.data);
      }
    } catch (err) {
      console.log();
      message.error(ErrorMsg.getDetailsByPartnerIDFailure);
    }
  }, []);

  const getTotalCostDetails = useCallback((poLineListCopy) => {
    let totalItemCost = 0;
    let totalItemCharge = 0;
    let totalWeight = 0;
    poLineListCopy.forEach((element) => {
      totalItemCost += element.randomWeight
        ? +element.quantity * element.weight * +element.cost
        : +element.quantity * +element.cost;
      // totalItemCharge += +element.quantity * element.chargeAmount;
      totalItemCharge += element.randomWeight
        ? +element.quantity * element.chargeAmount * element.weight
        : +element.quantity * element.chargeAmount;
      totalWeight += +element.quantity * element.weight;
    });
    setTotalWeight(totalWeight);
    setTotalItemCost(totalItemCost);
    setTotalCostCharge(totalItemCharge);
    setTotalCost(totalItemCost + totalItemCharge);
  }, []);

  const lineLevelChargeUpdate = useCallback(
    async (poLineListCopy, chargeListCopy) => {
      try {
        let totalQuantity = 0;
        let finalCharges = 0;
        let totalHeaderFixCharge = 0;
        let totalHeaderLineCharge = 0;

        chargeListCopy.forEach((ele) => {
          if (ele.level === "Header" && ele.basis === "Fixed") {
            totalHeaderFixCharge += +ele.amount;
          }
          if (ele.level === "Header" && ele.basis === "Per Unit") {
            totalHeaderLineCharge += +ele.amount;
          }
        });

        if (poLineListCopy?.length > 0) {
          poLineListCopy.forEach((element) => {
            totalQuantity += +element.quantity;
          });

          if (totalQuantity > 0) {
            finalCharges = parseFloat(
              (
                ((totalHeaderFixCharge / totalQuantity + Number.EPSILON) *
                  100) /
                100
              ).toFixed(4)
            );
          } else {
            finalCharges = totalHeaderFixCharge + totalHeaderLineCharge;
          }

          // Header Level Fixed
          poLineListCopy.forEach((element) => {
            element.chargeAmount = element.randomWeight
              ? parseFloat(
                  (
                    ((finalCharges / element.weight + Number.EPSILON) * 100) /
                    100
                  ).toFixed(4)
                )
              : finalCharges;
          });

          console.log(poLineListCopy);

          // Header line Level Per Unit
          chargeListCopy.forEach((charge) => {
            poLineListCopy.forEach((line) => {
              if (charge.level === "Header" && charge.basis === "Per Unit") {
                line.chargeAmount += line.randomWeight
                  ? parseFloat(
                      (
                        ((+charge.amount / line.weight + Number.EPSILON) *
                          100) /
                        100
                      ).toFixed(4)
                    )
                  : +charge.amount;
              }
            });
          });

          chargeListCopy.forEach((ele) => {
            poLineListCopy.forEach((line) => {
              if (ele.itemNumber === line.itemNumber) {
                if (ele.level === "Line" && ele.basis === "Fixed") {
                  if (+line.quantity > 0) {
                    let finalLineCharges = parseFloat(
                      (
                        (line.randomWeight
                          ? ((+ele.amount / +line.quantity + Number.EPSILON) *
                              100) /
                            line.weight
                          : (+ele.amount / +line.quantity + Number.EPSILON) *
                            100) / 100
                      ).toFixed(4)
                    );
                    line.chargeAmount += finalLineCharges;
                  } else {
                    line.chargeAmount += +ele.amount;
                  }
                }

                if (ele.level === "Line" && ele.basis === "Per Unit") {
                  line.chargeAmount += +ele.amount;
                }
              }
            });
          });
          poLineListCopy.forEach((element) => {
            element.deliveredCost = +element.cost + element.chargeAmount;
          });
        } else {
          finalCharges = totalHeaderFixCharge + totalHeaderLineCharge;
        }

        poLineListCopy.forEach((ele, idx) => {
          ele.rowId = idx;
        });

        setPoLineList([...poLineListCopy]);
        getTotalCostDetails(poLineListCopy);
        getCostSummaryDetails(poLineListCopy, chargeListCopy);
      } catch (err) {
        console.log(err);
      }
    },
    []
  );

  const getCostSummaryDetails = useCallback(
    async (poLineListCopy, chargeListCopy) => {
      try {
        let totalItemCost = 0;
        let internalChargeList = [];
        let externalChargeList = [];

        let freightList = [];
        // let totalInternalFixCharge = 0;
        // let totalInternalLineCharge = 0;
        let finalInternalCharges = 0;

        // let totalExternalFixCharge = 0;
        // let totalExternalLineCharge = 0;
        let finalExternalCharges = 0;
        let finalFreightCharges = 0;

        poLineListCopy.forEach((element) => {
          totalItemCost += element.randomWeight
            ? +element.quantity * element.weight * +element.cost
            : +element.quantity * +element.cost;
        });

        internalChargeList = chargeListCopy.filter(
          (ele) => ele.vendorVisible === false && ele.chargeCode !== "504"
        );

        finalInternalCharges = calculatePoSummaryCharges(
          internalChargeList,
          poLineListCopy
        );

        externalChargeList = chargeListCopy.filter(
          (ele) => ele.vendorVisible === true && ele.chargeCode !== "504"
        );

        finalExternalCharges = calculatePoSummaryCharges(
          externalChargeList,
          poLineListCopy
        );

        freightList = chargeListCopy.filter((ele) => ele.chargeCode === "504");

        finalFreightCharges = calculatePoSummaryCharges(
          freightList,
          poLineListCopy
        );

        setCostSummaryDetails({
          totalInternal: finalInternalCharges,
          totalExternal: finalExternalCharges,
          totalFreight: finalFreightCharges,
          totalItemCost: totalItemCost,
          total3rdCharge: finalInternalCharges + finalExternalCharges,
        });

        console.log(chargeListCopy);
      } catch (error) {}
    },
    []
  );

  const calculatePoSummaryCharges = (
    chargeListCopy = [],
    poLineListCopy = []
  ) => {
    try {
      let totalQuantity = 0;
      let finalCharges = 0;
      let totalHeaderFixCharge = 0;
      let deliveredCost = 0;
      let totalHeaderLineCharge = 0;

      poLineListCopy.forEach((element) => {
        totalQuantity += +element.quantity;
        element.perLineCharge = 0;
      });

      chargeListCopy.forEach((ele) => {
        if (ele.level === "Header" && ele.basis === "Fixed") {
          totalHeaderFixCharge += +ele.amount;
        }
        if (ele.level === "Header" && ele.basis === "Per Unit") {
          totalHeaderLineCharge += +ele.amount;
        }
      });

      if (totalQuantity > 0) {
        finalCharges = parseFloat(
          (totalHeaderFixCharge / totalQuantity).toFixed(4)
        );
        // Math.round(
        //   (totalHeaderFixCharge / totalQuantity + Number.EPSILON) * 100
        // ) / 100;

        poLineListCopy.forEach((element) => {
          element.perLineCharge += element.randomWeight
            ? parseFloat((finalCharges / element.weight).toFixed(4))
            : finalCharges;
          // ? Math.round(
          //     (finalCharges / element.weight + Number.EPSILON) * 100
          //   ) / 100
          // : finalCharges;
        });

        chargeListCopy.forEach((charge) => {
          poLineListCopy.forEach((line) => {
            if (charge.level === "Header" && charge.basis === "Per Unit") {
              line.perLineCharge += line.randomWeight
                ? parseFloat(
                    (
                      ((+charge.amount / line.weight + Number.EPSILON) * 100) /
                      100
                    ).toFixed(4)
                  )
                : +charge.amount;
            }
          });
        });

        chargeListCopy.forEach((ele) => {
          poLineListCopy.forEach((line) => {
            if (ele.itemNumber === line.itemNumber) {
              if (ele.level === "Line" && ele.basis === "Fixed") {
                if (+line.quantity > 0) {
                  let finalLineCharges = parseFloat(
                    (
                      (line.randomWeight
                        ? ((+ele.amount / +line.quantity + Number.EPSILON) *
                            100) /
                          line.weight
                        : (+ele.amount / +line.quantity + Number.EPSILON) *
                          100) / 100
                    ).toFixed(4)
                  );
                  line.perLineCharge += finalLineCharges;
                } else {
                  line.perLineCharge += +ele.amount;
                }
              }

              if (ele.level === "Line" && ele.basis === "Per Unit") {
                line.perLineCharge += +ele.amount;
              }
            }
          });
        });

        poLineListCopy.forEach((element) => {
          deliveredCost += element.randomWeight
            ? +element.quantity * element.perLineCharge * element.weight
            : +element.quantity * element.perLineCharge;
        });

        return deliveredCost;
      }
    } catch (error) {
      console.log("error in calculatePoSummaryCharges() method" + error);
      return 0;
    }
  };

  const getItemCodeDetails = useCallback(
    async (id) => {
      try {
        setVisibleItemDetails(true);
        const response = await PurchaseOrderService.fetchItemMasterByItemCode({
          itemCode: id,
          clientId:
            userDetails["clientType"] === "BUYER"
              ? userDetails["clientId"]
              : poDetails?.clientId,
        });
        if (response.status === 200) {
          console.log(response.data);
          setItemDetails(response.data);
        }
      } catch (err) {
        message.error(ErrorMsg.fetchItemMasterByItemCodeFailure);
      }
    },
    [poDetails]
  );

  const getVendorItemDetails = useCallback(
    async (id) => {
      try {
        if (Object.keys(poDetails).length === 0) {
          return;
        }
        setVisibleItemDetails(true);
        const response =
          await PurchaseOrderService.fetchVendorItemMasterByItemCode({
            itemCode: id,
            clientId: poDetails?.targetClientId,
          });
        if (response.status === 200) {
          setItemDetails(response.data);
        }
      } catch (err) {
        message.error(ErrorMsg.fetchVendorItemMasterByItemCodeFailure);
      }
    },
    [poDetails]
  );

  const printPDF = useCallback(async () => {
    try {
      const response = await PurchaseOrderService.printPDF(
        poDetails.id,
        poDetails.poNumber
      );
      if (response.status === 200) {
      }
    } catch (err) {
      message.error(ErrorMsg.printPDFFailure);
    }
  }, [poDetails]);

  const claimNumberTemplate = (
    <div>
      {poDetails?.claimNumber?.length > 0 &&
        poDetails?.claimNumber?.map((option, idx) => (
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            className="custom-value-style"
            onClick={() => {
              navigate(
                `/receiving/create-claim/view/claimView/${option?.trim()}`
              );
            }}
            key={idx}
          >
            {option}
          </span>
        ))}
    </div>
  );

  const shipFromLineTemplate = (
    <div>
      {shipFromLineList.map((option, idx) => (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          className="custom-value-style"
          onClick={() => getLocationDetails(option?.locationId)}
          // onClick={() => {
          //   navigate(
          //     `/receiving/create-claim/view/claimView/${option?.trim()}`
          //   );
          // }}
          key={idx}
        >
          {option.shortName}
        </span>
      ))}
    </div>
  );

  return (
    <>
      <div className="row ">
        <div>
          <div className="col-12">
            <h3 className="primary-title">
              {poDetails["poNumber"] || "[ Po Number ]"}
              {poDetails["splitFg"] && (
                <span style={{ color: "red" }}>[ Split Requested ]</span>
              )}
            </h3>
          </div>

          <div style={{ position: "relative", marginBottom: 45 }}>
            <h6 className="secondary-title">PO Header</h6>
            <div className="btn-group" style={{ marginTop: "-7px" }}>
              <Button
                onClick={() => setArrowVisible(!arrowVisible)}
                style={{
                  lineHeight: "initial",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                {arrowVisible ? (
                  <img src={upArrow} className="poNotesImage" />
                ) : (
                  <img src={downArrow} className="poNotesImage" />
                )}
              </Button>
              <Button
                title="Audit Logs"
                onClick={() => setAuditlogVisibleModal(true)}
              >
                A
              </Button>

              <img
                onClick={() => {
                  headerNotes.forEach((ele) => {
                    ele.showMore = false;
                  });
                  setNotesVisible(true);
                }}
                style={{ marginLeft: 15 }}
                src={
                  headerNotes.length > 0 ? notesColorYellowIcon : notesColorIcon
                }
              />

              {poDetails["status"] !== "Draft" && (
                <Button onClick={() => printPDF()}>PRINT</Button>
              )}
            </div>
          </div>
        </div>

        {arrowVisible ? (
          <Form
            form={POForm}
            name="POModifyForm"
            layout="vertical"
            requiredMark={true}
          >
            <div className="row" style={{ marginTop: 5 }}>
              <div className="row">
                <div className="POModifySummaryTabStyle1">
                  <div
                    className="row"
                    style={{ width: "100%", marginLeft: "auto" }}
                  >
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Vendor Name</label>
                        <span
                          className="custom-value-style po-hyper-link"
                          onClick={() => getCustomerDetails(vendorName)}
                        >
                          {poDetails["vendorName"] || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">
                          Buyer Vendor #
                        </label>
                        <span className="custom-value-style">
                          {poDetails["buyerVendor"] || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Ship From</label>

                        {shipFromLineList.length === 1 && (
                          <span
                            className="custom-value-style po-hyper-link"
                            onClick={() =>
                              getLocationDetails(
                                shipFromLineList[0]?.locationId
                              )
                            }
                          >
                            {shipFromLineList[0]?.shortName || "-"}
                          </span>
                        )}

                        {shipFromLineList.length > 1 && (
                          <>
                            <Popover
                              style={{ minWidth: 200 }}
                              content={shipFromLineTemplate}
                              title=""
                              trigger="click"
                            >
                              <span
                                style={{ display: "inline-block" }}
                                className="custom-value-style po-hyper-link"
                              >
                                Multiple
                              </span>
                            </Popover>
                          </>
                        )}
                        {/* <span
                          className="custom-value-style po-hyper-link"
                          onClick={() =>
                            getLocationDetails(poDetails?.shipFrom?.locationId)
                          }
                        >
                          {poDetails?.shipFrom?.shortName || "-"}
                        </span> */}
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Ship Date</label>

                        <span className={"custom-value-style"}>
                          {moment(poDetails?.shipDate).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="POModifySummaryTabStyle2">
                  <div
                    className="row"
                    style={{ width: "100%", float: "right" }}
                  >
                    <div className="col-3">
                      <div className="row">
                        <div className="col-6">
                          <span className="custom-lbl-style">Trans</span>
                          {isTrans && <img height={35} src={onIcon} />}
                          {!isTrans && <img height={35} src={offIcon} />}
                        </div>
                        <div className="col-4">
                          <span className="custom-lbl-style">Meat</span>
                          {meat && <img height={35} src={onIcon} />}
                          {!meat && <img height={35} src={offIcon} />}
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="POModifySummaryHeaderfields1">
                        <Form.Item
                          label="PO Status"
                          name="PoStatus"
                          colon={false}
                        >
                          <span
                            style={{ position: "relative", left: "-10px" }}
                            className={`po-status-label ${
                              poStatusClassNames[poDetails["status"]]
                            }`}
                          >
                            {poStatusLables[poDetails["status"]] || "-"}
                          </span>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="POModifySummaryHeaderfields1">
                        <Form.Item label="Claim #" name="claim" colon={false}>
                          {poDetails?.claimNumber?.length === 0 && (
                            <span>-</span>
                          )}

                          {poDetails?.claimNumber?.length === 1 && (
                            <span
                              style={
                                poDetails?.claimNumber?.length === 1
                                  ? {
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }
                                  : null
                              }
                              className="custom-value-style"
                              onClick={() => {
                                if (poDetails?.claimNumber?.length === 1) {
                                  navigate(
                                    `/receiving/create-claim/view/claimView/${poDetails?.claimNumber[0]}`
                                  );
                                }
                              }}
                            >
                              {poDetails?.claimNumber[0] || "-"}
                            </span>
                          )}

                          {poDetails?.claimNumber?.length > 1 && (
                            <>
                              <Popover
                                style={{ minWidth: 200 }}
                                content={claimNumberTemplate}
                                title=""
                                trigger="click"
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  Multiple
                                </span>
                              </Popover>
                            </>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <Form.Item
                          label="Appt Time"
                          name="apptDate"
                          colon={false}
                        >
                          <span>
                            {poDetails?.apptDate
                              ? convertMMDDHHMMFomat(poDetails?.apptDate)
                              : "-"}
                          </span>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="POModifySummaryTabStyle1">
                  <div
                    className="row"
                    style={{ width: "100%", marginLeft: "auto" }}
                  >
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Buyer</label>
                        {poDetails?.sourceType?.toUpperCase() === "DEMAND" && (
                          <span
                            className="custom-value-style po-hyper-link"
                            onClick={() =>
                              getUserDetails(poDetails["turnBuyer"])
                            }
                          >
                            {poDetails?.turnBuyer || "-"}
                          </span>
                        )}

                        {poDetails?.sourceType?.toUpperCase() !== "DEMAND" && (
                          <span
                            className="custom-value-style po-hyper-link"
                            onClick={() => getUserDetails(poDetails["buyer"])}
                          >
                            {poDetails?.buyer || "-"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Routing</label>
                        <span className="custom-value-style">
                          {poDetails?.routingMethod || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Ship To</label>
                        <span
                          className="custom-value-style po-hyper-link"
                          onClick={() =>
                            getLocationDetails(poDetails?.shipTo?.locationId)
                          }
                        >
                          {poDetails?.shipTo?.shortName || "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields">
                        <label className="custom-lbl-style">Arrival Date</label>
                        <Tooltip
                          placement="top"
                          mouseLeaveDelay={0}
                          color={"#800080"}
                          title={
                            poDetails?.arrivalDateChanged
                              ? () => {
                                  return (
                                    <>
                                      <span>
                                        Old Arrival Date :{" "}
                                        {poDetails["changedBy"] === "BUYER" && (
                                          <b>
                                            {moment(
                                              poDetails?.changedArrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                        {poDetails["changedBy"] ===
                                          "VENDOR" && (
                                          <b>
                                            {moment(
                                              poDetails?.arrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                        {poDetails["changedBy"] ===
                                          "CARRIER" && (
                                          <b>
                                            {moment(
                                              poDetails?.arrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        New Arrival Date :{" "}
                                        {poDetails["changedBy"] === "BUYER" && (
                                          <b>
                                            {moment(
                                              poDetails?.arrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                        {poDetails["changedBy"] ===
                                          "VENDOR" && (
                                          <b>
                                            {moment(
                                              poDetails?.changedArrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                        {poDetails["changedBy"] ===
                                          "CARRIER" && (
                                          <b>
                                            {moment(
                                              poDetails?.changedArrivalDate
                                            ).format("MM/DD/YYYY")}
                                          </b>
                                        )}
                                      </span>
                                    </>
                                  );
                                }
                              : null
                          }
                        >
                          <span
                            className={
                              poDetails?.arrivalDateChanged
                                ? "custom-value-style changed-date-style fillColor"
                                : "custom-value-style"
                            }
                          >
                            {moment(poDetails?.arrivalDate).format(
                              "MM/DD/YYYY"
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="POModifySummaryTabStyle2">
                  <div
                    className="row"
                    style={{ width: "100%", float: "right" }}
                  >
                    <div className="col-3">
                      <div className="POModifySummaryHeaderfields1">
                        <>
                          <label className="custom-lbl-style">Nat Buyer</label>
                          {poDetails?.sourceType?.toUpperCase() ===
                            "DEMAND" && (
                            <span
                              className="custom-value-style po-hyper-link"
                              onClick={() => getUserDetails(poDetails["buyer"])}
                            >
                              {poDetails?.buyer || "-"}
                            </span>
                          )}

                          {poDetails?.sourceType?.toUpperCase() !==
                            "DEMAND" && (
                            <span
                              className="custom-value-style po-hyper-link"
                              onClick={() =>
                                getUserDetails(poDetails["turnBuyer"])
                              }
                            >
                              {poDetails?.turnBuyer || "-"}
                            </span>
                          )}
                        </>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="POModifySummaryHeaderfields1">
                        <label className="custom-lbl-style">Pickup #</label>
                        <span className="custom-value-style">
                          {poDetails["pickupNum"] || "-"}
                        </span>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="POModifySummaryHeaderfields1">
                        <span className="custom-lbl-style">Load Num #</span>
                        {poDetails?.loadNum && poDetails?.loadNum.length > 0 ? (
                          <span
                            style={{
                              cursor: "pointer",
                            }}
                            className="custom-value-style po-hyper-link"
                            onClick={() => {
                              navigate(
                                `/logistics/load-manager/load-details-view/${poDetails.loadId}`
                              );
                            }}
                          >
                            {poDetails?.loadNum}
                          </span>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        ) : null}
        <div className="po-lines-section">
          <div
            style={{ position: "relative", marginBottom: 30, marginTop: 20 }}
          >
            <h6 className="secondary-title">PO Lines</h6>

            <div className="po-lines-wrapper">
              <table
                className="table table-bordered custom-tbl-style po-line-tbl"
                width={"100vw"}
              >
                <thead>
                  <tr>
                    <th width={"150px"}>Item #</th>

                    <th width={"200px"}>Item Desc</th>

                    <th width={"150px"}>Vender Item #</th>

                    <th width={"100px"}>Qty</th>
                    <th width={"120px"}>Cost</th>
                    {meat && <th width={"120px"}>Finished Cost</th>}

                    <th width={"100px"}>Weight</th>
                    <th width={"150px"}>Freight</th>
                    <th width={"100px"}>OQ</th>
                    <th width={"100px"}>SQ</th>
                    <th width={"100px"}>RQ</th>
                    <th width={"150px"}>DLVD Cost</th>

                    <th width={"150px"}>Ship From</th>

                    <th width={"80px"}>Ti </th>
                    <th width={"80px"}>Hi</th>
                    {(clientDetails["clientType"] === "BUYER" ||
                      clientDetails["clientType"] === "VENDOR") && (
                      <th width={"80px"}> ADV Item </th>
                    )}
                    <th width={"80px"}>notes</th>
                  </tr>
                </thead>
                <tbody style={{ minHeight: 250 }}>
                  {poLineList.map((option, idx) => (
                    <tr
                      key={idx}
                      className={
                        ["Part Received", "Receipt Adj", "Received"].includes(
                          poDetails["status"]
                        ) && option?.poQunatity !== option?.rcvdQty
                          ? "apply-row-color"
                          : ""
                      }
                    >
                      <td width={"150px"}>
                        <span
                          onClick={() => getItemCodeDetails(option.itemNumber)}
                          className="po-line-desc po-hyper-link"
                        >
                          {option.itemNumber}
                        </span>
                      </td>
                      <td width={"200px"}>
                        <span className="po-line-desc">
                          {option.itemDescription}
                        </span>
                      </td>

                      <td width={"150px"}>
                        {option?.vendorItemNumber !== undefined &&
                          option?.vendorItemNumber.length > 0 && (
                            <span
                              onClick={() =>
                                getVendorItemDetails(option.vendorItemNumber)
                              }
                              className="po-line-desc po-hyper-link"
                            >
                              {option.vendorItemNumber}
                            </span>
                          )}

                        {((option?.vendorItemNumber !== undefined &&
                          option.vendorItemNumber.length === 0) ||
                          option?.vendorItemNumber === undefined) && (
                          <span className="po-line-desc">N/A</span>
                        )}
                      </td>

                      <td
                        width={"100px"}
                        className={
                          option.qtyChanged ? "fillColor" : "removeColor"
                        }
                      >
                        <Tooltip
                          placement="top"
                          mouseLeaveDelay={0}
                          color={"#800080"}
                          title={
                            option.qtyChanged
                              ? () => {
                                  return (
                                    <>
                                      <span>
                                        Old Quantity :{" "}
                                        {option.changedBy === "BUYER" && (
                                          <b>{option.changedQuantity}</b>
                                        )}
                                        {option.changedBy === "VENDOR" && (
                                          <b>{option.quantity}</b>
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        New Quantity :{" "}
                                        {option.changedBy === "BUYER" && (
                                          <b>{option.quantity}</b>
                                        )}
                                        {option.changedBy === "VENDOR" && (
                                          <b>{option.changedQuantity}</b>
                                        )}
                                      </span>
                                    </>
                                  );
                                }
                              : null
                          }
                        >
                          <NumberFormat
                            className={
                              option.boldQuantityFg
                                ? "numeric-input1 boldText"
                                : "numeric-input1"
                            }
                            name="quantity"
                            value={option.quantity}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            placeholder="Qty"
                            displayType={"text"}
                          />
                        </Tooltip>
                      </td>

                      <td
                        width={"120px"}
                        className={
                          option.costChanged ? "fillColor" : "removeColor"
                        }
                      >
                        <Tooltip
                          placement="top"
                          mouseLeaveDelay={0}
                          color={"#800080"}
                          title={
                            option.costChanged
                              ? () => {
                                  return (
                                    <>
                                      <span>
                                        Old Cost :{" "}
                                        {option.costChangedBy === "BUYER" && (
                                          <b>{option.changedCost}</b>
                                        )}
                                        {option.costChangedBy === "VENDOR" && (
                                          <b>{option.cost}</b>
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        New Cost :{" "}
                                        {option.costChangedBy === "BUYER" && (
                                          <b>{option.cost}</b>
                                        )}
                                        {option.costChangedBy === "VENDOR" && (
                                          <b>{option.changedCost}</b>
                                        )}
                                      </span>
                                    </>
                                  );
                                }
                              : null
                          }
                        >
                          <NumberFormat
                            className={
                              option.boldCostFg
                                ? "numeric-input1 boldText"
                                : "numeric-input1"
                            }
                            name="cost"
                            value={option.cost}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            decimalScale={meat ? 4 : 2}
                            fixedDecimalScale={true}
                            placeholder="0.00"
                            displayType={"text"}
                          />
                        </Tooltip>
                      </td>
                      {meat && (
                        <td width={"120px"}>
                          <NumberFormat
                            name="finishedCost"
                            style={{ fontSize: "14px" }}
                            className="numeric-input1"
                            value={option.finishedCost}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            decimalScale={meat ? 4 : 2}
                            fixedDecimalScale={true}
                            placeholder="0.00"
                            displayType={"text"}
                          />
                        </td>
                      )}

                      <td width={"100px"}>
                        <NumberFormat
                          style={{ fontSize: "14px" }}
                          className="numeric-input1"
                          value={
                            parseInt(option.weight) * parseInt(option.quantity)
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          allowEmptyFormatting
                        />
                      </td>

                      <td width={"150px"}>
                        <NumberFormat
                          className="numeric-input1"
                          value={option.freightAmount}
                          displayType={"text"}
                          prefix={"$"}
                          decimalScale={meat ? 4 : 2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                        />
                      </td>
                      <td width={"100px"}>
                        <NumberFormat
                          className="numeric-input1"
                          value={option.poQunatity || 0}
                          displayType={"text"}
                          allowedDecimalSeparators={false}
                          allowEmptyFormatting
                        />
                      </td>

                      <td width={"100px"}>
                        <NumberFormat
                          className="numeric-input1"
                          value={option.qtyShipped || 0}
                          displayType={"text"}
                          allowedDecimalSeparators={false}
                          allowEmptyFormatting
                        />
                      </td>

                      <td width={"100px"}>
                        <NumberFormat
                          className="numeric-input1"
                          value={option.rcvdQty || 0}
                          displayType={"text"}
                          allowedDecimalSeparators={false}
                          allowEmptyFormatting
                        />
                      </td>

                      <td width={"150px"}>
                        <NumberFormat
                          className="numeric-input1"
                          value={option.deliveredCost || 0}
                          displayType={"text"}
                          prefix={"$"}
                          decimalScale={meat ? 4 : 2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                        />
                      </td>

                      <td width={"150px"}>
                        <span
                          className="numeric-input1 po-hyper-link"
                          onClick={() =>
                            getLocationDetails(option?.shipFrom?.locationId)
                          }
                        >
                          {option.shipFrom?.shortName}
                        </span>
                      </td>

                      <td width={"80px"}>
                        <NumberFormat
                          className="numeric-input1"
                          name="ti"
                          value={option.ti}
                          allowNegative={false}
                          allowedDecimalSeparators={false}
                          placeholder="TI"
                          displayType={"text"}
                        />
                      </td>

                      <td width={"80px"}>
                        <NumberFormat
                          className="numeric-input1"
                          name="hi"
                          value={option.hi}
                          allowNegative={false}
                          allowedDecimalSeparators={false}
                          placeholder="HI"
                          displayType={"text"}
                        />
                      </td>

                      {(clientDetails["clientType"] === "BUYER" ||
                        clientDetails["clientType"] === "VENDOR") && (
                        <td
                          width={"80px"}
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <Checkbox
                            style={{ marginTop: 7 }}
                            checked={option.advItem}
                            disabled={true}
                          />
                        </td>
                      )}

                      <td width={"65px"}>
                        <img
                          onClick={() => {
                            option.notes &&
                              option.notes.forEach((element) => {
                                element.showMore = false;
                              });
                            setLineLevelNotes(option?.notes);
                            setLineLevelNotesVisible(true);
                          }}
                          src={
                            option?.notes?.length > 0
                              ? notesYellowIcon
                              : notesIcon
                          }
                          style={{ padding: 7, cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-7">
            <div style={{ marginTop: "25px" }}>
              <div style={{ position: "relative", marginBottom: 20 }}>
                <h6 className="secondary-title">Charges</h6>
              </div>

              <table
                className="table table-bordered custom-tbl-style my-2 po-charges"
                width={"100%"}
              >
                <thead>
                  <tr>
                    <th width={"180px"}>Charge Name</th>
                    <th width={"120px"}>Charge Type</th>
                    <th width={"120px"}>Charge Basis</th>
                    <th width={"120px"}>Item Number</th>
                    <th width={"80px"}>Amount</th>
                  </tr>
                </thead>
                <tbody style={{ minHeight: 150 }}>
                  {chargeList.length === 0 && (
                    <tr>
                      <td colSpan={6}>
                        <Empty style={{ marginTop: 20, marginBottom: 20 }} />
                      </td>
                    </tr>
                  )}

                  {chargeList.length > 0 &&
                    chargeList.map((option, idx) => (
                      <tr key={idx}>
                        <td width={"180px"}>
                          <span
                            style={{ textAlign: "left" }}
                            className="po-line-desc"
                          >
                            {option.chargeName}
                          </span>
                        </td>

                        <td width={"120px"}>
                          <span className="po-line-desc">{option.level}</span>
                        </td>

                        <td width={"120px"}>
                          <span className="po-line-desc">{option.basis}</span>
                        </td>
                        <td width={"120px"}>
                          {option.level === "Header" && (
                            <span className="po-line-desc">N/A</span>
                          )}

                          {option.level !== "Header" && (
                            <span className="po-line-desc">
                              {option.itemNumber}
                            </span>
                          )}
                        </td>
                        <td width={"80px"}>
                          <NumberFormat
                            className="numeric-input po-line-desc"
                            name="amount"
                            value={option.amount}
                            allowNegative={false}
                            allowedDecimalSeparators={false}
                            decimalScale={meat ? 4 : 2}
                            fixedDecimalScale={true}
                            placeholder={meat ? "0.0000" : "0.00"}
                            displayType={"text"}
                            style={{ fontSize: 14 }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-5">
            <div className="po-attachments" style={{ marginTop: 30 }}>
              <div style={{ position: "relative" }}>
                <h6 className="secondary-title">Attachments</h6>
              </div>
              <div className="row">
                <div className="col-12">
                  <table
                    className="col-12 table table-bordered custom-tbl-style my-2"
                    width={"100%"}
                  >
                    <thead>
                      <tr>
                        <th width={"25%"}>Classification</th>
                        <th width={"42%"}>Attachment Description</th>
                        <th width={"25%"}>Attachment Link</th>
                      </tr>
                    </thead>
                    <tbody style={{ minHeight: 150 }}>
                      {attachments.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <Empty
                              style={{ marginTop: 20, marginBottom: 20 }}
                            />
                          </td>
                        </tr>
                      )}

                      {attachments?.length > 0 &&
                        attachments?.map((option, idx) => (
                          <tr key={idx}>
                            <td width={"25%"}>
                              <span
                                style={{ textAlign: "left" }}
                                className="po-line-desc"
                              >
                                {option.classification}
                              </span>
                            </td>
                            <td width={"42%"}>
                              <span className="numeric-input po-line-desc">
                                {option.description}
                              </span>
                            </td>
                            <td width={"25%"}>
                              <div
                                className="d-flex"
                                style={{ alignItems: "center", marginTop: 7 }}
                              >
                                {option.link.length > 0 && (
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() => handleViewAttachment(option)}
                                  >
                                    View Attachment
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <AdditionalHeaderFields
                  totalCost={totalCost}
                  totalCostCharge={totalCostCharge}
                  meat={meat}
                  totalItemCost={totalItemCost}
                  poDetails={poDetails}
                  poLineList={poLineList}
                  totalWeight={totalWeight}
                />
              </div>
              <div className="col-7">
                <PoCostSummary
                  totalCost={totalCost}
                  totalCostCharge={totalCostCharge}
                  totalItemCost={totalItemCost}
                  costSummaryDetails={costSummaryDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={900}
        maskClosable={false}
        title="User Notes"
        visible={notesVisible}
        footer={null}
      >
        <PoNotes
          level="HEADER"
          mode="MODIFY"
          key={new Date().getTime()}
          details={poDetails}
          setHeaderNotes={setHeaderNotes}
          headerNotes={headerNotes}
          status={"READY_ONLY"}
          notesModalClose={() => setNotesVisible(false)}
        />
      </Modal>

      <Modal
        width={900}
        maskClosable={false}
        title="User Notes"
        visible={lineLevelNotesVisible}
        footer={null}
      >
        <PoNotes
          level="HEADER"
          mode="MODIFY"
          key={new Date().getTime()}
          details={poDetails}
          setHeaderNotes={setLineLevelNotes}
          headerNotes={lineLevelNotes}
          status={"READY_ONLY"}
          notesModalClose={() => setLineLevelNotesVisible(false)}
        />
      </Modal>

      <Modal
        width={900}
        maskClosable={false}
        title="Audit Log"
        visible={auditlogModalVisible}
        footer={null}
        onCancel={() => setAuditlogVisibleModal(false)}
        className={"audit-log-section"}
      >
        <AuditLogTable
          key={new Date().getTime()}
          sourceId={poDetails["id"]}
          sourceType="Purchase Order"
          auditlogModalVisible={auditlogModalVisible}
          setAuditlogVisibleModal={() => setAuditlogVisibleModal(false)}
        />
      </Modal>

      <Modal
        width={1250}
        closable={false}
        title="Details"
        visible={visibleWarehouseDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <ShipDetails locationDetails={selectedDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisibleWarehouseDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>

      <Modal
        width={280}
        closable={false}
        title="Details"
        visible={visiblePatnerDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <PartnerDetails selectedDetails={selectedDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisiblePartnerDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>

      <Modal
        width={280}
        closable={false}
        title="Details"
        visible={visibleUserDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <UserDetails selectedDetails={selectedDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setVisibleUserDetails(false)}
          type="default"
        >
          Close
        </Button>
      </Modal>

      <Modal
        width={500}
        closable={false}
        title="Item Details"
        visible={visibleItemDetails}
        footer={null}
        className="po-more-details-body"
      >
        <div className="po-modal-more-details">
          <ItemNumberDetails itemDetails={itemDetails} />
        </div>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => {
            setVisibleItemDetails(false);
            setItemDetails({});
          }}
          type="default"
        >
          Close
        </Button>
      </Modal>
    </>
  );
}

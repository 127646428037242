import { Button, Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import UserService from "../services/user.service";
import { store } from "../store";
import { loginDetailsSelector } from "../store/selectors/app.selector";

export default function UnAuthorized() {
  const userDetails = useSelector(loginDetailsSelector);

  return (
    <>
      <Result
        status={userDetails['status'] === 'InActive' ? 404 : 403}
        title={userDetails['status'] === 'InActive' ? 404 : 403}

        subTitle={userDetails['status'] === 'InActive' ? "Sorry, you are Inactivated due to end date is passed please work with your system admin" : "Sorry, you are not authorized to access this page."}

        extra={
          <Button
            type="primary"
            onClick={async () => {
              await UserService.doLogout();
              store.dispatch({ type: "USER_LOGOUT" });
            }}
          >
            Back To Login
          </Button>
        }
      />
    </>
  );
}

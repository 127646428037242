import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import dateFormat, { masks } from "dateformat";

import "./layout.css";
import { useSelector } from "react-redux";
import {
  serviceLoaderSelector,
  unAuthorizedSelector,
} from "../store/selectors/app.selector";
import Logo from "../assets/icons/CSLogo.svg";
import ClientLogo from "../assets/icons/navbar/clientLogo.svg";

export default function StaticLayout({ children }) {
  const now = new Date();
  const navigation = useNavigate();
  const location = useLocation();

  const unAuthorized = useSelector(unAuthorizedSelector);

  useEffect(() => {
    if (unAuthorized) {
      navigation("/unAuthorized");
    } else {
      navigation(
        location.pathname.includes("/unAuthorized")
          ? "/home"
          : location.pathname
      );
    }
  }, [unAuthorized]);

  return (
    <div className="main-container">
      <div className="d-flex flex-column">
        <span className="d-flex logoImage">
          <img
            src={Logo}
            width={60}
            height={56}
            style={{ display: "block", margin: "0 auto", cursor: "pointer" }}
          />
        </span>

        <div className="d-flex arrow">

        </div>
      </div>
      <div className="top-banner"></div>
      <div className="render-wrapper">
        {/* <div className="breadcrumb-section"></div> */}
        <div className="left-section menu-expand">

          <div
            style={{ position: "fixed", left: "10px", bottom: "20px" }}
            className="clientLogo"
          >
            <img src={ClientLogo} />
          </div>
        </div>

        <div className="right-wrapper right-wrapper-expand" style={{ paddingTop: 30 }}>
          {children}
        </div>
      </div>
    </div>
  );
}

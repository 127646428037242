import React from "react";
import { notification } from "antd";
import http from "./http.service";
import config from "./constant";

const showErrorMessage = (description) => {
  return notification.error({
    message: "Error",
    description: description,
  });
};

const showSuccessMessage = (description) => {
  return notification.success({
    message: "Success",
    description: description,
  });
};

const showWarningMessage = (description) => {
  return notification.warning({
    message: "Warning",
    description: description,
  });
};
const getUserNotification = async () => {
  try {
    const response = await http.get(
      config.UserNotification.getUserNotification
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const notificationAdd = async (params) => {
  try {
    const response = await http.post(
      config.UserNotification.getUserNotification,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const NotificationService = {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
  getUserNotification,
  notificationAdd,
};

export default NotificationService;

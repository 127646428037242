import React, { Suspense } from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  hasAccessToRoute,
  loginDetailsSelector,
} from "../../store/selectors/app.selector";
import AccessDenied from "../../pages/accessDenied/AccessDenied";
import Loader from "../loader";
import Layout from "../../layout/layout";

export default function AuthRoute(props) {
  const {
    route: { link, checkRoutePermissions, component, exceptionRule },
  } = props;

  const user = useSelector(loginDetailsSelector);

  const hasPermissions = useSelector(hasAccessToRoute(checkRoutePermissions));
  const exceptionPermission = exceptionRule ? exceptionRule(user) : false;

  const RouteComponent = component;

  return (
    <Layout>
      <Suspense fallback={<Loader />}>
        {hasPermissions || exceptionPermission ? (
          <RouteComponent />
        ) : (
          <AccessDenied />
        )}
      </Suspense>
    </Layout>
  );
}

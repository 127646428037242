import http from "./http.service";
import config from "./constant";

const getLocationList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.Location.locationList, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPoLocationList = async (clientId) => {
  try {
    const response = await http.get(
      `${config.Location.locationPoList}/${clientId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getTradingLocationList = async (partnerId) => {
  try {
    const response = await http.get(
      `${config.Location.locationList}/${partnerId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postLocation = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.Location.addLocation, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLocationDetails = async (locationId) => {
  try {
    const response = await http.get(
      `${config.Location.locationDetails}${locationId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLocationDetailsWithShortName = async (shortName) => {
  try {
    const response = await http.get(
      `${config.Location.locationDetailsWithShortName}${shortName}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLocationswithFunctionType = async (type) => {
  try {
    const response = await http.get(
      `${config.Location.locationFunctionType}/${type}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const LocationService = {
  getLocationList,
  postLocation,
  getLocationDetails,
  getLocationswithFunctionType,
  getTradingLocationList,
  getPoLocationList,
  getLocationDetailsWithShortName,
};

export default LocationService;

import "./globals";
import React from "react";
import ReactDOM from "react-dom";
import "./assets/customFont.css";
import { Provider } from "react-redux";
import "./styles/color_variable.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persister, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import UserService from "./services/user.service";
import "./globals";

const renderApp = () =>
  ReactDOM.render(
    <>
      <Provider store={store}>
        <PersistGate persistor={persister}>
          <App />
        </PersistGate>
      </Provider>
    </>,
    document.getElementById("root")
  );

UserService.initKeyCloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

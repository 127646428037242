import React, { useCallback, useEffect, useState } from "react";
import "./style/load-manager.css";
import { Button, Form, Input, message, Select } from "antd";
import saveimg from "../../../assets/icons/common/save.svg";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import LoadMangerService from "../../../services/loadManager.service";
import CommonService from "../../../services/common.service";
import NumberFormat from "react-number-format";

const Option = Select.Option;

export default function AutoFreightOnLoad(props) {
  const [autoFreightOnLoadForm] = Form.useForm();
  const [segmentsByCarrier, selectSegmentsByCarrier] = useState([]);
  const [carriersAndSegments, collectCarriersAndSegments] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [selectedSegment, setSelectedSegment] = useState({});
  const [lookupData, setLookupData] = useState([]);
  const [loadTypes, setLoadTypes] = useState([]); //TODO Get All
  const [loadDetails, setLoadDetails] = useState({});
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [permileFlag, setPerMileFlag] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [saveLoaderDraft, setSaveLoaderDraft] = useState(false);
  const [submitLoaderDraft, setSubmitLoaderDraft] = useState(false);
  const allocationBasisData = [
    { id: 1, value: "Case" },
    { id: 2, value: "Cube" },
    { id: 3, value: "Weight" },
    { id: 4, value: "Pallet" },
  ];

  useEffect(() => {
    setLoadDetails({});
    getLookupDetails();
    fetchLoadTypesLOVs();
  }, []);
  useEffect(() => {
    setLoadDetails(props?.autoFreightLoad?.loadDetailsDto);
    setPurchaseOrders(props?.autoFreightLoad?.purchaseOrders);
  }, [props.autoFreightLoad]);
  useEffect(() => {
    getCarrierAndTransportSegmentsByPOIds();
  }, []);
  useEffect(() => {
    if (carriersAndSegments && carriersAndSegments.tpSegmentDefinitions) {
      let segments = carriersAndSegments?.tpSegmentDefinitions?.filter(
        (segmentDefinition) =>
          segmentDefinition?.assignedLineHauls?.findIndex(
            (lineHaul) => lineHaul?.carrier === selectedCarrier?.carrierName
          ) > -1
      );
      selectSegmentsByCarrier(segments);
    }
  }, [selectedCarrier]);

  let toFixedVal;
  useEffect(() => {
    toFixedVal = (n, fixed) =>
      ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
    autoFreightOnLoadForm.setFieldsValue({
      carrier: loadDetails?.carrierName,
      carrierId: loadDetails?.carrierId,
      carrierNameSelected: loadDetails?.carrierName,
      carrierSelected: loadDetails?.carrierId,
      segmentSelected: loadDetails?.segmentId,
      segmentNameSelected: loadDetails?.segmentName,
      carrierClientIdSelected: loadDetails?.carrierClientId,
      segment: loadDetails?.segmentName,
      segmentId: loadDetails?.segmentId,
      loadType: loadDetails?.loadType,
      lineHaulRate: parseFloat(
        loadDetails?.loadPricingDto?.lineHaulRate
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.lineHaulRate ==
      //   Math.floor(loadDetails?.loadPricingDto?.lineHaulRate)
      //   ? loadDetails?.loadPricingDto?.lineHaulRate + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.lineHaulRate, 2),
      totalLineHaulRate: parseFloat(
        loadDetails?.loadPricingDto?.totalLineHaulRate
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.totalLineHaulRate ==
      //   Math.floor(loadDetails?.loadPricingDto?.totalLineHaulRate)
      //   ? loadDetails?.loadPricingDto?.totalLineHaulRate + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.totalLineHaulRate, 2),
      freightType: loadDetails?.loadPricingDto?.freightType
        ? loadDetails?.loadPricingDto?.freightType
        : carriersAndSegments?.defaultFreight,
      miles: loadDetails?.loadPricingDto?.miles,
      pickupCharge: parseFloat(
        loadDetails?.loadPricingDto?.pickupCharge
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.pickupCharge ==
      //   Math.floor(loadDetails?.loadPricingDto?.pickupCharge)
      //   ? loadDetails?.loadPricingDto?.pickupCharge + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.pickupCharge, 2),
      deliverCharge: parseFloat(
        loadDetails?.loadPricingDto?.deliveryCharge
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.deliveryCharge ==
      //   Math.floor(loadDetails?.loadPricingDto?.deliveryCharge)
      //   ? loadDetails?.loadPricingDto?.deliveryCharge + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.deliveryCharge, 2),
      shipZone: parseFloat(loadDetails?.loadPricingDto?.shipZoneCharge).toFixed(
        2
      ),
      // loadDetails?.loadPricingDto?.shipZoneCharge ==
      //   Math.floor(loadDetails?.loadPricingDto?.shipZoneCharge)
      //   ? loadDetails?.loadPricingDto?.shipZoneCharge + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.shipZoneCharge, 2),
      feulCharge: parseFloat(loadDetails?.loadPricingDto?.fuelCharge).toFixed(
        2
      ),
      // loadDetails?.loadPricingDto?.fuelCharge ==
      //   Math.floor(loadDetails?.loadPricingDto?.fuelCharge)
      //   ? loadDetails?.loadPricingDto?.fuelCharge + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.fuelCharge, 2),
      minorFreighr: parseFloat(
        loadDetails?.loadPricingDto?.minorFreight
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.minorFreight ==
      //   Math.floor(loadDetails?.loadPricingDto?.minorFreight)
      //   ? loadDetails?.loadPricingDto?.minorFreight + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.minorFreight, 2),
      unloadingCharge: parseFloat(
        loadDetails?.loadPricingDto?.unloadingCharge
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.unloadingCharge ==
      //   Math.floor(loadDetails?.loadPricingDto?.unloadingCharge)
      //   ? loadDetails?.loadPricingDto?.unloadingCharge + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.unloadingCharge, 2),
      rebate: parseFloat(loadDetails?.loadPricingDto?.rebate).toFixed(2),
      // loadDetails?.loadPricingDto?.rebate ==
      //   Math.floor(loadDetails?.loadPricingDto?.rebate)
      //   ? loadDetails?.loadPricingDto?.rebate + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.rebate, 2),
      miscellaneous: parseFloat(
        loadDetails?.loadPricingDto?.miscellaneous
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.miscellaneous ==
      //   Math.floor(loadDetails?.loadPricingDto?.miscellaneous)
      //   ? loadDetails?.loadPricingDto?.miscellaneous + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.miscellaneous, 2),
      allocationBasis: loadDetails?.loadPricingDto?.allocationBasis
        ? loadDetails?.loadPricingDto?.allocationBasis
        : (!loadDetails?.loadPricingDto ||
          !loadDetails?.loadPricingDto?.segmentId ||
          loadDetails?.loadPricingDto?.segmentId === 0) &&
        "Case",
      totalCost: parseFloat(loadDetails?.loadPricingDto?.totalCost).toFixed(2),
      // loadDetails?.loadPricingDto?.totalCost ==
      //   Math.floor(loadDetails?.loadPricingDto?.totalCost)
      //   ? loadDetails?.loadPricingDto?.totalCost + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.totalCost, 2),
      finishedCost: parseFloat(
        loadDetails?.loadPricingDto?.finishedCost
      ).toFixed(2),
      // loadDetails?.loadPricingDto?.finishedCost ==
      //   Math.floor(loadDetails?.loadPricingDto?.finishedCost)
      //   ? loadDetails?.loadPricingDto?.finishedCost + ".00"
      //   : toFixedVal(loadDetails?.loadPricingDto?.finishedCost, 2),
      container: loadDetails?.container
        ? loadDetails?.container
        : carriersAndSegments?.containerType,
    });
  }, [carriersAndSegments]);

  useEffect(() => {
    autoFreightOnLoadForm.setFieldsValue({
      freightType: loadDetails?.loadPricingDto?.freightType,
    });
  }, [selectedSegment]);
  const getSegmentDetails = useCallback(async (segInfo) => {
    if (segInfo) {
      let segDetails = JSON.parse(segInfo);
      autoFreightOnLoadForm.setFieldsValue({
        segmentSelected: segDetails?.segmentId,
      });
      autoFreightOnLoadForm.setFieldsValue({
        segmentNameSelected: segDetails?.segmentName,
      });
    }
    const poIds = props?.autoFreightLoad?.purchaseOrders.map(
      (purchaseOrder) => purchaseOrder.id
    );
    const segmentDetailsRequestDTO = {
      poIds: poIds ? poIds : 0,
      carrierName: autoFreightOnLoadForm.getFieldValue("carrierNameSelected")
        ? autoFreightOnLoadForm.getFieldValue("carrierNameSelected")
        : loadDetails.carrierName, // TODO ID is available get carrier name from selected carrier object
      carrierId: autoFreightOnLoadForm.getFieldValue("carrierSelected")
        ? autoFreightOnLoadForm.getFieldValue("carrierSelected")
        : loadDetails.carrierId,
      carrierClientId: selectedCarrier.carrierClientId
        ? selectedCarrier.carrierClientId
        : 0,
      loadType: autoFreightOnLoadForm.getFieldValue("loadType"),
      container: autoFreightOnLoadForm.getFieldValue("container"),
      cases: calculateCount("cases"),
      cubes: calculateCount("cubes"),
      pallets: calculateCount("pallets"),
      weight: calculateCount("weight"),
      miles: autoFreightOnLoadForm.getFieldValue("miles"),
      segmentId: autoFreightOnLoadForm.getFieldValue("segmentSelected"),
      segmentName: autoFreightOnLoadForm.getFieldValue("segmentNameSelected"),
      lineHaulRate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
          ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      totalLineHaulRate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
          ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      freightType: autoFreightOnLoadForm.getFieldValue("freightType"),
      pickupCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("pickupCharge")
          ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      deliveryCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("deliverCharge")
          ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      shipZoneCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("shipZone")
          ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      fuelCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("feulCharge")
          ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      minorFreight: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("minorFreighr")
          ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      unloadingCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
          ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      rebate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("rebate")
          ? autoFreightOnLoadForm.getFieldValue("rebate") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      miscellaneous: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("miscellaneous")
          ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      totalCost: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("totalCost")
          ? autoFreightOnLoadForm.getFieldValue("totalCost") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      finishedCost: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("finishedCost")
          ? autoFreightOnLoadForm.getFieldValue("finishedCost") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      allocationBasis: autoFreightOnLoadForm.getFieldValue("allocationBasis"),
    };
    try {
      const response = await LoadMangerService.segmentDetailsOnSegmentId(
        segmentDetailsRequestDTO
      );
      if (response.status === 200) {
        setSelectedSegment(response.data);
        const autoFreightDTO = response.data;
        autoFreightOnLoadForm.setFieldsValue({
          pickupCharge: autoFreightDTO.pickupCharge,
          deliverCharge: autoFreightDTO.deliveryCharge,
          shipZone: autoFreightDTO.shipZoneCharge,
          feulCharge: autoFreightDTO.fuelCharge,
          minorFreighr: autoFreightDTO.minorFreight,
          unloadingCharge: autoFreightDTO.unloadingCharge,
          rebate: autoFreightDTO.rebate,
          miscellaneous: autoFreightDTO.miscellaneous,
          totalCost: autoFreightDTO.totalCost,
          finishedCost: autoFreightDTO.finishedCost,
          lineHaulRate: autoFreightDTO.lineHaulRate,
          totalLineHaulRate: autoFreightDTO.totalLineHaulRate,
          allocationBasis: autoFreightDTO.allocationBasis,
          freightType: autoFreightDTO.freightType,
        });
      } else {
        message.success("Get Segment is failed!");
      }
    } catch (err) {
      console.log(err?.data?.errors[0]);
      autoFreightOnLoadForm.setFieldsValue({
        pickupCharge: 0,
        deliverCharge: 0,
        shipZone: 0,
        feulCharge: 0,
        minorFreighr: 0,
        unloadingCharge: 0,
        rebate: 0,
        miscellaneous: 0,
        totalCost: 0,
        finishedCost: 0,
        lineHaulRate: 0,
        totalLineHaulRate: 0,
      });
    }
  }, []);
  const getCarrierAndTransportSegmentsByPOIds = useCallback(async () => {
    const poIds = props?.autoFreightLoad?.purchaseOrders.map(
      (purchaseOrder) => purchaseOrder.id
    );
    if (poIds.length > 0) {
      const response =
        await LoadMangerService.getCarrierAndTransportSegmentsByPOIds(poIds);
      if (response.status === 200) {
        collectCarriersAndSegments(response.data);
      }
    }
  }, [purchaseOrders]);

  const getLookupDetails = useCallback(async () => {
    try {
      const response = await CommonService.getLookupDetails({});
      if (response.status === 200) {
        setLookupData(response.data);
      }
    } catch (err) { }
  }, []);

  const fetchLoadTypesLOVs = useCallback(async () => {
    try {
      const response = await LoadMangerService.fetchLoadTypesLOVs({});
      if (response.status === 200) {
        setLoadTypes(response.data || []);
      }
    } catch (err) { }
  }, []);

  const selectCarrier = (carrInfo) => {
    let cDetails = JSON.parse(carrInfo);
    autoFreightOnLoadForm.setFieldsValue({
      carrierSelected: cDetails?.carrierId,
    });
    autoFreightOnLoadForm.setFieldsValue({
      carrierNameSelected: cDetails?.carrierName,
    });
    autoFreightOnLoadForm.setFieldsValue({
      carrierClientIdSelected: cDetails?.carrierClientId,
    });
    autoFreightOnLoadForm.setFieldsValue({ segment: "" });
    setSelectedCarrier(
      carriersAndSegments?.carrierInfo?.find(
        (carrier) => carrier.carrierId === cDetails?.carrierId
      )
    );
  };

  const onFreightTypeChange = (selectedFreightType) => {
    if (selectedFreightType === "Per Mile") {
      setPerMileFlag(true);
      autoFreightOnLoadForm.setFieldsValue({
        allocationBasis: carriersAndSegments?.defaultAllocation
          ? carriersAndSegments?.defaultAllocation
          : "Case",
      });
    } else if (selectedFreightType === "Case") {
      autoFreightOnLoadForm.setFieldsValue({ allocationBasis: "Case" });
      setPerMileFlag(false);
    } else if (selectedFreightType === "Cube") {
      autoFreightOnLoadForm.setFieldsValue({ allocationBasis: "Cube" });
      setPerMileFlag(false);
      // } else if (selectedFreightType === "Cube") {
      //   autoFreightOnLoadForm.setFieldsValue({ allocationBasis: "Cube" });
      //   setPerMileFlag(false);
    } else if (selectedFreightType === "Weight") {
      autoFreightOnLoadForm.setFieldsValue({ allocationBasis: "Weight" });
      setPerMileFlag(false);
    } else if (selectedFreightType === "Pallet") {
      autoFreightOnLoadForm.setFieldsValue({ allocationBasis: "Pallet" });
      setPerMileFlag(false);
    } else if (selectedFreightType === "Flat Rate") {
      setPerMileFlag(false);
      autoFreightOnLoadForm.setFieldsValue({
        allocationBasis: carriersAndSegments?.defaultAllocation
          ? carriersAndSegments?.defaultAllocation
          : "Case",
      });
    } else {
      setPerMileFlag(false);
      autoFreightOnLoadForm.setFieldsValue({
        allocationBasis: carriersAndSegments?.defaultAllocation
          ? carriersAndSegments?.defaultAllocation
          : "Case",
      });
    }
    autoFreight();
  };

  const selectSegment = (segInfo) => {
    let segDetails = JSON.parse(segInfo);
    autoFreightOnLoadForm.setFieldsValue({
      segmentSelected: segDetails?.segmentId,
    });
    autoFreightOnLoadForm.setFieldsValue({
      segmentNameSelected: segDetails?.segmentName,
    });
    if (parseInt(segDetails?.segmentId) === 0) {
      setSelectedSegment(0);
    } else {
      setSelectedSegment(
        carriersAndSegments?.tpSegmentDefinitions?.find(
          (segmentDefinition) =>
            segmentDefinition.segmentId === segDetails?.segmentId
        )
      );
    }
  };

  const selectAccessorial = (accessorialId) => {
    selectAccessorial(
      selectedSegment?.segmentAccesorials?.find(
        (accessorial) => accessorial.accessorialId === accessorialId
      )
    );
  };

  const calculateCount = (type) => {
    let count = 0;
    switch (type) {
      case "cases":
        count = loadDetails?.cases;
        break;
      case "pallets":
        count = loadDetails?.pallets;
        break;
      case "cubes":
        count = loadDetails?.cubes;
        break;
      case "weight":
        count = loadDetails?.weight;
        break;
    }

    return count;
  };

  const autoFreight = useCallback(async () => {
    const autoFreightDTO = {
      poIds: props?.autoFreightLoad?.purchaseOrders?.map(
        (purchaseOrder) => purchaseOrder.id
      ),
      carrierClientId: selectedCarrier?.carrierClientId
        ? selectedCarrier?.carrierClientId
        : 0,
      loadType: autoFreightOnLoadForm.getFieldValue("loadType"),
      cases: calculateCount("cases"),
      cubes: calculateCount("cubes"),
      pallets: calculateCount("pallets"),
      weight: calculateCount("weight"),
      miles: autoFreightOnLoadForm.getFieldValue("miles"),
      carrierId: autoFreightOnLoadForm.getFieldValue("carrierSelected")
        ? autoFreightOnLoadForm.getFieldValue("carrierSelected")
        : loadDetails.carrierId,
      segmentId: autoFreightOnLoadForm.getFieldValue("segmentSelected")
        ? autoFreightOnLoadForm.getFieldValue("segmentSelected")
        : loadDetails.segmentId,
      lineHaulRate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
          ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      totalLineHaulRate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
          ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      freightType: autoFreightOnLoadForm.getFieldValue("freightType"),
      pickupCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("pickupCharge")
          ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      deliveryCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("deliverCharge")
          ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      shipZoneCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("shipZone")
          ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      fuelCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("feulCharge")
          ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      minorFreight: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("minorFreighr")
          ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      unloadingCharge: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
          ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      rebate: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("rebate")
          ? autoFreightOnLoadForm.getFieldValue("rebate") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      miscellaneous: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("miscellaneous")
          ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
          : ""
        )?.replace(/,/g, "")
      ),
      totalCost: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("totalCost")
          ? autoFreightOnLoadForm.getFieldValue("totalCost") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      finishedCost: parseFloat(
        (autoFreightOnLoadForm.getFieldValue("finishedCost")
          ? autoFreightOnLoadForm.getFieldValue("finishedCost") + ".00"
          : "0.00"
        )?.replace(/,/g, "")
      ),
      allocationBasis: autoFreightOnLoadForm.getFieldValue("allocationBasis"),
      loadId: loadDetails?.loadId,
      loadNumber: loadDetails?.loadNumber,
    };
    const response = await LoadMangerService.loadAutoFreight(autoFreightDTO);
    if (response.status === 200) {
      const autoFreightDTO = response.data;
      autoFreightOnLoadForm.setFieldsValue({
        lineHaulRate:
          autoFreightDTO.lineHaulRate === 0 ? "" : autoFreightDTO.lineHaulRate,
        totalLineHaulRate: autoFreightDTO.totalLineHaulRate,
        pickupCharge:
          autoFreightDTO.pickupCharge === 0 ? "" : autoFreightDTO.pickupCharge,
        deliverCharge:
          autoFreightDTO.deliveryCharge === 0
            ? ""
            : autoFreightDTO.deliveryCharge,
        shipZone:
          autoFreightDTO.shipZoneCharge === 0
            ? ""
            : autoFreightDTO.shipZoneCharge,
        feulCharge:
          autoFreightDTO.fuelCharge === 0 ? "" : autoFreightDTO.fuelCharge,
        minorFreighr:
          autoFreightDTO.minorFreight === 0 ? "" : autoFreightDTO.minorFreight,
        unloadingCharge:
          autoFreightDTO.unloadingCharge === 0
            ? ""
            : autoFreightDTO.unloadingCharge,
        rebate: autoFreightDTO.rebate === 0 ? "" : autoFreightDTO.rebate,
        miscellaneous:
          autoFreightDTO.miscellaneous === 0
            ? ""
            : autoFreightDTO.miscellaneous,
        totalCost: autoFreightDTO.totalCost,
        finishedCost: autoFreightDTO.finishedCost,
        freightType: autoFreightDTO.freightType,
        allocationBasis: autoFreightDTO.allocationBasis,
      });
    } else {
      message.error("Auto Freight is failed!");
    }
  }, [selectedCarrier, selectedSegment]);
  const handleSubmitDraft = useCallback(async (values) => {
    try {
      setSubmitLoaderDraft(true);
      setTimeout(async () => {
        if (
          autoFreightOnLoadForm.getFieldValue("carrier") === undefined ||
          autoFreightOnLoadForm.getFieldValue("carrier") === "" ||
          autoFreightOnLoadForm.getFieldValue("lineHaulRate") === undefined ||
          autoFreightOnLoadForm.getFieldValue("lineHaulRate") === 0 ||
          autoFreightOnLoadForm.getFieldValue("lineHaulRate") === "" ||
          autoFreightOnLoadForm.getFieldValue("lineHaulRate") === "0" ||
          autoFreightOnLoadForm.getFieldValue("lineHaulRate") === "0.00"
        ) {
          message.error("Carrier and Line Haul Rate Required!");
          setSubmitLoaderDraft(false);
        } else {
          let lineHaulRateVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
              ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let pickupChargeVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("pickupCharge")
              ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let deliveryChargeVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("deliverCharge")
              ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let shipZoneChargeVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("shipZone")
              ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let fuelChargeVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("feulCharge")
              ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let minorFreightVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("minorFreighr")
              ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let unloadingChargeVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
              ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let miscellaneousVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("miscellaneous")
              ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          let totalLineHaulRateVal = parseFloat(
            (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
              ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") + ".00"
              : "0.00"
            )?.replace(/,/g, "")
          );
          const poNos = props?.autoFreightLoad?.purchaseOrders?.map(
            (purchaseOrder) => purchaseOrder.poNumber
          );
          const poIds = props?.autoFreightLoad?.purchaseOrders?.map(
            (purchaseOrder) => purchaseOrder.id && purchaseOrder.id
          );
          const loadDetailsDto = {
            loadStatus: "Tender",
            poNumbers: poNos ? poNos : [],
            poIds: poIds ? poIds : [],
            loadId: loadDetails?.loadId,
            loadNumber: loadDetails?.loadNumber,
            carrierClientId: autoFreightOnLoadForm.getFieldValue(
              "carrierClientIdSelected"
            ),
            carrierName:
              autoFreightOnLoadForm.getFieldValue("carrier") === undefined
                ? ""
                : autoFreightOnLoadForm.getFieldValue("carrierNameSelected"),
            carrierId:
              autoFreightOnLoadForm.getFieldValue("carrier") === undefined
                ? ""
                : autoFreightOnLoadForm.getFieldValue("carrierSelected"),
            segmentId:
              autoFreightOnLoadForm.getFieldValue("segment") === undefined
                ? ""
                : autoFreightOnLoadForm.getFieldValue("segmentSelected"),
            segmentName:
              autoFreightOnLoadForm.getFieldValue("segment") === undefined
                ? ""
                : autoFreightOnLoadForm.getFieldValue("segmentNameSelected"),
            loadPricingDto: {
              lineHaulRate: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              totalLineHaulRate: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                  ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") +
                  ".00"
                  : "0.00"
                )?.replace(/,/g, "")
              ),
              pickupCharge: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("pickupCharge")
                  ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              deliveryCharge: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("deliverCharge")
                  ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              shipZoneCharge: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("shipZone")
                  ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              fuelCharge: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("feulCharge")
                  ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              minorFreight: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("minorFreighr")
                  ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              unloadingCharge: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
                  ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") +
                  ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              rebate: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("rebate")
                  ? autoFreightOnLoadForm.getFieldValue("rebate") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              miscellaneous: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("miscellaneous")
                  ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
                  : ""
                )?.replace(/,/g, "")
              ),
              freightType:
                autoFreightOnLoadForm.getFieldValue("freightType") || 0,
              status: "SUBMITTED",
              allocationBasis:
                autoFreightOnLoadForm.getFieldValue("allocationBasis") || "",
              systemAllocFreight: 0,
              freightCost: 0,
              loadId: 0,
              purchaseOrderId: 0,
              finishedCost: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("finishedCost")
                  ? autoFreightOnLoadForm.getFieldValue("finishedCost") + ".00"
                  : "0.00"
                )?.replace(/,/g, "")
              ),
              totalCost: parseFloat(
                (autoFreightOnLoadForm.getFieldValue("totalCost")
                  ? autoFreightOnLoadForm.getFieldValue("totalCost") + ".00"
                  : "0.00"
                )?.replace(/,/g, "")
              ),
            },
            cases: calculateCount("cases"),
            cubes: calculateCount("cubes"),
            pallets: calculateCount("pallets"),
            weight: calculateCount("weight"),
            miles: autoFreightOnLoadForm.getFieldValue("miles") || 0,
            loadType: autoFreightOnLoadForm.getFieldValue("loadType") || "",
            container: autoFreightOnLoadForm.getFieldValue("container") || "",
            notes: [],
          };
          const response = await LoadMangerService.addLoad(loadDetailsDto);
          if (response.status === 200) {
            setSubmitLoaderDraft(false);
            message.success("Success!");
            props.autoFreightOnLoadClose();
            props.setAutoFreightLoadSuccessAccess(true);
          } else {
            setSubmitLoaderDraft(false);
            message.error("Record update failed!");
            props.autoFreightOnLoadClose();
          }
        }
      }, "1000");
    } catch (error) {
      setSubmitLoaderDraft(false);
    }
  });
  const handleSaveDraft = useCallback(async (values) => {
    try {
      setSaveLoaderDraft(true);
      setTimeout(async () => {
        let lineHaulRateVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
            ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let pickupChargeVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("pickupCharge")
            ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let deliveryChargeVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("deliverCharge")
            ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let shipZoneChargeVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("shipZone")
            ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let fuelChargeVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("feulCharge")
            ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let minorFreightVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("minorFreighr")
            ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let unloadingChargeVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
            ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let miscellaneousVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("miscellaneous")
            ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        let totalLineHaulRateVal = parseFloat(
          (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
            ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") + ".00"
            : "0.00"
          )?.replace(/,/g, "")
        );
        const poNos = props?.autoFreightLoad?.purchaseOrders?.map(
          (purchaseOrder) => purchaseOrder.poNumber
        );
        const poIds = props?.autoFreightLoad?.purchaseOrders?.map(
          (purchaseOrder) => purchaseOrder.id && purchaseOrder.id
        );
        const loadDetailsDto = {
          loadStatus: "Draft",
          poNumbers: poNos ? poNos : [],
          poIds: poIds ? poIds : [],
          loadId: loadDetails?.loadId,
          loadNumber: loadDetails?.loadNumber,
          carrierName:
            autoFreightOnLoadForm.getFieldValue("carrier") === undefined
              ? ""
              : autoFreightOnLoadForm.getFieldValue("carrierNameSelected"),
          carrierId:
            autoFreightOnLoadForm.getFieldValue("carrier") === undefined
              ? ""
              : autoFreightOnLoadForm.getFieldValue("carrierSelected"),
          segmentId:
            autoFreightOnLoadForm.getFieldValue("segment") === undefined
              ? ""
              : autoFreightOnLoadForm.getFieldValue("segmentSelected"),
          segmentName:
            autoFreightOnLoadForm.getFieldValue("segment") === undefined
              ? ""
              : autoFreightOnLoadForm.getFieldValue("segmentNameSelected"),
          carrierClientId: selectedCarrier?.carrierClientId
            ? selectedCarrier?.carrierClientId
            : 0,
          carrierClientId: autoFreightOnLoadForm.getFieldValue(
            "carrierClientIdSelected"
          ),
          loadPricingDto: {
            lineHaulRate: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
                ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            totalLineHaulRate: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") +
                ".00"
                : "0.00"
              )?.replace(/,/g, "")
            ),
            freightType: autoFreightOnLoadForm.getFieldValue("freightType"),
            pickupCharge: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("pickupCharge")
                ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            deliveryCharge: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("deliverCharge")
                ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            shipZoneCharge: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("shipZone")
                ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            fuelCharge: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("feulCharge")
                ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            minorFreight: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("minorFreighr")
                ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            unloadingCharge: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
                ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            rebate: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("rebate")
                ? autoFreightOnLoadForm.getFieldValue("rebate") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            miscellaneous: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("miscellaneous")
                ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
                : ""
              )?.replace(/,/g, "")
            ),
            status: "SUBMITTED",
            allocationBasis:
              autoFreightOnLoadForm.getFieldValue("allocationBasis") || "",
            systemAllocFreight: 0,
            freightCost: 0,
            loadId: 0,
            purchaseOrderId: 0,
            finishedCost: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("finishedCost")
                ? autoFreightOnLoadForm.getFieldValue("finishedCost") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            ),
            totalCost: parseFloat(
              (autoFreightOnLoadForm.getFieldValue("totalCost")
                ? autoFreightOnLoadForm.getFieldValue("totalCost") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            ),
          },
          cases: calculateCount("cases"),
          cubes: calculateCount("cubes"),
          pallets: calculateCount("pallets"),
          weight: calculateCount("weight"),
          miles: autoFreightOnLoadForm.getFieldValue("miles") || 0,
          loadType: autoFreightOnLoadForm.getFieldValue("loadType") || "",
          container: autoFreightOnLoadForm.getFieldValue("container") || "",
          notes: [],
        };
        const response = await LoadMangerService.addLoad(loadDetailsDto);
        if (response.status === 200) {
          setSaveLoaderDraft(false);
          message.success("Success!");
          props.autoFreightOnLoadClose();
          props.setAutoFreightLoadSuccessAccess(true);
        } else {
          setSaveLoaderDraft(false);
          message.error("Record update failed!");
          props.autoFreightOnLoadClose();
        }
      }, "1000");
    } catch (error) {
      setSaveLoaderDraft(false);
    }
  });
  const saveAutoFreightOnLoad = useCallback(
    async (values) => {
      try {
        setSubmitLoader(true);
        setTimeout(async () => {
          if (
            autoFreightOnLoadForm.getFieldValue("carrier") === undefined ||
            autoFreightOnLoadForm.getFieldValue("carrier") === ""
          ) {
            message.error("Carrier required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("lineHaulRate") === undefined
          ) {
            message.error("Line Haul Rate required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("lineHaulRate") === "0"
          ) {
            message.error("Line Haul Rate required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("lineHaulRate") === "0.00"
          ) {
            message.error("Line Haul Rate required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("lineHaulRate") === 0
          ) {
            message.error("Line Haul Rate required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("lineHaulRate") === ""
          ) {
            message.error("Line Haul Rate required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("freightType") === undefined
          ) {
            message.error("Freight Type required");
            setSubmitLoader(false);
          } else if (
            autoFreightOnLoadForm.getFieldValue("allocationBasis") === undefined
          ) {
            message.error("Allocation Basis required");
            setSubmitLoader(false);
          } else {
            let lineHaulRateVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
                ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let pickupChargeVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("pickupCharge")
                ? autoFreightOnLoadForm.getFieldValue("pickupCharge") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let deliveryChargeVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("deliverCharge")
                ? autoFreightOnLoadForm.getFieldValue("deliverCharge") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let shipZoneChargeVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("shipZone")
                ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let fuelChargeVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("feulCharge")
                ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let minorFreightVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("minorFreighr")
                ? autoFreightOnLoadForm.getFieldValue("minorFreighr") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let unloadingChargeVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
                ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let miscellaneousVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("miscellaneous")
                ? autoFreightOnLoadForm.getFieldValue("miscellaneous") + ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            let totalLineHaulRateVal = parseFloat(
              (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") +
                ".00"
                : "0.00"
              )?.replace(/,/g, "")
            );
            const loadDetailsDto = {
              carrierName:
                autoFreightOnLoadForm.getFieldValue("carrier") === undefined
                  ? ""
                  : autoFreightOnLoadForm.getFieldValue("carrierNameSelected"),
              carrierId:
                autoFreightOnLoadForm.getFieldValue("carrier") === undefined
                  ? ""
                  : autoFreightOnLoadForm.getFieldValue("carrierSelected"),
              segmentId:
                autoFreightOnLoadForm.getFieldValue("segment") === undefined
                  ? ""
                  : autoFreightOnLoadForm.getFieldValue("segmentSelected"),
              segmentName:
                autoFreightOnLoadForm.getFieldValue("segment") === undefined
                  ? ""
                  : autoFreightOnLoadForm.getFieldValue("segmentNameSelected"),
              loadType: autoFreightOnLoadForm.getFieldValue("loadType"),
              container: autoFreightOnLoadForm.getFieldValue("container"),
              loadId: loadDetails?.loadId,
              carrierClientId: autoFreightOnLoadForm.getFieldValue(
                "carrierClientIdSelected"
              ),
              loadPricingDto: {
                lineHaulRate: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("lineHaulRate")
                    ? autoFreightOnLoadForm.getFieldValue("lineHaulRate") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                totalLineHaulRate: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("totalLineHaulRate")
                    ? autoFreightOnLoadForm.getFieldValue("totalLineHaulRate") +
                    ".00"
                    : "0.00"
                  )?.replace(/,/g, "")
                ),
                freightType: autoFreightOnLoadForm.getFieldValue("freightType"),
                pickupCharge: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("pickupCharge")
                    ? autoFreightOnLoadForm.getFieldValue("pickupCharge") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                deliveryCharge: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("deliverCharge")
                    ? autoFreightOnLoadForm.getFieldValue("deliverCharge") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                shipZoneCharge: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("shipZone")
                    ? autoFreightOnLoadForm.getFieldValue("shipZone") + ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                fuelCharge: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("feulCharge")
                    ? autoFreightOnLoadForm.getFieldValue("feulCharge") + ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                minorFreight: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("minorFreighr")
                    ? autoFreightOnLoadForm.getFieldValue("minorFreighr") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                unloadingCharge: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("unloadingCharge")
                    ? autoFreightOnLoadForm.getFieldValue("unloadingCharge") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                rebate: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("rebate")
                    ? autoFreightOnLoadForm.getFieldValue("rebate") + ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                miscellaneous: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("miscellaneous")
                    ? autoFreightOnLoadForm.getFieldValue("miscellaneous") +
                    ".00"
                    : ""
                  )?.replace(/,/g, "")
                ),
                allocationBasis:
                  autoFreightOnLoadForm.getFieldValue("allocationBasis"),
                freightCost: loadDetails?.loadDetailsDto?.freightCost,
                id: loadDetails?.loadDetailsDto?.id,
                loadId: loadDetails?.loadDetailsDto?.loadId,
                finishedCost: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("finishedCost")
                    ? autoFreightOnLoadForm.getFieldValue("finishedCost") +
                    ".00"
                    : "0.00"
                  )?.replace(/,/g, "")
                ),
                totalCost: parseFloat(
                  (autoFreightOnLoadForm.getFieldValue("totalCost")
                    ? autoFreightOnLoadForm.getFieldValue("totalCost") + ".00"
                    : "0.00"
                  )?.replace(/,/g, "")
                ),
              },
            };
            const response = await LoadMangerService.autoFreightOnLoad(
              loadDetailsDto
            );
            if (response.status === 200) {
              setSubmitLoader(false);
              message.success("Success!");
              props.autoFreightOnLoadClose();
              props.setAutoFreightLoadSuccessAccess(true);
            } else {
              setSubmitLoader(false);
              message.error("Error");
              props.autoFreightOnLoadClose();
            }
          }
        }, "1000");
      } catch (error) {
        setSubmitLoader(false);
      }
    },
    [loadDetails]
  );

  const resetFields = () => {
    autoFreightOnLoadForm.setFieldsValue({
      carrier: "",
      segment: "",
      lineHaulRate: "",
      pickupCharge: "",
      deliverCharge: "",
      shipZone: "",
      feulCharge: "",
      minorFreighr: "",
      unloadingCharge: "",
      rebate: "",
      miscellaneous: "",
      totalLineHaulRate: "",
      totalCost: 0,
      finishedCost: 0,
      carrierNameSelected: "",
    });
  };
  const pickUpClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ pickupCharge: "" });
    }
  };
  const lineHualClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ lineHaulRate: "" });
    }
  };
  const deliveryClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ deliverCharge: "" });
    }
  };
  const shipzneClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ shipZone: "" });
    }
  };
  const fualChargeClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ feulCharge: "" });
    }
  };
  const minorFreightClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ minorFreighr: "" });
    }
  };
  const unLoadClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ unloadingCharge: "" });
    }
  };
  const rebateClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ rebate: "" });
    }
  };
  const miscClick = (e) => {
    if (e.target.value === "0") {
      autoFreightOnLoadForm.setFieldsValue({ miscellaneous: "" });
    }
  };
  return (
    <>
      <Form
        name="addSystemOption"
        layout="vertical"
        requiredMark={true}
        form={autoFreightOnLoadForm}
      >
        <div className="row">
          <div className="col-12">
            <h3
              className="primary-title"
              style={{ fontSize: "17px", width: "97%" }}
            >
              {loadDetails && (
                <label style={{ width: "40%" }}>
                  {loadDetails?.loadNumber}
                </label>
              )}
              <label className="newLaodHeaderLabelWidth">
                <label>Case:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("cases")}
                >
                  <NumberFormat
                    value={calculateCount("cases")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Pallets:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("pallets")}
                >
                  <NumberFormat
                    value={calculateCount("pallets")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Cubes:</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("cubes")}
                >
                  <NumberFormat
                    value={calculateCount("cubes")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
              <label className="newLaodHeaderLabelWidth">
                <label>Weight(lb):</label>
                <label
                  className="cubesCountLabel"
                  title={calculateCount("weight")}
                >
                  <NumberFormat
                    value={calculateCount("weight")}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                  />
                </label>
              </label>
            </h3>
          </div>
          <div style={{ width: "20%", marginTop: "3.8rem" }}>
            <div className="logisticLMHeaderfields">
              <Form.Item label="Carrier" name="carrier" colon={false}>
                <Select
                  placeholder="Choose Option"
                  onSelect={selectCarrier}
                  allowClear
                  disabled={
                    [
                      "Tender",
                      "Draft",
                      "Submitted",
                      "Confirmed",
                      "In Transit",
                    ].indexOf(loadDetails?.loadStatus) < 0
                  }
                >
                  {carriersAndSegments &&
                    carriersAndSegments.carrierInfo &&
                    carriersAndSegments.carrierInfo.length > 0 &&
                    carriersAndSegments.carrierInfo.map((carrier, index) => (
                      <Option key={index} value={JSON.stringify(carrier)}>
                        {carrier.carrierName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="logisticLMHeaderfields">
              <Form.Item label="Segment" name="segment" colon={false}>
                <Select
                  id="ddlSegment"
                  placeholder="Choose Option"
                  onSelect={selectSegment}
                  onChange={getSegmentDetails}
                  allowClear
                  disabled={
                    [
                      "Tender",
                      "Draft",
                      "Submitted",
                      "Confirmed",
                      "In Transit",
                    ].indexOf(loadDetails?.loadStatus) < 0
                  }
                >
                  <Option value="0">Choose None</Option>
                  {carriersAndSegments &&
                    carriersAndSegments?.tpSegmentDefinitions?.length > 0 &&
                    carriersAndSegments?.tpSegmentDefinitions?.map((segmentDefintion, index) => (
                      <Option
                        key={index}
                        value={JSON.stringify(segmentDefintion)}
                      >
                        {segmentDefintion.segmentName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="logisticLMHeaderfields">
              <Form.Item label="Load Type" name="loadType" colon={false}>
                <Select
                  placeholder="Choose Option"
                  disabled={
                    [
                      "Tender",
                      "Draft",
                      "Submitted",
                      "Confirmed",
                      "In Transit",
                    ].indexOf(loadDetails?.loadStatus) < 0
                  }
                >
                  {loadTypes.map((loadType, index) => (
                    <Option key={index} value={loadType}>
                      {loadType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="logisticLMHeaderfields">
              <Form.Item label="Container" name="container" colon={false}>
                <Select
                  placeholder="Choose Option"
                  tabIndex={-1}
                  disabled={
                    [
                      "Tender",
                      "Draft",
                      "Submitted",
                      "Confirmed",
                      "In Transit",
                    ].indexOf(loadDetails?.loadStatus) < 0
                  }
                >
                  {lookupData.find(
                    (ele) => ele.lookupCode === "Container Size"
                  ) &&
                    lookupData
                      .filter((ele) => ele.lookupCode === "Container Size")[0]
                      .values.map((option, idx) => (
                        <Select.Option value={option.value}>
                          {option.value}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div style={{ width: "22%", marginTop: "15px" }}>
            <h3
              className="primary-title"
              style={{ fontSize: "17px", width: "97%" }}
            >
              Line Haul
            </h3>
            <div
              layout="vertical"
              style={{ marginTop: "15px" }}
              requiredMark={true}
            >
              <div className="logisticLMHeaderfields">
                <Form.Item
                  label="Line Haul Rate($)"
                  name="lineHaulRate"
                  colon={false}
                >
                  <NumberFormat
                    className="inputNumFormat"
                    displayType={"numeric-input"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                    onBlur={autoFreight}
                    onClick={lineHualClick}
                    disabled={
                      [
                        "Tender",
                        "Draft",
                        "Submitted",
                        "Confirmed",
                        "In Transit",
                      ].indexOf(loadDetails?.loadStatus) < 0
                    }
                  />
                </Form.Item>
              </div>
              <div className="logisticLMHeaderfields">
                <Form.Item
                  label="Total Line Haul Amount($)"
                  name="totalLineHaulRate"
                  colon={false}
                >
                  <NumberFormat
                    className="inputNumFormat"
                    displayType={"numeric-input"}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    allowEmptyFormatting
                    onBlur={autoFreight}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="logisticLMHeaderfields">
                <Form.Item
                  label="Freight Type"
                  name="freightType"
                  colon={false}
                >
                  <Select
                    placeholder="Choose Option"
                    onSelect={onFreightTypeChange}
                    //onChange={autoFreight}
                    disabled={
                      [
                        "Tender",
                        "Draft",
                        "Submitted",
                        "Confirmed",
                        "In Transit",
                      ].indexOf(loadDetails?.loadStatus) < 0
                    }
                  >
                    {lookupData.find(
                      (ele) => ele.lookupCode === "Fregith Basis"
                    ) &&
                      lookupData
                        .filter((ele) => ele.lookupCode === "Fregith Basis")[0]
                        .values.map((option, idx) => (
                          <Select.Option key={idx} value={option.value}>
                            {option.value}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="logisticLMHeaderfields">
                <Form.Item
                  label="Allocation Basis"
                  name="allocationBasis"
                  colon={false}
                >
                  <Select
                    placeholder="Choose Option"
                    onChange={autoFreight}
                    disabled={
                      [
                        "Tender",
                        "Draft",
                        "Submitted",
                        "Confirmed",
                        "In Transit",
                      ].indexOf(loadDetails?.loadStatus) < 0
                    }
                  >
                    {allocationBasisData.map((option, idx) => (
                      <Select.Option key={idx} value={option.value}>
                        {option.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="logisticLMHeaderfields">
                <Form.Item
                  label="# of miles"
                  name="miles"
                  colon={false}
                  rules={[
                    { required: permileFlag, message: "Please input miles!" },
                  ]}
                >
                  <Input
                    placeholder=" "
                    disabled={
                      autoFreightOnLoadForm.getFieldValue("freightType") !==
                      "Per Mile"
                    }
                    onBlur={autoFreight}
                  ></Input>
                </Form.Item>
              </div>
            </div>
          </div>
          <div style={{ width: "58%", marginTop: "15px" }}>
            <div style={{ width: "100%" }}>
              <h3
                className="primary-title"
                style={{ fontSize: "17px", width: "97%" }}
              >
                Accessorials
              </h3>
              <div className="row">
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div layout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Pickup Charge($)"
                        name="pickupCharge"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={pickUpClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Delivery Charge($)"
                        name="deliverCharge"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={deliveryClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Ship Zone Charge($)"
                        name="shipZone"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={shipzneClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="logisticLMHeaderfields"
                      style={{ marginTop: "6.8rem" }}
                    >
                      <Form.Item
                        label="Total Cost($)"
                        name="totalCost"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div ayout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Fuel Charge($)"
                        name="feulCharge"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={fualChargeClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Minor Freight($)"
                        name="minorFreighr"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={minorFreightClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Unloading Charge($)"
                        name="unloadingCharge"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={unLoadClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div
                      className="logisticLMHeaderfields"
                      style={{ marginTop: "6.8rem" }}
                    >
                      <Form.Item
                        label="Finished Cost($)"
                        name="finishedCost"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          disabled
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-4" style={{ marginTop: "15px" }}>
                  <div layout="vertical" requiredMark={true}>
                    <div className="logisticLMHeaderfields">
                      <Form.Item label="Rebate($)" name="rebate" colon={false}>
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={rebateClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="logisticLMHeaderfields">
                      <Form.Item
                        label="Miscellaneous($)"
                        name="miscellaneous"
                        colon={false}
                      >
                        <NumberFormat
                          className="inputNumFormat"
                          displayType={"numeric-input"}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          allowEmptyFormatting
                          onBlur={autoFreight}
                          onClick={miscClick}
                          disabled={
                            [
                              "Tender",
                              "Draft",
                              "Submitted",
                              "Confirmed",
                              "In Transit",
                            ].indexOf(loadDetails?.loadStatus) < 0
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-10">
            {loadDetails?.loadStatus === "Draft" ? (
              <>
                <Button
                  id="btnSubmitDraft"
                  className="logisticLMRemoveYesButton saveBtnAddLoad"
                  style={{ width: "150px" }}
                  htmlType="submit"
                  onClick={(e) => handleSubmitDraft(e)}
                  disabled={
                    [
                      "Tender",
                      "Draft",
                      "Submitted",
                      "Confirmed",
                      "In Transit",
                    ].indexOf(loadDetails?.loadStatus) < 0
                  }
                  loading={submitLoaderDraft}
                >
                  <img
                    src={saveimg}
                    style={{ fontSize: 25, marginRight: 10 }}
                  />
                  Submit Load
                </Button>
                <Button
                  id="btnSaveDraft"
                  className="logisticLMRemoveYesButton saveBtnAddLoad"
                  style={{ width: "110px" }}
                  htmlType="submit"
                  onClick={(e) => handleSaveDraft(e)}
                  disabled={
                    ["Tender", "Draft", "Submitted", "Confirmed"].indexOf(
                      loadDetails?.loadStatus
                    ) < 0
                  }
                  loading={saveLoaderDraft}
                >
                  <img
                    src={saveimg}
                    style={{ fontSize: 25, marginRight: 10 }}
                  />
                  Save
                </Button>
              </>
            ) : (
              <Button
                className="logisticLMRemoveYesButton saveBtnAddLoad"
                style={{ width: "110px" }}
                htmlType="submit"
                onClick={(e) => saveAutoFreightOnLoad(e)}
                disabled={
                  [
                    "Tender",
                    "Draft",
                    "Submitted",
                    "Confirmed",
                    "In Transit",
                  ].indexOf(loadDetails?.loadStatus) < 0
                }
                loading={submitLoader}
              >
                <img src={saveimg} style={{ fontSize: 25, marginRight: 10 }} />
                Save
              </Button>
            )}
          </div>
        </div>
      </Form>
      <div className="cancelAndAutoFButtonsLoadAutoFreight">
        <ButtonComponent
          className="clearContentsButton"
          onClick={() => resetFields()}
          disabled={
            ["Tender", "Draft", "Submitted", "Confirmed", "In Transit"].indexOf(
              loadDetails?.loadStatus
            ) < 0
          }
        >
          Clear contents
        </ButtonComponent>
        <ButtonComponent
          className="addLoadCancelButton"
          onClick={props.autoFreightOnLoadClose}
        >
          CANCEL
        </ButtonComponent>
      </div>
    </>
  );
}

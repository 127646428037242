import React, { useCallback, useRef, useState } from "react";
import "../../../components/grid/grid.css";
import { Button, Form, Input } from "antd";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Inject,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useSelector } from "react-redux";
import {
  clientDetailsSelector,
  loginDetailsSelector,
} from "../../../store/selectors/app.selector";
import moment from "moment";
import PurchaseOrderService from "../../../services/purchase.service";

export const notAllowEdit = ["Cancelled"];

const editSettings = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  newRowPosition: "Bottom",
};

export default function PoNotes(props) {
  const {
    headerNotes,
    setHeaderNotes,
    notesModalClose,
    status,
    level = "",
    details = {},
    mode = "",
  } = props;
  const [notesForm] = Form.useForm();
  const clientDetails = useSelector(clientDetailsSelector);
  const userDetails = useSelector(loginDetailsSelector);
  const [loading, setLoading] = useState(false);

  const addNotes = useCallback(
    async (values) => {
      try {
        const headerNotesCopy = [...headerNotes];
        const reqParams = [
          {
            company: clientDetails?.clientInfo?.clientName,
            createDate: moment(),
            createdBy: userDetails["userName"],
            note: values.notes,
            userName: userDetails["userName"],
            showMore: false,
          },
          ...headerNotesCopy,
        ];

        if (mode === "MODIFY" && Object.keys(details).length > 0) {
          setLoading(true);
          const response =
            level === "HEADER"
              ? await PurchaseOrderService.updateHeaderNotes(
                details["id"],
                reqParams
              )
              : await PurchaseOrderService.updateLineLevelNotes(
                details["id"],
                reqParams
              );
          if (response.status === 200) {
            setLoading(false);
            setHeaderNotes(reqParams);
            notesForm.resetFields();
            // notesModalClose();
          }
        } else {
          setHeaderNotes(reqParams);
          notesForm.resetFields();
          // notesModalClose();
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    },
    [headerNotes, mode, level]
  );

  const showMore = useCallback((args, headerNotes) => {
    console.log(args);
    console.log(headerNotes);
    const headerNotesCopy = [...headerNotes];
    headerNotesCopy.forEach((ele, idx) => {
      if (idx === +args.index) {
        ele.showMore = true;
      } else {
        ele.showMore = false;
      }
    });
    setHeaderNotes(headerNotesCopy);
  }, []);

  const showLess = useCallback((args, headerNotes) => {
    console.log(args);
    console.log(headerNotes);
    const headerNotesCopy = [...headerNotes];
    headerNotesCopy.forEach((ele, idx) => {
      // if (idx === +args.index) {
      ele.showMore = false;
      // }
    });
    setHeaderNotes(headerNotesCopy);
  }, []);

  const dateTemplate = (args) => {
    return (
      <>
        <span>
          {moment(args?.createDateTime).format("MM/DD/YYYY HH:mm:ss")}
        </span>
      </>
    );
  };

  const notesTemplate = (args) => {
    console.log(args.note.length);
    return (
      <>
        {!args.showMore && (
          <span className="po-notes">
            {args.note.substring(0, 60)}
            {args.note.length > 60 && (
              <span>
                ...{" "}
                <a
                  style={{ fontSize: 13 }}
                  className="po-hyper-link"
                  onClick={() => showMore(args, headerNotes)}
                >
                  Show More
                </a>
              </span>
            )}
          </span>
        )}

        {args.showMore && (
          <span className="po-notes">
            {args.note}

            <span>
              ...{" "}
              <a
                style={{ fontSize: 13 }}
                className="po-hyper-link"
                onClick={() => showLess(args, headerNotes)}
              >
                Show Less
              </a>
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12" style={{ marginTop: "5px" }} id="poHeader">
        <GridComponent
          dataSource={headerNotes}
          gridLines="Both"
          allowFiltering={false}
          rowHeight={36}
          width="100%"
          height="200px"
          editSettings={editSettings}
          allowSelection={true}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: "Content" }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="createDate"
              headerText="Date"
              width="55"
              textAlign="Left"
              template={dateTemplate}
            />
            <ColumnDirective
              field="company"
              headerText="Company"
              width="60"
              textAlign="Left"
            />
            <ColumnDirective
              field="userName"
              headerText="USER NAME"
              width="40"
              textAlign="Left"
            />
            <ColumnDirective
              field="note"
              headerText="NOTES"
              width="100"
              textAlign="Left"
              template={notesTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Page]} />
        </GridComponent>
      </div>

      <div className="col-12">
        {notAllowEdit.indexOf(status) < 0 && (
          <Form
            name="addSystemOption"
            layout="vertical"
            requiredMark={true}
            onFinish={addNotes}
            form={notesForm}
          >
            <Form.Item
              label="Enter Notes"
              name="notes"
              style={{ width: "100%", marginTop: "10px" }}
              rules={[{ required: true, message: "Required Field" }]}
            >
              <Input.TextArea rows={3} placeholder=""></Input.TextArea>
            </Form.Item>
          </Form>
        )}
      </div>

      <div className="col-7"></div>
      <div className="col-5" align="right">
        <Button
          className="logisticNewLoadButton"
          onClick={() => notesModalClose()}
          style={{ width: "120px", marginTop: 15 }}
        >
          Close
        </Button>
        {notAllowEdit.indexOf(props.status) < 0 && (
          <Button
            type="primary"
            className="logisticLMRemoveYesButton"
            style={{ width: "110px", marginTop: 15 }}
            onClick={() => notesForm.submit()}
            loading={loading}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
}

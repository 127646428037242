import {
    SET_CLAIM_SUMMARY_DATA
  } from "../constants";
  
  const initialState = {
    claimSummaryData:{},
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
          case SET_CLAIM_SUMMARY_DATA:
            return { ...state,claimSummaryData: payload };
      default:
        return state;
    }
  };
  
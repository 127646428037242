import http from "./http.service";
import config from "./constant";

const getUserList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.LLUser.userList, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postUser = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.LLUser.addUser, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchByUsername = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      `${config.LLUser.searchByUsername}${params.name}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserDetails = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      `${config.LLUser.getUserDetails}${params.name}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getUserDetailsByID = async (userId) => {
  try {
    const response = await http.get(
      `${config.LLUser.getUserDetailsByID}${userId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const resetPassword = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.post(config.LLUser.resetPassword, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const validateUserName = async (userName) => {
  try {
    const response = await http.get(`${config.LLUser.validateUsername}${userName}`)
    return {status: response.status, data: response.data}
  } catch (err) {
    return Promise.reject(err)
  }
}

const LLAdminUserService = {
  getUserList,
  postUser,
  fetchByUsername,
  resetPassword,
  getUserDetails,
  getUserDetailsByID,
  validateUserName
};

export default LLAdminUserService;

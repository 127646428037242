import CommonService from "./common.service";

const getBoldBiReports = async (token, baseURL, sitePath) => {
  const reportsEndpoint = "v1.0/items?itemType=Report";
  if (baseURL && sitePath) {
    const url = `${baseURL}/${sitePath}/${reportsEndpoint}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      return response.json();
    } catch (e) {
      Promise.reject(e);
    }
  }
};

export const BoldBiServices = {
  getBoldBiReports
};

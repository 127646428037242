import {
  SET_APP_LANGUAGE,
  SET_LOGIN_DETAILS,
  SET_SERVICE_LOADER,
  SET_PARTNER_DETAILS_BY_USERNAME,
  SET_SYSTEM_OPTIONS,
  SET_CLIENT_DETAILS,
  SET_COMMON_DETAILS,
  SET_GRID_CONFIGS,
  UPDATE_GRID_CONFIG,
  ADD_GRID_CONFIG,
  UPDATE_BOLD_BI_TOKEN,
  UPDATE_BOLD_BI_REPORTS,
  SET_UNAUTHORIZED,
  UPDATE_TEMP_GRID_CONFIG,
  RESET_TEMP_GRID_CONFIG,
  SET_VENDOR_LIST,
} from "../constants";

const initialState = {
  language: "en",
  loginDetails: {},
  serviceLoader: false,
  partnerDetails: {},
  loginUserDetails: {},
  systemOptions: {},
  clientDetails: {},
  commonDetails: {},
  gridConfigs: [],
  tempGridConfig: {},
  boldBIToken: "",
  boldBIReports: [],
  unAuthorized: false,
  vendorList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_APP_LANGUAGE:
      return { ...state, language: payload };
    case SET_LOGIN_DETAILS:
      return { ...state, loginDetails: payload };
    case SET_SERVICE_LOADER:
      return { ...state, serviceLoader: payload };
    case SET_PARTNER_DETAILS_BY_USERNAME:
      return { ...state, partnerDetails: payload };
    case SET_SYSTEM_OPTIONS:
      return { ...state, systemOptions: payload };
    case SET_CLIENT_DETAILS:
      return { ...state, clientDetails: payload };
    case SET_COMMON_DETAILS:
      return { ...state, commonDetails: payload };
    case SET_GRID_CONFIGS:
      return { ...state, gridConfigs: payload };
    case UPDATE_GRID_CONFIG:
      return {
        ...state,
        gridConfigs: state.gridConfigs.map((gridConfig) => {
          if (gridConfig.gridId === payload.gridId) {
            return payload;
          } else {
            return gridConfig;
          }
        }),
      };
    case ADD_GRID_CONFIG:
      return {
        ...state,
        gridConfigs: [...state.gridConfigs, payload],
      };
    case UPDATE_TEMP_GRID_CONFIG:
      return {
        ...state,
        tempGridConfig: {
          ...(state.tempGridConfig || {}),
          [payload.gridId]: payload.view,
        },
      };
    case RESET_TEMP_GRID_CONFIG:
      return {
        ...state,
        tempGridConfig: {},
      };
    case UPDATE_BOLD_BI_TOKEN:
      return { ...state, boldBIToken: payload };
    case UPDATE_BOLD_BI_REPORTS:
      return { ...state, boldBIReports: payload };
    case SET_UNAUTHORIZED:
      return { ...state, unAuthorized: payload };

    case SET_VENDOR_LIST:
      return { ...state, vendorList: payload };

    default:
      return state;
  }
};
